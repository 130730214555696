import React, { useEffect, useRef } from "react";
import { CloudUpload } from "@mui/icons-material";
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
  Alert,
  CircularProgress,
} from "@mui/material";
import {
  CommonButton,
  // OpenPositionsCard,
  // CommonTeamMemberCard,
  TextField,
  DropDownField,
} from "components";
import careerImg1 from "../../assets/images/career-img1.png";
import careerImg2 from "../../assets/images/career-img2.png";
import { useCustomStyles } from "components/styles/useStyles";

// import teamMember1 from "../../assets/images/team-member1.png";
// import teamMember2 from "../../assets/images/team-member2.png";
// import teamMember3 from "../../assets/images/team-member3.png";

const Careers = () => {
  const [values, setValues] = React.useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email_addr: "",
    home_addr: "",
    file: "",
    positions: "",
  });

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 4000);
    }
    if (success) {
      setTimeout(() => {
        setSuccess("");
      }, 4000);
    }
  }, [error, success]);

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const customstyles = useCustomStyles();

  const AlertBox = ({ severity, message }) => {
    return (
      <Alert
        sx={{
          fontWeight: 600,
          fontSize: ".8rem",
          position: "fixed",
          bottom: "2rem",
          right: "2rem",
          opacity: ".95",
          p: "1rem 1.5xrem",
          zIndex: "9999",
        }}
        severity={severity}
      >
        {message}
      </Alert>
    );
  };

  const submitForm = () => {
    var last_name = document
      .querySelector("input[name='last_name']")
      .value.trim();
    var first_name = document
      .querySelector("input[name='first_name']")
      .value.trim();
    var phone_number = document
      .querySelector("input[name='phone_number']")
      .value.trim();
    var mail_address = document
      .querySelector("input[name='email_addr']")
      .value.trim();
    var home_address = document
      .querySelector("input[name='home_addr']")
      .value.trim();
    var file = document.querySelector("input[name='file']").files[0];
    /*I couldn't find a way to extract the value of the value of the the input field 
      in the `DropDownField`. After spending hours of researching I decided it was best to 
      comment it out and leave it for a more experienced dev to come along and fix */

    var dropdown = document
      .querySelector("input[name='positions']")
      .value?.trim();

    if (
      last_name === "" ||
      first_name === "" ||
      phone_number === "" ||
      mail_address === "" ||
      home_address === "" ||
      home_address === ""
    ) {
      setError("One or more fields were not completed!");
    }

    if (!file) {
      setError("Missing resume file!");
    }

    var composed_message = `
    Name: ${last_name} ${first_name}
    Phone Number: ${phone_number}
    Email Address: ${mail_address}
    Home Address: ${home_address}
    Position: ${dropdown}
    
    The resume file is attached to this mail. Check below`;

    const careerform = new FormData();
    // careerform.append("to", "your-email-address"); // for testing
    careerform.append("to", process.env.REACT_APP_EMAIL_ADDRESS);
    careerform.append("subject", "Email with attachment: Uridium Works");
    careerform.append("text", composed_message);
    careerform.append("html", "none");
    careerform.append("Attachments", file);

    if (
      last_name !== "" &&
      first_name !== "" &&
      phone_number !== "" &&
      mail_address !== "" &&
      home_address !== "" &&
      file !== undefined
    ) {
      setLoading(true);
      fetch("https://staging.uridiumworks.com/uemail/EmailSender", {
        method: "POST",
        body: careerform,
      })
        .then((res) => console.log(res))
        .then((data) => {
          setSuccess("Your resume has been submitted successfully!");
          document.querySelector(".fileImgVal").innerHTML = "upload a file";
          setValues({
            first_name: "",
            last_name: "",
            phone_number: "",
            email_addr: "",
            home_addr: "",
            file: "",
            positions: "",
          });
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    } else {
      setError("One or more fields were not completed!");
    }
  };

  const theme = useTheme();
  // const hiddenFileInput = React.useRef(null);
  const hiddenFileInput = useRef(null);
  const joinUs = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  // const teamMemberData = [
  //   {
  //     imgUrl: teamMember1,
  //     title: "Daniel Losch",
  //     subTitle: "Lead Back-end Developer",
  //     description:
  //       "“Getting the right people is the most important part of building a strong team, of course, and delegating to give people more autonomy is a powerful motivator. Uridium works create a team bonding that makes our work seamless”. ",
  //   },
  //   {
  //     imgUrl: teamMember2,
  //     title: "Daniel Losch",
  //     subTitle: "Lead Back-end Developer",
  //     description:
  //       "“Getting the right people is the most important part of building a strong team, of course, and delegating to give people more autonomy is a powerful motivator. Uridium works create a team bonding that makes our work seamless”. ",
  //   },
  //   {
  //     imgUrl: teamMember3,
  //     title: "Daniel Losch",
  //     subTitle: "Lead Back-end Developer",
  //     description:
  //       "“Getting the right people is the most important part of building a strong team, of course, and delegating to give people more autonomy is a powerful motivator. Uridium works create a team bonding that makes our work seamless”. ",
  //   },
  // ];

  const positionsList = [
    {
      value: "Product (UI/UX) Designer",
      label: "Product (UI/UX) Designer",
    },
    {
      value: "Backend Engineer",
      label: "Backend Engineer",
    },
    {
      value: "Frontend Enginer",
      label: "Frontend Enginer",
    },
    {
      value: "Core Banking (Finacle, Flexcude, T24)",
      label: "Core Banking (Finacle, Flexcude, T24)",
    },
    {
      value: "Machine learning or Data science/ Analyst",
      label: "Machine learning or Data science/ Analyst",
    },
  ];
  // const formatExcelDate =
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <Box>
        {error && <AlertBox severity="error" message={error} />}
        {success && <AlertBox severity="success" message={success} />}
        <Container
          maxWidth="lg"
          sx={{ p: { xs: "0rem", lg: "5rem auto 10rem auto" } }}
        >
          <Box sx={{ p: "5rem 1rem" }}>
            <Grid
              container
              spacing={{ xs: 3, sm: 3, md: 3 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
              wrap={"wrap-reverse"}
            >
              <Grid item xs={12} sm={6} md={6}>
                <Typography
                  sx={{
                    textAlign: "left",
                    color: theme.palette.blue[500],
                    fontWeight: "800",
                    p: "3rem 0 2rem 0",
                    width: { sx: "100%", lg: "25rem" },
                  }}
                  variant="h1"
                >
                  Join Our Rapidly Growing Team of Professionals
                </Typography>
                <Typography
                  sx={{
                    textAlign: "left",
                    color: theme.palette.grey[800],
                    p: "0 0 2rem 0",
                  }}
                  variant="h6"
                >
                  Getting the right people is the most important part of
                  building a strong team, of course, and delegating to give
                  people more autonomy is a powerful motivator. We create a team
                  bonding that make our work seamless.
                </Typography>
                <Typography
                  sx={{
                    textAlign: "left",
                    color: theme.palette.grey[700],
                    fontWeight: "600",
                    p: "0 0 5rem 0",
                  }}
                  variant="h6"
                >
                  “No one can whistle a symphony. It takes a whole orchestra to
                  <br />
                  play it”. –HE Luccock
                </Typography>

                {/* <Link to="#us"> */}
                <CommonButton
                  variant="outlined"
                  size="large"
                  sx={{
                    // mt: "2rem",
                    borderRadius: "5rem",
                    fontSize: "1rem",
                    boxShadow: "none",
                    border: `1px solid ${theme.palette.neutral[500]}`,
                    // bgcolor: theme.palette.blue[400],
                    color: theme.palette.neutral[100],
                    textTransform: "capitalize",
                    "&:hover": {
                      ...customstyles.buttonHover,
                      // bgcolor: theme.palette.red[500],
                    },
                  }}
                  children="Join Us"
                  onClick={() => scrollToSection(joinUs)}
                ></CommonButton>
                {/* </Link> */}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <img src={careerImg1} width="100%" alt="career-img1" />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ m: { xs: "0 1rem 3rem 1rem", lg: "10rem 1rem" } }}>
            <Grid
              container
              spacing={{ xs: 3, sm: 3, md: 3 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <img src={careerImg2} width="100%" alt="career-img1" />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Typography
                  sx={{
                    textAlign: "left",
                    color: theme.palette.blue[500],
                    fontWeight: "800",
                    p: "3rem 0 2rem 0",
                    lineHeight: "",
                  }}
                  variant="h1"
                >
                  We are on a mission
                </Typography>
                <Typography
                  sx={{
                    textAlign: "left",
                    color: theme.palette.grey[800],
                    p: "0 0 2rem 0",
                  }}
                  variant="h6"
                >
                  To be the global leader in the Fintech space by building on
                  our relationships, technologies, competencies that adds value
                  to our stakeholders.
                </Typography>
                <Typography
                  sx={{
                    textAlign: "left",
                    color: theme.palette.grey[800],
                    p: "0 0 2rem 0",
                  }}
                  variant="h6"
                >
                  Being a part of our Squad, you will be provided with an
                  enabling environment to aid you in delivering quality work as
                  well as be guided through your career progression.
                </Typography>
                {/* <CommonButton
                  variant="outlined"
                  sx={{
                    border: `1px solid ${theme.palette.red[600]}`,
                    color: theme.palette.red[500],
                    "&:hover": {
                      color: theme.palette.red[600],
                      border: `1px solid ${theme.palette.red[500]}`,
                    },
                  }}
                  size="large"
                  children="View open roles"
                ></CommonButton> */}
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Box ref={joinUs} sx={{ bgcolor: theme.palette.grey[300] }}>
          <Container maxWidth="lg">
            <Typography
              variant="h1"
              sx={{
                fontWeight: "700",
                color: theme.palette.blue[500],
                p: "7rem 0 2rem 0",
              }}
            >
              Join Us Now
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                color: theme.palette.grey[600],
                mb: ".5rem",
              }}
            >
              Would you like to be part of our high profound Team of
              Professionals?
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontWeight: "500", color: theme.palette.grey[500] }}
            >
              Fill your details in the space provided below:
            </Typography>
            <Box sx={{ pb: { xs: "1rem", lg: "5rem" } }}>
              <Box component="form">
                <Box display="flex" m="3rem auto" noValidate autoComplete="off">
                  <Grid
                    container
                    spacing={{ xs: 3, sm: 3, md: 6 }}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <Box>
                        <label>
                          Resume <span>*</span>
                        </label>
                        <Box
                          sx={{
                            p: "4rem",
                            bgcolor: theme.palette.grey[200],
                            width: { xs: "100%", lg: "40%" },
                            display: "flex",
                            cursor: "pointer",
                            border: "1px solid rgba(0,0,0,0.1)",
                          }}
                          onClick={handleClick}
                        >
                          <Box sx={{ m: "auto" }}>
                            <Typography
                              variant="h5"
                              sx={{
                                color: theme.palette.grey[600],
                                fontWeight: "500",
                              }}
                              className="fileImgVal"
                            >
                              Upload a file
                            </Typography>
                            <Box sx={{ textAlign: "center" }}>
                              <CloudUpload
                                sx={{
                                  color: theme.palette.grey[600],
                                }}
                              />
                            </Box>
                          </Box>
                          {/* <Typography>{fileName}</Typography> */}
                        </Box>
                        <input
                          name="file"
                          hidden
                          ref={hiddenFileInput}
                          //   accept="image/*"
                          type="file"
                          //   onChange={handleFile}
                          accept=".docx,.pdf"
                          onChange={() => {
                            document.querySelector(".fileImgVal").innerHTML =
                              document?.querySelector(
                                "input[name='file']"
                              )?.files[0].name;
                            // console.log(
                            //   document.querySelector("input[name='file']")
                            //     .files[0].name
                            // );
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <Box>
                        <label>
                          First Name <span>*</span>
                        </label>
                        <TextField
                          name="first_name"
                          value={values.first_name}
                          onChange={handleChange("first_name")}
                          type="text"
                          placeholder="Enter first name"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <label>
                        Last Name <span>*</span>
                      </label>
                      <TextField
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange("last_name")}
                        placeholder="Enter last name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <label>
                        Phone Number <span>*</span>
                      </label>
                      <TextField
                        name="phone_number"
                        value={values.phone_number}
                        onChange={handleChange("phone_number")}
                        type="tel"
                        title="Please use a 10 digit number with no dashes or dots"
                        pattern="[0-9]{10}"
                        placeholder="Enter phone number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <label>
                        Email Address<span>*</span>
                      </label>
                      <TextField
                        name="email_addr"
                        value={values.email_addr}
                        onChange={handleChange("email_addr")}
                        type="email"
                        placeholder="Enter email"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <label>
                        Positions<span>*</span>
                      </label>
                      <DropDownField
                        positions={values.positions}
                        positionsList={positionsList}
                        handleChange={handleChange("positions")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <label>
                        Address <span>*</span>
                      </label>
                      <TextField
                        name="home_addr"
                        value={values.home_addr}
                        onChange={handleChange("home_addr")}
                        type="text"
                        placeholder="Enter Address"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Divider
                  sx={{
                    bgcolor: theme.palette.secondary[400],
                    m: "4rem 0",
                    width: "100%",
                  }}
                />
                <Box display="flex">
                  <CommonButton
                    variant="contained"
                    size="large"
                    sx={{
                      position: "relative",
                      p: ".8rem 3.5rem",
                      m: "auto",
                      color: theme.palette.secondary.main,
                      bgcolor: theme.palette.blue[500],
                      borderColor: theme.palette.blue[600],
                      borderRadius: "30rem",
                      "&:hover": {
                        bgcolor: theme.palette.blue[500],
                        color: theme.palette.secondary.main,
                      },
                      "&.MuiButton-outlined": {
                        borderColor: theme.palette.blue[600],
                      },
                    }}
                    onClick={submitForm}
                    children={"Submit Application"}
                    // disabled={
                    //   values.first_name === "" ||
                    //   values.last_name === "" ||
                    //   values.phone_number === "" ||
                    //   values.email_addr === "" ||
                    //   values.positions === "" ||
                    //   values.home_addr === "" ||
                    //   values.file === undefined
                    // }
                    loading={
                      loading && (
                        <CircularProgress
                          size="lg"
                          sx={{
                            position: "absolute",
                            right: "1rem",
                            ml: 3,
                            width: 20,
                          }}
                        />
                      )
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>

        {/* <Container maxWidth="lg">
          <Box sx={{ m: "5rem 0" }}>
            <Typography
              variant="h1"
              sx={{
                color: theme.palette.blue[500],
                fontWeight: "700",
                mb: "1rem",
              }}
            >
              Open positions
            </Typography>
            <Typography variant="h5" sx={{ color: theme.palette.grey[600] }}>
              Ready to kickstart your journey with Uridium Works? Join us!
            </Typography>
            <Box
              sx={{
                width: "100%",
                p: "4rem 0",
                // display: "flex",
                display: { xs: "block", md: "flex" },
                // display: { xs: "block", lg: "flex" },
                // flexWrap: "wrap",

                "& .MuiButton-contained": {
                  p: ".4rem 1rem",
                  // border: `1px solid ${theme.palette.neutral[500]}`,
                  borderRadius: "40px",
                  mb: "1rem",
                  bgcolor: "transparent",
                  color: theme.palette.neutral[500],
                },
                "& .MuiButton-contained:hover": {
                  p: ".4rem 1rem",
                  // border: `1px solid ${theme.palette.neutral[500]}`,
                  borderRadius: "40px",
                  mb: "1rem",
                  bgcolor: "transparent",
                  color: theme.palette.neutral[500],
                },
                "& .MuiButton-outlined": {
                  p: ".4rem 1rem",
                  border: `1px solid ${theme.palette.neutral[500]}`,
                  borderRadius: "40px",
                  mb: "1rem",
                  color: theme.palette.neutral[500],
                },
                "& .MuiButton-outlined:hover": {
                  p: ".4rem 1rem",
                  border: `1px solid ${theme.palette.neutral[500]}`,
                  borderRadius: "40px",
                  mb: "1rem",
                  color: theme.palette.neutral[500],
                },
              }}
            >
              <Box
                sx={{
                  display: { xs: "flex", md: "block" },
                  // width: { xs: "100%", lg: "25%" },
                  // bgcolor: "red !important",
                  mr: "2rem",
                  flexDirection: { xs: "row", md: "column" },
                }}
              >
                <CommonButton variant="outlined" children="All" />
                <br />
                <CommonButton variant="contained" children="Design" />
                <br />
                <CommonButton
                  variant="contained"
                  children="Software Engineering"
                />
                <br />
                <CommonButton variant="contained" children="Front desk" />
                <br />
                <CommonButton variant="contained" children="Customer Support" />
              </Box>
              <Box sx={{ width: "100%" }}>
                <OpenPositionsCard />
              </Box>
            </Box>
          </Box>
        </Container> */}
        {/* <Box sx={{ m: { xs: "1rem", lg: "5rem" } }}>
          <Typography
            variant="h1"
            sx={{
              color: theme.palette.blue[500],
              fontWeight: "700",
              mb: "8rem",
              textAlign: "center",
            }}
          >
            See What Our Team Members Say
          </Typography>
          <Container maxWidth={{ sx: "xl", lg: "lg" }} sx={{ mt: "10rem" }}>
            <Grid
              container
              spacing={{ xs: 3, sm: 3, md: 6, lg: 6 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              {teamMemberData.map((item, index) => {
                return (
                  <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                    <CommonTeamMemberCard
                      imgUrl={item.imgUrl}
                      title={item.title}
                      subTitle={item.subTitle}
                      description={item.description}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </Box> */}
      </Box>
    </>
  );
};

/*
var testform = new FormData()
testform.append("to", "akinnagbe.olamide@uridiumworks.com")
testform.append("subject", "Test api request")
testform.append("text", "testing mail")
testform.append("html", "This key (html) is required. I have no idea why")


fetch("https://staging.uridiumworks.com/uemail/EmailSender", {
  method: "POST",
  body: testform,
}).then(res => res.json()).then(data => {
  console.log("sent test mail 2", data)
}).catch(e => {
  console.log(e)
})
*/

export default Careers;
