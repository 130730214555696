import {
  Box,
  useTheme,
  Typography,
  Container,
  List,
  ListItem,
  Grid,
  Link,
} from "@mui/material";
import { CommonButton } from "components";
import { Link as RouterLink } from "react-router-dom";
import React from "react";
import heroImg from "../../assets/images/umarketplace/hero-img.png";
import img1 from "../../assets/images/umarketplace/img1.png";
import img2 from "../../assets/images/umarketplace/img2.png";
import img3 from "../../assets/images/umarketplace/img3.png";
import paymentImg1 from "../../assets/images/umarketplace/payment-img1.png";
import paymentImg2 from "../../assets/images/umarketplace/payment-img2.png";
import paymentImg3 from "../../assets/images/umarketplace/payment-img3.png";
import paymentImg4 from "../../assets/images/umarketplace/payment-img4.png";

const UMarketplace = () => {
  const theme = useTheme();
  return (
    <>
      <Box>
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              p: {
                xs: "3rem 1.5rem 10rem 1.5rem",
                sm: "3rem 1.5rem 13rem 1.5rem",
                lg: "3rem 6rem 15rem 6rem",
              },
              textAlign: "center",
              "& .MuiTypography-h2": {
                p: "2rem 0",
                fontWeight: "bold",
                color: theme.palette.blue[500],
                width: { xs: "100%", sm: "60%", lg: "45%" },
                m: "auto",
              },
              "& .MuiTypography-h5": {
                fontWeight: "600",
                // color: theme.palette.blue[500],
              },
            }}
          >
            <Typography variant="h5" color={theme.palette.blue[500]}>
              UIMARKET PLACE
            </Typography>
            <Box display="flex">
              <Typography variant="h2" color="initial">
                Protect Your Future with Our Comprehensive Insurance Services
              </Typography>
            </Box>
            <Typography variant="h5" color="initial">
              Get started in minutes
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: { xs: "", lg: "48%" },
              left: "50%",
              transform: "translate(-50%,-50%)",
              display: "flex",
              "& img": {
                width: { xs: "100%", md: "80%", lg: "80%" },
                m: "auto",
                borderRadius: "3rem",
              },
            }}
          >
            <img src={heroImg} alt="hero-img" />
          </Box>
          <Box
            sx={{
              bgcolor: theme.palette.blue[500],
              p: {
                xs: "10rem 1rem 6rem 1rem",
                sm: "13rem 1rem 6rem 1rem",
                lg: "15rem 1rem 6rem 1rem",
              },
              textAlign: "center",
              "& .MuiTypography-h2": {
                color: theme.palette.secondary[900],
                fontWeight: "bold",
                pb: "2rem",
              },
              "& .MuiTypography-h6": {
                color: theme.palette.secondary[900],
                m: "auto auto 1rem auto",
                textAlign: "left",
                width: { xs: "100%", lg: "70%" },
                lineHeight: "1.5rem",
              },
            }}
          >
            <Typography variant="h2" color="initial">
              What’s UMarketPlace?
            </Typography>
            <Box display="flex">
              <Typography variant="h6" color="initial">
                A service aggregation solution that aggregates services and
                makes it a self-service for customers to be able to initiate
                (from their bank app) themselves and get instant value from the
                comfort of their home or office.
              </Typography>
            </Box>
            <Typography variant="h6" color="initial">
              This implies that if a customer wants to renew or get a new car
              insurance for example, they could logon to their bank app to
              initiate it (select comprehensive or third-party insurance) and
              get the soft copy of the insurance delivered to their email
              address. <br />
              Customers who want the hard copy delivered to their preferred
              address can also do that from this service.
            </Typography>
          </Box>
        </Box>

        <Container maxWidth="lg">
          <Box
            sx={{
              textAlign: "center",
              p: "4rem 0",
              "& .MuiTypography-h3": {
                color: theme.palette.blue[500],
                fontWeight: "bold",
                pb: "2rem",
              },
            }}
          >
            <Typography variant="h3" color="initial">
              Targeted Services
            </Typography>
            <Box
              display="flex"
              sx={{
                "& img": {
                  width: { xs: "100%", sm: "30%", lg: "30%" },
                  m: "1rem auto 3rem auto",
                },
              }}
            >
              <img src={img1} alt="" />
            </Box>
            <Typography variant="h3" color="initial">
              How it works
            </Typography>

            <Box
              flexWrap="wrap-reverse"
              sx={{
                display: "flex",
                mb: 10,
                p: { xs: "0 1.5rem", lg: "0 4rem" },
              }}
            >
              <Box
                sx={{
                  m: "auto",
                  width: { xs: "100%", lg: "30%" },
                  p: "4rem 0",
                  "& .MuiList-root": {
                    fontSize: "1rem",
                    fontWeight: "500",
                    color: theme.palette.secondary[100],
                    listStyleType: "disc",
                    "& .MuiListItem-root::before": {
                      content: "''",
                      color: theme.palette.blue[500],
                      display: "list-item",
                      fontSize: "2rem",

                      // color: "green !important",
                    },
                  },
                }}
              >
                <List>
                  <ListItem>Customer login to their bank application</ListItem>
                </List>
                <List>
                  <ListItem>Customerclicks on UMarketPlace menu item</ListItem>
                </List>
                <List>
                  <ListItem>
                    The UMarketplace displays the list of all insurance
                    companies that have integrated.
                  </ListItem>
                </List>
                <List>
                  <ListItem>
                    Customer selects their preferred insurance company
                  </ListItem>
                </List>
                <List>
                  <ListItem>
                    Customer select the insurance type (Comprehensive or third
                    party).
                  </ListItem>
                </List>
                <List>
                  <ListItem>
                    The cost for the insurance type is displayed for the
                    customer to review and consent for debit.
                  </ListItem>
                </List>
              </Box>
              <Box
                sx={{
                  m: "auto",
                  width: { xs: "100%", lg: "70%" },
                  "& img": {
                    width: "100%",
                    borderRadius: "1rem",
                  },
                }}
              >
                <img src={img2} alt="img1" />
              </Box>
            </Box>
            <Typography variant="h3" color="initial">
              Payment Channels
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, sm: 2, md: 2, lg: 15 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              pt="2rem"
              sx={{
                "& .MuiBox-root": {
                  "& img": {
                    width: { xs: "55%", sm: "60%", lg: "65%" },
                    m: "auto",
                  },
                },
              }}
            >
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <Box>
                  <img src={paymentImg1} alt="payment-img" />
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <Box>
                  <img src={paymentImg2} alt="payment-img" />
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <Box>
                  <img src={paymentImg3} alt="payment-img" />
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <Box>
                  <img src={paymentImg4} alt="payment-img" />
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                p: "4rem",
                "& .MuiTypography-h5": {
                  color: theme.palette.blue[500],
                  p: "1rem 0",
                  fontWeight: "bold",
                },
                "& .MuiTypography-h6": {
                  color: theme.palette.secondary[100],
                  fontWeight: "500",
                },
              }}
            >
              <Box
                display="flex"
                sx={{
                  "& img": {
                    width: { xs: "30%", md: "15%", lg: "10%" },
                    m: "auto",
                  },
                }}
              >
                <img src={img3} alt="img3" />
              </Box>
              <Typography variant="h5">Still have questions?</Typography>
              <Typography variant="h6">
                Can’t find the answer you’re looking for? Please send chat to
                our friendly team.
              </Typography>
              <Link underline="none" component={RouterLink} to="/contact-us">
                <CommonButton
                  variant="contained"
                  size="large"
                  sx={{
                    color: theme.palette.secondary[1000],
                    bgcolor: theme.palette.blue[500],
                    borderRadius: "3rem",
                    mt: "1rem",
                    "&:hover": {
                      color: theme.palette.secondary[1000],
                      bgcolor: theme.palette.blue[500],
                    },
                  }}
                  children="Get in touch"
                />
              </Link>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default UMarketplace;
