import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
  Button,
} from "@mui/material";
// import { CommonButton } from "components";
import React from "react";
// import { servicesData } from "assets/data/servicesData";
import img1 from "assets/images/our-services/img1.png";
import img2 from "assets/images/our-services/img2.png";
import { useNavigate } from "react-router-dom";
import { FAQs } from "components";

const SingleServices = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <Box>
        <Box sx={{ "& img": { width: "100%" } }}>
          <img src={img1} alt="img1" />
        </Box>
        <Typography
          sx={{
            textAlign: "center",
            color: theme.palette.blue[500],
            fontWeight: "800",
            p: "10rem 0 2rem 0",
          }}
          variant="h1"
        >
          System Integrations And Designs
        </Typography>
        <Typography
          sx={{
            color: theme.palette.grey[900],
            p: { xs: "1rem", lg: "0 20rem 4rem 20rem" },
            textAlign: "center",
            fontWeight: "500",
          }}
          variant="h4"
        >
          systems design and integration is one of the two main aspects of
          systems engineering. We spend quality time to understand the physics
          of the system, provide the ability to design and integrate a system
        </Typography>
        <Box sx={{ p: "0  0 10rem 0" }}>
          <Container maxWidth="lg">
            <Grid
              container
              spacing={{ xs: 2, sm: 2, md: 2, lg: 10 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <Grid item xs={12} sm={6} lg={6}>
                <Box display="flex" height="100%">
                  <Box margin="auto">
                    <Typography
                      sx={{
                        color: theme.palette.blue[500],
                        fontWeight: "700",
                        p: "0 0 1rem 0",
                      }}
                      variant="h1"
                    >
                      Purpose Of System Integrations And Design
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.grey[900],
                        fontWeight: "500",
                        p: "1rem 0 0 0",
                      }}
                      variant="h5"
                    >
                      <b> Why is system integration important? </b>The primary
                      reason why businesses need to implement system integration
                      is to ensure that all systems work together in harmony to
                      boost productivity and enhance the quality of day-to-day
                      operations. The goal of system integration is to
                      streamline and simplify communication between not only the
                      organization’s internal systems but also the third parties
                      the organization works with. System Integration Design
                      helps accelerate the outflow of information and cut back
                      on operational costs.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <img src={img2} width="100%" alt="s-img" />
              </Grid>
            </Grid>
            <Box p margin="4rem auto">
              <Typography
                sx={{
                  color: theme.palette.blue[500],
                  fontWeight: "700",
                  p: "0 0 1rem 0",
                  width: { xs: "100%", md: "80%", lg: "60%" },
                }}
                variant="h1"
              >
                Types Of System Integrations And Design We offer
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.neutral[50],
                  fontWeight: "500",
                  p: "0 0 1rem 0",
                }}
                variant="h5"
              >
                Let’s take a look at the different types of system integration
                and designs that you can leverage.
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.neutral[100],
                  fontWeight: "500",
                  p: "1rem 0 0 0",
                }}
                variant="h5"
              >
                <b> Why is system integration important? </b>The primary reason
                why businesses need to implement system integration is to ensure
                that all systems work together in harmony to boost productivity
                and enhance the quality of day-to-day operations. The goal of
                system integration is to streamline and simplify communication
                between not only the organization’s internal systems but also
                the third parties the organization works with. System
                Integration Design helps accelerate the outflow of information
                and cut back on operational costs.
              </Typography>
            </Box>
            <Box p margin="4rem auto">
              <Box
                display="flex"
                sx={{
                  mt: "2rem ",
                }}
              >
                <Divider
                  orientation="horizontal"
                  sx={{
                    border: `3px solid ${theme.palette.blue[500]}`,
                    m: "auto 1rem auto 0",
                    width: "5%",
                  }}
                />

                <Typography
                  sx={{
                    color: theme.palette.blue[500],
                    fontWeight: "600",
                  }}
                  variant="h4"
                >
                  Quality testing
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: theme.palette.neutral[100],
                  fontWeight: "500",
                  pt: "2rem",
                }}
                variant="h5"
              >
                We have built a script that helps enforce control in
                <b> Microsoft SQL server </b> such that if anyone (developer or
                support staff) takes the <b> username </b> and <b> password </b>
                from an application config file and tries to logon through SSMS
                or Toad, such application user will be <b> denied </b> access
                and thereby ensure the application user is only used by the
                application and not by a <b> staff.</b>
              </Typography>
            </Box>
            <Box mb="4rem">
              <Box
                display="flex"
                sx={{
                  mt: "2rem ",
                }}
              >
                <Divider
                  orientation="horizontal"
                  sx={{
                    border: `3px solid ${theme.palette.blue[500]}`,
                    m: "auto 1rem auto 0",
                    width: "5%",
                  }}
                />

                <Typography
                  sx={{
                    color: theme.palette.blue[500],
                    fontWeight: "600",
                  }}
                  variant="h4"
                >
                  Enterprise application integration (EAI)
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: theme.palette.neutral[100],
                  fontWeight: "500",
                  pt: "2rem",
                }}
                variant="h5"
              >
                When businesses scale, they tend to incorporate more enterprise
                applications to streamline their front-end and back-office
                processes. This results in many disparate applications that do
                not share any point of convergence and store volumes of data
                separately. EAI combines these different subsystems into a
                single business environment and facilitates the real-time
                exchange of data between different applications.
              </Typography>
            </Box>
          </Container>
          <Box
            sx={{ bgcolor: theme.palette.blue[500], p: "2.5rem 0", mt: "6rem" }}
          >
            <Container component="section">
              <Box
                sx={{
                  display: { sx: "block", lg: "flex" },
                  justifyContent: "space-between",
                }}
              >
                <Box display="flex">
                  <Box sx={{ m: "auto" }}>
                    <Box display="flex">
                      <Box sx={{ textAlign: { xs: "center", lg: "left" } }}>
                        <Typography
                          sx={{ fontWeight: "600", mb: ".5rem" }}
                          variant="h2"
                        >
                          Want to know the Duration and Cost?
                        </Typography>
                        <Typography
                          sx={{ color: theme.palette.red[500] }}
                          variant="h4"
                        >
                          Let’s get your training started
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex">
                  <Button
                    sx={{
                      p: ".5rem 2rem",
                      mt: { xs: "1rem" },
                      borderRadius: "4rem",
                      m: "auto",
                    }}
                    onClick={() => {
                      navigate("/contact-us");
                    }}
                    variant="outlined"
                  >
                    Get in touch
                  </Button>
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>
        <Container maxWidth="lg">
          <FAQs />
        </Container>
        <Box
          sx={{ bgcolor: theme.palette.blue[500], p: "3.5rem 0", mt: "6rem" }}
        >
          <Container component="section">
            <Box
              sx={{
                // display: { sx: "block", lg: "flex" },
                justifyContent: "space-between",
              }}
            >
              <Box display="flex">
                <Box sx={{ m: "auto" }}>
                  <Typography
                    sx={{ fontWeight: "500", mb: ".5rem" }}
                    variant="h3"
                  >
                    Looking for opportunities to grow?
                  </Typography>
                </Box>
              </Box>
              <Box display="flex">
                <Button
                  sx={{
                    p: ".5rem 2rem",
                    mt: { xs: "1rem" },
                    borderRadius: "4rem",
                    m: "auto",
                  }}
                  onClick={() => {
                    navigate("/careers");
                  }}
                  variant="outlined"
                >
                  Search for all opportunities
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default SingleServices;
