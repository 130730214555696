import {
  Box,
  Divider,
  Grid,
  Typography,
  useTheme,
  // CardActions,
  // Button,
  // Link,
} from "@mui/material";
import React from "react";
// import { Link as RouterLink } from "react-router-dom";

import trainingImg from "../../../assets/images/training/trainingImg6.png";

const TrainingContent2 = () => {
  const theme = useTheme();
  const style = {
    parentPosition: {
      position: "relative",
    },
    textBottom: {
      position: "absolute",
      bottom: 0,
    },
  };

  return (
    <>
      <Box className="max__height">
        <Box
          sx={{
            m: "0 0 4rem 0",
            "& *": {
              lineHeight: 2,
            },
          }}
        >
          <Grid
            container
            spacing={{ xs: 0, sm: 0, md: 0, lg: 0 }}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            // wrap={"wrap-reverse"}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  bgcolor: theme.palette.blue[500],
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.secondary[1000],
                    fontWeight: "bold",
                    m: "auto",
                    p: "2rem",
                  }}
                  variant="h2"
                >
                  Python For Data Science
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  "& img": {
                    margin: "auto",
                    width: "100%",
                  },
                }}
              >
                <img src={trainingImg} alt="img1" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: "4rem" }}>
          <Typography
            sx={{
              color: theme.palette.blue[600],
              fontWeight: "bold",
              textAlign: "center",
              mb: "2rem",
            }}
            variant="h2"
          >
            PYTHON FOR DATA SCIENCE
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              textAlign: "left",
              // mb: "2rem"
            }}
          >
            Python is the go-to programming language for data science, and for a
            good reason. With its user-friendly syntax and extensive library of
            data analysis tools, Python enables data scientists to manipulate,
            visualize, and analyze complex data sets quickly and efficiently.
            Whether you're a beginner or an experienced programmer, Python for
            Data Science training will equip you with the skills you need to
            succeed in this growing field. So get started today and join the
            thriving community of data scientists using Python to unlock
            valuable insights and drive meaningful change.
          </Typography>
        </Box>
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
              }}
            >
              Learning the basics introduction to python, why learn python for
              data analytics, and installation of the jupyter notebook using
              anaconda for your coding and the different data types.
            </Typography>
          </Box>
          <Box sx={[{ width: "15%" }, style.parentPosition]}>
            <Typography
              sx={[
                {
                  color: theme.palette.secondary[100],
                  fontWeight: "bold",
                },
                style.textBottom,
              ]}
              variant="h3"
            >
              Day 1
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Introduction to python libraries: Numpy, Pandas, Matplotlib and
              Seabom
            </Typography>
          </Box>
          <Box sx={[{ width: "15%" }, style.parentPosition]}>
            <Typography
              sx={[
                {
                  color: theme.palette.secondary[100],
                  fontWeight: "bold",
                },
                style.textBottom,
              ]}
              variant="h3"
            >
              Day 2
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Understanding what is exploratory data analysis (EDA) and
              visualizations of data set.
            </Typography>
          </Box>
          <Box sx={[{ width: "15%" }, style.parentPosition]}>
            <Typography
              sx={[
                {
                  color: theme.palette.secondary[100],
                  fontWeight: "bold",
                },
                style.textBottom,
              ]}
              variant="h3"
            >
              Day 3
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Supervised Learning tasks-classification: Loan Default Prediction
              (Working with loan default prediction dataset; load, transform and
              extract, feature selections and engineering, confusion matrix,
              accuracy, recall and precision, cross validation, overfitting and
              underfitting).
              <br />
              <br />
              Regression; supervised machine leaming task-Boston House Prices
              <br />
              Prediction
            </Typography>
          </Box>
          <Box sx={{ width: "15%", ...style.parentPosition }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
                ...style.textBottom,
              }}
              variant="h3"
            >
              Day 4
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Unsupervised Leaming Tasks - Clustering: Customer Segmentation
              Dataset.
              <br />
              <br />
              Capstone project on Online Fraud Detection Dataset.
            </Typography>
          </Box>
          <Box sx={{ width: "15%", ...style.parentPosition }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
                ...style.textBottom,
              }}
              variant="h3"
            >
              Day 5
            </Typography>
          </Box>
        </Box>
        <Divider />
      </Box>
    </>
  );
};

export default TrainingContent2;
