import { Box, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade, FreeMode, Autoplay, Pagination } from "swiper";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/free-mode";
import "swiper/css";
import phone1 from "../../../assets/images/phone1.png";
import phone2 from "../../../assets/images/phone2.png";
import uinvoiceImg from "../../../assets/images/uinvoice-img.png";
import uMarketPlace from "../../../assets/images/umartketplace-img.png";
import uPayroll from "../../../assets/images/upayroll-img.png";

const ProductUpdateSlider = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{ overflow: "hidden", bgcolor: theme.palette.grey[200], p: "5rem 0" }}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            // width: { xs: "100%", sm: , md: 1000, lg: 1500 },
            width: { xs: "100%", sm: 520, md: 750, lg: 1000 },
            overflow: "hidden",
            // width: { xs: "100%", sm: 670, md: 1000, lg: 1500 },
            m: "auto",
          }}
        >
          <Swiper
            style={{
              "--swiper-pagination-color": "#000",
              "--swiper-pagination-bullet-inactive-color": "#000",
              "--swiper-pagination-bullet-inactive-opacity": "1",
              "--swiper-pagination-bullet-size": "36px",
              "--swiper-pagination-bullet-horizontal-gap": "20px",
            }}
            loop={true}
            loopedSlides={4}
            modules={[Navigation, EffectFade, FreeMode, Autoplay, Pagination]}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            // spaceBetween={50}
            // slidesPerView={3}
            breakpoints={{
              0: { slidesPerView: 1, spaceBetween: 10 },
              480: { slidesPerView: 1, spaceBetween: 10 },
              768: { slidesPerView: 1, spaceBetween: 15 },
              980: { slidesPerView: 1, spaceBetween: 15 },
            }}
            grabCursor={true}
            freeMode={{ enabled: true, sticky: true }}
            pagination={{
              clickable: true,
              // el: `swiper-container swiper-container-testClass`,
              // bulletClass: `swiper-pagination-bullet swiper-pagination-testClass`,
            }}
            // wrapperTag="ul"
            // navigation={true}
            // navigation={{
            //   prevEl: ".swiperNavPrevRef",
            //   nextEl: ".swiperNavNextRef",
            // }}
            // effect={"fade"}
            // speed={800}
            // onInit={(swiper) => {
            //   // swiper.params.navigation.prevEl = swiperNavPrevRef.current;
            //   // swiper.params.navigation.nextEl = swiperNavNextRef.current;
            //   swiper.navigation.init();
            //   swiper.navigation.update();
            // }}
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
          >
            <SwiperSlide>
              <Box
                sx={{
                  overflow: "hidden",
                  backgroundColor: theme.palette.grey[200],
                  // border: `1px solid ${theme.palette.grey[300]}`,
                  borderRadius: ".5rem",
                  height: "25rem",
                }}
              >
                <Grid
                  container
                  spacing={{ xs: 2, sm: 2, md: 2, lg: 10 }}
                  columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                >
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box
                      sx={{
                        display: "flex",
                        // overflow: "hidden",
                        "& div": {
                          marginRight: "1.5rem",
                        },
                        "& div:last-child": { marginRight: "0" },
                      }}
                    >
                      {/* <Box sx={{ display: "flex" }}> */}
                      <Box
                        sx={{
                          m: "auto",
                          // display: "flex",
                          position: "relative",
                          width: "100%",
                          height: "25rem",
                          "& img": {
                            position: "absolute",
                            pt: "1rem",
                            // m: "auto",
                            height: { xs: "100%", lg: "20rem" },
                          },
                          "& .phone1": {
                            top: "50%",
                            left: "45%",
                            transform: "translate(-50%, -50%)",
                          },
                          "& .phone2": {
                            top: "50%",
                            left: "55%",
                            transform: "translate(-50%, -50%)",
                          },
                        }}
                      >
                        <img src={phone1} className="phone1" alt="phone1" />
                        <img src={phone2} className="phone2" alt="phone2" />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box display="flex" height="100%">
                      <Box
                        sx={{
                          p: "1.5rem",
                          m: "auto",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "600",
                            m: "2rem 0",
                            color: theme.palette.primary[500],
                          }}
                          variant="h1"
                          gutterBottom
                        >
                          USSD
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "500",
                            m: "2rem 0",
                            color: theme.palette.primary[500],
                          }}
                          variant="h4"
                          gutterBottom
                        >
                          Unstructured Supplementary Service <br />
                          Data without internet connection
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              <Box
                component="div"
                display="flex"
                // className="uinvoice__img"
                sx={{
                  height: "25rem",
                  overflow: "hidden",
                  position: "relative",
                  // mt: "3rem",
                  borderRadius: ".5rem",
                  // border: `1px solid ${theme.palette.grey[300]}`,
                }}
              >
                {/* <Box
                  component="div"
                  display="flex"
                  // className="uinvoice__img"
                  sx={{
                    position: "absolute",
                    left: "0",
                    right: "0",
                    top: "0",
                    bottom: "0",
                    width: "100%",
                    zIndex: "10",
                    bgcolor: "rgba(0, 0, 0, 0.55)",
                    display: "flex",
                  }}
                >
                  <Box margin="auto" textAlign="center" sx={{ zIndex: "200" }}>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        m: "2rem 0",
                        color: theme.palette.secondary[500],
                      }}
                      variant="h1"
                      gutterBottom
                    >
                      UInvoice
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        m: "2rem 0",
                        color: theme.palette.secondary[500],
                      }}
                      variant="h4"
                      gutterBottom
                    >
                      Create a Professional Invoice for your Business in
                      minutes!
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        m: "2rem 0",
                        color: theme.palette.red[500],
                      }}
                      variant="h1"
                      gutterBottom
                      className="animate__flicker"
                    >
                      Coming Soon !!
                    </Typography>
                  </Box>
                </Box> */}
                <Grid
                  container
                  spacing={{ xs: 3, sm: 3, md: 6 }}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{
                    p: "2.5rem",
                  }}
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ m: "4rem auto" }}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          m: "2rem 0 1rem 0",
                          color: theme.palette.blue[500],
                        }}
                        variant="h4"
                        gutterBottom
                      >
                        Smart Invoice
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          mb: "1rem",
                          color: theme.palette.secondary[300],
                          width: { xs: "100%", lg: "25rem" },
                        }}
                        variant="h6"
                        gutterBottom
                      >
                        Create a Professional Invoice for your Business in
                        minutes!
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box
                      sx={{
                        // position: "absolute",
                        display: "flex",
                        height: "100%",
                        // right: "1.5rem",
                        // zIndex: "-10",
                        "& img": { width: { xs: "90%" }, m: "auto" },
                      }}
                    >
                      <img src={uinvoiceImg} alt="uinvoice" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              <Box
                component="div"
                display="flex"
                // className="uinvoice__img"
                sx={{
                  height: "25rem",
                  overflow: "hidden",
                  position: "relative",
                  // mt: "3rem",
                  borderRadius: ".5rem",
                  // border: `1px solid ${theme.palette.grey[300]}`,
                }}
              >
                {/* <Box
                  sx={{
                    position: "absolute",
                    left: "0",
                    right: "0",
                    top: "0",
                    bottom: "0",
                    width: "100%",
                    zIndex: "10",
                    bgcolor: "rgba(0, 0, 0, 0.55)",
                    display: "flex",
                  }}
                >
                  <Box margin="auto" textAlign="center" sx={{ zIndex: "200" }}>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        m: "2rem 0",
                        color: theme.palette.secondary[500],
                      }}
                      variant="h1"
                      gutterBottom
                    >
                      UMarketPlace
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        m: "2rem auto",
                        color: theme.palette.secondary[500],
                        textAlign: "center",
                        width: { xs: "100%", lg: "35rem" },
                      }}
                      variant="h4"
                      gutterBottom
                    >
                      A service aggregation solution that aggregates services
                      and makes it a self-service for customers
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        m: "2rem 0",
                        color: theme.palette.red[500],
                      }}
                      variant="h1"
                      gutterBottom
                      className="animate__flicker"
                    >
                      Coming Soon !!
                    </Typography>
                  </Box>
                </Box> */}
                <Grid
                  container
                  spacing={{ xs: 3, sm: 3, md: 6 }}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{
                    p: "2.5rem",
                  }}
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ m: "4rem auto" }}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          m: "2rem 0 1rem 0",
                          color: theme.palette.blue[500],
                        }}
                        variant="h4"
                        gutterBottom
                      >
                        UMarketplace
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          mb: "1rem",
                          color: theme.palette.secondary[300],
                          width: { xs: "100%", lg: "25rem" },
                        }}
                        variant="h6"
                        gutterBottom
                      >
                        A service aggregation solution that aggregates services
                        and makes it a self-service for customers
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box
                      sx={{
                        // position: "absolute",
                        display: "flex",
                        height: "100%",
                        // right: "1.5rem",
                        // zIndex: "-10",
                        "& img": { width: { xs: "90%" }, m: "auto" },
                      }}
                    >
                      <img src={uMarketPlace} alt="uinvoice" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </SwiperSlide>
            <SwiperSlide>
              <Box
                component="div"
                display="flex"
                // className="uinvoice__img"
                sx={{
                  height: "25rem",
                  overflow: "hidden",
                  position: "relative",
                  // mt: "3rem",
                  borderRadius: ".5rem",
                  // border: `1px solid ${theme.palette.grey[300]}`,
                }}
              >
                {/* <Box
                  sx={{
                    position: "absolute",
                    left: "0",
                    right: "0",
                    top: "0",
                    bottom: "0",
                    width: "100%",
                    zIndex: "10",
                    bgcolor: "rgba(0, 0, 0, 0.55)",
                    display: "flex",
                  }}
                >
                  <Box margin="auto" textAlign="center" sx={{ zIndex: "200" }}>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        m: "2rem 0",
                        color: theme.palette.secondary[500],
                      }}
                      variant="h1"
                      gutterBottom
                    >
                      UMarketPlace
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        m: "2rem auto",
                        color: theme.palette.secondary[500],
                        textAlign: "center",
                        width: { xs: "100%", lg: "35rem" },
                      }}
                      variant="h4"
                      gutterBottom
                    >
                      A service aggregation solution that aggregates services
                      and makes it a self-service for customers
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        m: "2rem 0",
                        color: theme.palette.red[500],
                      }}
                      variant="h1"
                      gutterBottom
                      className="animate__flicker"
                    >
                      Coming Soon !!
                    </Typography>
                  </Box>
                </Box> */}
                <Grid
                  container
                  spacing={{ xs: 3, sm: 3, md: 6 }}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{
                    p: "2.5rem",
                  }}
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ m: "4rem auto" }}>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          m: "2rem 0 1rem 0",
                          color: theme.palette.blue[500],
                        }}
                        variant="h4"
                        gutterBottom
                      >
                        UPayroll
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          mb: "1rem",
                          color: theme.palette.secondary[300],
                          width: { xs: "100%", lg: "25rem" },
                        }}
                        variant="h6"
                        gutterBottom
                      >
                        The platform provides a seamless experience for managing
                        all aspects of payroll, from calculating wages and taxes
                        to generating payslips and making payments.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box
                      sx={{
                        // position: "absolute",
                        display: "flex",
                        height: "100%",
                        // right: "1.5rem",
                        // zIndex: "-10",
                        "& img": { width: { xs: "90%" }, m: "auto" },
                      }}
                    >
                      <img src={uPayroll} alt="uinvoice" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </SwiperSlide>
          </Swiper>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductUpdateSlider;
