import {
  Box,
  Card,
  CardContent,
  Typography,
  useTheme,
  // CardActions,
  // Button,
  // Link,
} from "@mui/material";
import { useAnimation, motion } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
// import { Link as RouterLink } from "react-router-dom";

const ServicesCard = ({ imgUrl, title, description }) => {
  const theme = useTheme();
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  const boxVariant = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1, ease: "easeInOut" },
    },
    hidden: { opacity: 0, x: -50 },
  };

  // const truncate = (str) => {
  //   console.log(str);
  //   if (str) {
  //     return JSON.stringify(str).length > 300
  //       ? str.substring(0, 140) + "..."
  //       : str;
  //   }
  // };

  return (
    <>
      <motion.div
        ref={ref}
        variants={boxVariant}
        initial="hidden"
        animate={control}
      >
        <Card
          sx={{
            "&:last-child": {
            },
            height: "26rem",
            margin: "auto",
            boxShadow: "none",
            bgcolor: theme.palette.neutral[1000],
            borderRadius: "15px",
          }}
        >
          <Box
            component="div"
            sx={{
              p: "1.5rem .2rem",
              height: "auto",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
              }}
            >
              <img
                src={imgUrl}
                height="100"
                alt="offer-img"
                style={{ margin: "auto auto auto 1rem" }}
              />
            </Box>
            <CardContent
              sx={{
                // backgroundColor: "transparent",
                fontColor: theme.palette.secondary.dark,
                // textAlign: align,
              }}
            >
              <Typography
                sx={{ color: theme.palette.blue[500], fontWeight: "bold" }}
                gutterBottom
                variant="h5"
                component="div"
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.secondary[100],
                  lineHeight: "1.2rem",
                  // textAlign: "left",
                }}
                variant="body1"
                // noWrap
                textOverflow="ellipsis"
              >
                {description}
              </Typography>
            </CardContent>
            {/* <Box>
              <CardActions>
                <Link component={RouterLink} to="" underline="none">
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      fontSize: ".7rem",
                      p: ".5rem 0 0 1rem",
                      color: theme.palette.red[500],
                      fontWeight: "bold",
                    }}
                    size="small"
                  >
                    Learn More &gt;&gt;
                  </Button>
                </Link>
              </CardActions>
            </Box> */}
          </Box>
        </Card>
      </motion.div>
    </>
  );
};

export default ServicesCard;
