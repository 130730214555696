import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { themeSettings } from "./theme";
import Layout from "./Layout";
import {
  AboutUs,
  Careers,
  ContactUs,
  Home,
  OurServices,
  OurTeam,
  UWPartners,
  Training,
  SingleServices,
  UInvoice,
  BoardOfDirectors,
  Ussd,
  UMarketplace,
  Product,
  WhyChooseUs,
} from "./pages";
import { AnimatePresence } from "framer-motion";

function App() {
  const mode = useSelector((state) => state.global.mode);
  let theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  theme = responsiveFontSizes(theme);
  // process.env.CI = true;;
  process.env.CI = false;

  function ScrollToTop({ children }) {
    let location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return children;
  }

  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AnimatePresence>
            <Routes>
              <Route element={<Layout />}>
                <Route path="/" element={<Navigate to="home" replace />} />
                <Route path="home" element={<Home />} />
                <Route path="about-us" element={<AboutUs />} />
                <Route path="why-choose-us" element={<WhyChooseUs />} />
                <Route path="our-services" element={<OurServices />} />
                <Route path="single-services" element={<SingleServices />} />
                <Route path="product" element={<Product />} />
                <Route path="u-invoice" element={<UInvoice />} />
                <Route path="ussd" element={<Ussd />} />
                <Route path="u-marketplace" element={<UMarketplace />} />
                <Route path="uw-partners" element={<UWPartners />} />
                <Route path="our-team" element={<OurTeam />} />
                <Route path="training" element={<Training />} />
                <Route path="careers" element={<Careers />} />
                <Route
                  path="board-of-directors"
                  element={<BoardOfDirectors />}
                />
                <Route path="contact-us" element={<ContactUs />} />
              </Route>
            </Routes>
          </AnimatePresence>
        </ThemeProvider>
      </Router>
    </div>
  );
}

export default App;
