export const keyModulesData = [
  {
    title: "User Onboarding",
  },
  {
    title: "Product Creation",
  },
  {
    title: "Invoice Generation",
  },
  {
    title: "Payment Gateway Integration",
  },
  {
    title: "Reporting",
  },
  {
    title: "Users, Admin and Super Admin",
  },
  {
    title: "User Authentication Module",
  },
  {
    title: "Users and Roles Management",
  },
];
