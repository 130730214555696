import { Box, Typography } from "@mui/material";
import React from "react";

const PartnersCard = ({ imageUrl, title, borderColor }) => {
  return (
    <>
      <Box>
        <Box
          display="flex"
          sx={{
            border: `1px solid ${borderColor}`,
            borderRadius: ".5rem",
            p: "1rem",
            height: "3rem",
          }}
        >
          <Box display="flex">
            <img
              src={imageUrl}
              style={{ margin: "auto" }}
              width="45"
              alt="partners-logo"
            />
          </Box>
          <Box display="flex">
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                m: "auto auto auto .5rem",
                fontWeight: "600",
                fontSize: { sm: ".9rem", md: ".7rem", lg: "1rem" },
              }}
              color={borderColor}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PartnersCard;
