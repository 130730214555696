import {
  Box,
  Divider,
  Grid,
  Typography,
  useTheme,
  // CardActions,
  // Button,
  // Link,
} from "@mui/material";
import React from "react";
// import { Link as RouterLink } from "react-router-dom";

import trainingImg from "../../../assets/images/training/trainingImg2.png";

const TrainingContent7 = () => {
  const theme = useTheme();

  return (
    <>
      <Box className="max__height">
        <Box
          sx={{
            m: "0 0 4rem 0",
            "& *": {
              lineHeight: 2,
            },
          }}
        >
          <Grid
            container
            spacing={{ xs: 0, sm: 0, md: 0, lg: 0 }}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            // wrap={"wrap-reverse"}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  bgcolor: theme.palette.blue[500],
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.secondary[1000],
                    fontWeight: "bold",
                    m: "auto",
                    p: "2rem",
                  }}
                  variant="h2"
                >
                  Product Design (Ui/UX) Course
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  "& img": {
                    margin: "auto",
                    width: "100%",
                  },
                }}
              >
                <img src={trainingImg} alt="img1" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: "4rem" }}>
          <Typography
            sx={{
              color: theme.palette.blue[600],
              fontWeight: "bold",
              textAlign: "center",
              mb: "2rem",
            }}
            variant="h2"
          >
            PRODUCT DESIGN
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              textAlign: "left",
              // mb: "2rem"
            }}
          >
            Unlock your potential as a product designer with our comprehensive
            training program. From ideation to prototyping, we'll teach you the
            skills and tools necessary to design products that meet user needs
            and stand out in the market. Our expert instructors will guide you
            through the design process, sharing their industry knowledge and
            best practices along the way. Join our program today and take the
            first step towards becoming a successful product designer."
          </Typography>
        </Box>
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Introduction to UI/UX
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Overview of UI & UX Design
              <br />
              Overview of the UX Design Process
              <br />
              Difference between User Interface (UI) vs User Experience (UX)
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 1
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              User Research
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              User Research ( Quantitative and Qualitative Research )
              <br />
              Importance of User Research in UX Design
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 2
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Designing Interactions
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              User Persona creation
              <br />
              Creative ideation – brainstorming and ideation techniques
              <br />
              Scenarios and functionality extraction
              <br />
              Information architecture
              <br />
              User Flow
              <br />
              Task flows
              <br />
              Wireframe design
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 3
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Elementary Sketching & Wireframing
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Sketching & Wireframing
              <br />
              Click through Wireframing
              <br />
              Prototyping Wireframes
              <br />
              Introduction and setting up the design tool - Figma
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 4 & 5
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Understand Style guides, elements, prototyping
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Building a Design System – Style guides; color palette, fonts,
              grid, iconography, UI elements, photography or imagery, and
              illustration
              <br />
              Use of grids in UI design
              <br />
              Design animations and interaction patterns for key UI elements
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 6 & 7
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Low-High Fidelity Design ( Visual Design )
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Inclusive Design and Designing for Accessibility
              <br />
              Building High-Fidelity Mockups
              <br />
              Designing Efficiently with Tools
              <br />
              Interaction Patterns
              <br />
              Designing animations and interactions
              <br />
              Testing ( User testing / Usability testing )
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 8 & 9
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Portfolio building and self branding
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Project development
              <br />
              Building your LinkedIn profile and portfolio
              <br />
              Introduction to Behance and Dribbble
              <br />
              Product Design career
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 10
            </Typography>
          </Box>
        </Box>
        <Divider />
      </Box>
    </>
  );
};

export default TrainingContent7;
