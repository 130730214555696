import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import UWlogo from "../../../assets/images/UW-logo1.png";
import mapIcon from "../../../assets/images/map.png";
import phone from "../../../assets/images/phone.png";
import mail from "../../../assets/images/mail.png";
// import plane from "../../../assets/images/plane.png";
import { ReactComponent as Ion1 } from "../../../assets/svgs/Icon.svg";
import { ReactComponent as Ion2 } from "../../../assets/svgs/Icon2.svg";
import { ReactComponent as Ion3 } from "../../../assets/svgs/Icon3.svg";
import { ReactComponent as Ion4 } from "../../../assets/svgs/Icon4.svg";
import { ReactComponent as Ion5 } from "../../../assets/svgs/Icon5.svg";
// import { ReactComponent as Plane } from "../../../assets/svgs/plane.svg";
import { ReactComponent as MessageUser } from "../../../assets/svgs/message-user.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box>
      <Box sx={{ bgcolor: theme.palette.blue[1000], p: "2.5rem 0" }}>
        <Container component="section">
          <Box
            sx={{
              display: { sx: "block", lg: "flex" },
              justifyContent: "space-between",
              "& .MuiTypography-root": {
                color: theme.palette.secondary.main,
              },
            }}
          >
            <Box display="flex">
              <Box sx={{ m: "auto" }}>
                <Box display="flex">
                  <MessageUser />
                  <Box sx={{ ml: "1.5rem", mb: "1.5rem" }}>
                    <Typography sx={{ fontWeight: "bold" }} variant="h2">
                      Ready to start a project
                    </Typography>
                    <Typography variant="h4">
                      Let's work on your project
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display="flex">
              <Button
                sx={{
                  p: ".5rem 2rem",
                  borderRadius: "4rem",
                  m: "auto",
                  border: `1px solid ${theme.palette.secondary.main}`,
                  "&:hover": {
                    color: theme.palette.secondary.main,
                  },
                }}
                onClick={() => {
                  navigate("contact-us");
                }}
                variant="outlined"
              >
                Request a quote
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box
        sx={{
          bgcolor: theme.palette.blue[500],
          p: "3rem 0",
          "& .nav-footer__link": {
            color: theme.palette.secondary.main,
            cursor: "pointer",
            "&:hover": {
              color: `${theme.palette.red.main}`,
            },
          },
          "& .MuiTypography-h4": {
            color: theme.palette.secondary.main,
          },
          "& .MuiDivider-root": {
            bgcolor: theme.palette.secondary[400],
          },
        }}
      >
        <Container component="section">
          <Grid
            container
            spacing={{ xs: 3, sm: 3, md: 3 }}
            columns={{ xs: 12, sm: 12, md: 13 }}
            sx={{
              "& .MuiTypography-h6": { mb: ".3rem" },
              "& .MuiTypography-h4": { mb: "1rem" },
              "& .MuiLink-root": { textDecoration: "none" },
              pb: "7rem",
            }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <Box>
                <Box sx={{ display: "box" }}>
                  <img src={UWlogo} width="200" alt="logo" />
                  <Typography
                    sx={{ mt: "1rem", color: theme.palette.secondary.main }}
                    varient="h4"
                  >
                    We use technology to modify, enhance, or automate financial
                    services for businesses or consumers.
                  </Typography>
                </Box>
                {/* <Box sx={{ mt: "1.5rem", position: "relative" }}>
                  <input
                    type="email"
                    className="input__field2"
                    placeholder="Enter your email"
                  />
                  <Plane
                    style={{
                      width: "2.5rem",
                      position: "absolute",
                      right: "6",
                      top: "-3",
                      cursor: "pointer",
                    }}
                  />
                </Box> */}
                <a
                  href="https://goo.gl/maps/kkS3UbtihTxNCzYt8"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-footer__link"
                >
                  <Box sx={{ display: "flex", mt: "1rem" }}>
                    <img
                      src={mapIcon}
                      alt="mapIcon"
                      style={{ height: "1rem", marginRight: ".6rem" }}
                    />
                    <Typography variant="h7">Google map location</Typography>
                  </Box>
                </a>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box>
                <Typography sx={{ fontWeight: "bold" }} variant="h4">
                  Our Services
                </Typography>

                <Typography variant="h6">
                  <Link className="nav-footer__link">
                    System Integrations & Designs
                  </Link>
                </Typography>

                <Typography variant="h6">
                  <Link className="nav-footer__link">
                    Solution Architecture
                  </Link>
                </Typography>

                <Typography variant="h6">
                  <Link className="nav-footer__link">Payment Solutions</Link>
                </Typography>

                <Typography variant="h6">
                  <Link className="nav-footer__link">Lending</Link>
                </Typography>

                <Typography variant="h6">
                  <Link className="nav-footer__link">WalletCore Banking</Link>
                </Typography>

                <Typography variant="h6">
                  <Link className="nav-footer__link">API Strategy</Link>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box>
                <Typography
                  sx={{ mb: ".4rem", fontWeight: "bold" }}
                  variant="h4"
                >
                  Company
                </Typography>
                <Link
                  to="/home"
                  className="nav-footer__link"
                  component={NavLink}
                >
                  <Typography variant="h6">Home</Typography>
                </Link>
                <Link
                  to="/about-us"
                  className="nav-footer__link"
                  component={NavLink}
                >
                  <Typography variant="h6">About Us</Typography>
                </Link>
                <Link
                  to="/our-services"
                  className="nav-footer__link"
                  component={NavLink}
                >
                  <Typography variant="h6">Services</Typography>
                </Link>
                <Link
                  to="/portfolio"
                  className="nav-footer__link"
                  component={NavLink}
                >
                  <Typography variant="h6">Portfolio</Typography>
                </Link>
                <Link
                  to="/uwl-partners"
                  className="nav-footer__link"
                  component={NavLink}
                >
                  <Typography variant="h6">UWL Partners</Typography>
                </Link>
                <Link
                  to="/our-team"
                  className="nav-footer__link"
                  component={NavLink}
                >
                  <Typography variant="h6">Team</Typography>
                </Link>
                <Link
                  to="/contact-us"
                  className="nav-footer__link"
                  component={NavLink}
                >
                  <Typography variant="h6">Contact Us</Typography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ "& .MuiTypography-h6": { mb: "1rem" } }}>
                <Typography
                  sx={{ mb: ".4rem", fontWeight: "bold" }}
                  variant="h4"
                >
                  Contact Us
                </Typography>
                <a
                  href="https://goo.gl/maps/kkS3UbtihTxNCzYt8"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-footer__link"
                >
                  <Box sx={{ display: "flex" }}>
                    <img
                      src={mapIcon}
                      alt="mapIcon"
                      style={{ height: "1rem", marginRight: ".6rem" }}
                    />
                    <Typography variant="h6">
                      The Office Apartment Building, Unit 4 (Right Wing, 1st
                      floor) #8 Rasheed Alaaba William Street (Road 13) Lekki
                      Phase 1 Lagos, Nigeria
                    </Typography>
                  </Box>
                </a>
                <a href="tel:2347032364108">
                  <Typography
                    sx={{ color: theme.palette.red[500] }}
                    variant="h6"
                  >
                    <img
                      src={phone}
                      alt="mapIcon"
                      style={{ height: ".9rem", marginRight: ".6rem" }}
                    />
                    +2347032364108
                  </Typography>
                </a>
                <a href="tel:2348033745378">
                  <Typography
                    sx={{ color: theme.palette.red[500] }}
                    variant="h6"
                  >
                    <img
                      src={phone}
                      alt="mapIcon"
                      style={{ height: ".9rem", marginRight: ".6rem" }}
                    />
                    +2348033745378
                  </Typography>
                </a>
                <a href="tel:2348060347351">
                  <Typography
                    sx={{ color: theme.palette.red[500] }}
                    variant="h6"
                  >
                    <img
                      src={phone}
                      alt="mapIcon"
                      style={{ height: ".9rem", marginRight: ".6rem" }}
                    />
                    +2348060347351
                  </Typography>
                </a>
                <a href="mailto:info@uridiumworks.com">
                  <Typography className="nav-footer__link" variant="h6">
                    <img
                      src={mail}
                      alt="mapIcon"
                      style={{
                        height: ".7rem",
                        marginRight: ".6rem",
                      }}
                    />
                    info@uridiumworks.com
                  </Typography>
                </a>
                <Box sx={{ p: "1rem 0" }}>
                  <Divider />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    "& div": {
                      bgcolor: theme.palette.blue[600],
                      width: "2rem",
                      height: "2rem",
                      borderRadius: "4rem",
                      display: "flex",
                      cursor: "pointer",
                    },
                    "& a svg": {
                      m: "auto",
                    },
                    "& a": {
                      mr: { xs: ".5rem", lg: "1rem" },
                    },

                    "& div:last-child": { mr: "0" },
                  }}
                >
                  <a
                    href="https://instagram.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Box>
                      <Ion1 />
                    </Box>
                  </a>
                  <a
                    href="https://facebook.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Box>
                      <Ion2 />
                    </Box>
                  </a>
                  <a
                    href="https://twitter.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Box>
                      <Ion3 />
                    </Box>
                  </a>
                  <a href="https://github.com" target="_blank" rel="noreferrer">
                    <Box>
                      <Ion4 />
                    </Box>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/uridium-technologies/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Box>
                      <Ion5 />
                    </Box>
                  </a>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Divider />
        <Box
          sx={{
            pt: "2.6rem",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Box sx={{ m: "auto" }}>
            <Typography
              variant="h6"
              color={{ color: theme.palette.secondary.main }}
              textAlign="center"
            >
              &copy; 2023 Uridium Works Limited. All rights reserved.
            </Typography>
          </Box>
          <Box sx={{ m: "auto" }}>
            <Box sx={{ display: "flex" }}>
              {/* <Box sx={{ m: "auto" }}> */}
              <Link to="#" component={RouterLink}>
                <Typography variant="h6" textAlign="center">
                  Privacy Policy
                </Typography>
              </Link>
              <Typography
                variant="h6"
                sx={{ m: "0 .5rem", color: theme.palette.secondary.main }}
                textAlign="center"
              >
                &
              </Typography>
              <Link to="#" component={RouterLink}>
                <Typography
                  variant="h6"
                  sx={{ color: theme.palette.red[500] }}
                  textAlign="center"
                >
                  Credits
                </Typography>
              </Link>
              {/* </Box> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
