import serviceImg1 from "../images/services/img1.png";
import serviceImg2 from "../images/services/img2.png";
import serviceImg3 from "../images/services/img3.png";
import serviceImg4 from "../images/services/img4.png";
import serviceImg5 from "../images/services/img5.png";
import serviceImg6 from "../images/services/img6.png";
import serviceImg7 from "../images/services/img7.png";
import serviceImg8 from "../images/services/img8.png";
import serviceImg9 from "../images/services/img9.png";
// import serviceImg10 from "../images/services/img10.png";
import serviceImg11 from "../images/services/img11.png";
import serviceImg12 from "../images/services/img12.png";
import serviceImg13 from "../images/services/img13.png";
import serviceImg14 from "../images/services/img14.png";
import serviceImg15 from "../images/services/img15.png";

export const servicesData = [
  {
    imgUrl: serviceImg1,
    title: "System Integrations & Designs",
    description:
      "Optimize your business operations with our systems integration and design services. We'll customize a solution that integrates seamlessly with your existing systems, increasing productivity and profitability. Contact us today to learn more!",
  },
  {
    imgUrl: serviceImg14,
    title: "Data Monetization",
    description:
      "Monetize your valuable data assets and create new revenue streams with our data monetization services. Our experts will help you package and sell your data through licensing, sales, or partnerships. Contact us to learn more.",
  },
  {
    imgUrl: serviceImg3,
    title: "Payment Solutions",
    description:
      "Simplify your payment process and improve your customer experience with our payment solutions. Our expert team will help you implement a customized payment system that integrates seamlessly with your business. From online payments to mobile payments, we've got you covered.",
  },
  {
    imgUrl: serviceImg4,
    title: "Solution Architecture",
    description:
      "Optimize your business with our solution architecture expertise. Our team will design a customized solution that fits your unique needs. Contact us to learn more.",
  },
  {
    imgUrl: serviceImg8,
    title: "Lending",
    description:
      "Get the funding you need to grow your business with our lending solutions. Our team of experts will help you secure a customized lending solution that fits your unique needs. From traditional loans to alternative financing options, we've got you covered. Contact us today to learn more",
  },
  {
    imgUrl: serviceImg9,
    title: "WalletCore Banking",
    description:
      "Experience the future of banking with our walletcore banking services. Our advanced technology platform provides a seamless and secure banking experience, allowing you to manage your finances anytime, anywhere. From account management to payments and transfers, our platform has everything you need. ",
  },
  {
    imgUrl: serviceImg13,
    title: "API Strategy",
    description:
      "Unlock the full potential of your business with our API strategy services. Our team of experts will help you design and implement a customized API strategy that integrates seamlessly with your existing systems, streamlines your workflow, and creates new revenue opportunities. Contact us today to learn more",
  },
  {
    imgUrl: serviceImg5,
    title: "Software Consultancy",
    description:
      "We assist organization to review performance of their in-house developed software architecture and come up with recommendations that best fit their business bearing growth in mind. For example we review NIP, loan applications etc.",
  },
  // {
  //   imgUrl: serviceImg10,
  //   title: "UridiumPostAgent",
  //   description:
  //     "Experience fast and reliable deliveries with our post agent services. Our team of agents provides personalized and efficient delivery services for all your postal needs. From letters to packages, we've got you covered. Contact us today to learn more and enjoy hassle-free deliveries with our post agent services.",
  // },
  {
    imgUrl: serviceImg6,
    title: "Anomaly Detection (Fraud Detection)",
    description:
      "Protect your business from fraud with our advanced fraud detection services. Our team of experts uses advanced technology and data analytics to detect and prevent fraudulent activity, keeping your business safe and secure. Contact us today to learn more and take the first step towards a fraud-free business.",
  },
  {
    imgUrl: serviceImg2,
    title: "Wealth Management",
    description:
      "Secure your financial future with our wealth management services. Our team of experts will help you develop a customized wealth management plan that aligns with your unique goals and objectives. From investment management to retirement planning, we've got you covered. ",
  },
  {
    imgUrl: serviceImg12,
    title: "InsureTech",
    description:
      "Experience the future of insurance with our insure tech services. Our advanced technology platform provides seamless and personalized insurance solutions, allowing you to easily manage your policies and claims. From auto to health insurance, we've got you covered. Contact us today to join the insure tech revolution.",
  },
  {
    imgUrl: serviceImg7,
    title: "TelegramBot Service",
    description:
      "Automate your communication with our telegram bot service. Our expert team will design and implement a customized telegram bot that streamlines your communication process, saves you time, and improves your customer experience. Contact us today to take the first step towards a more efficient communication process with our telegram bot service.",
  },
  {
    imgUrl: serviceImg11,
    title: "SmartInvoice",
    description:
      "Streamline your invoicing process with our smart invoice solutions. Our advanced technology platform provides a seamless and secure invoicing experience, allowing you to easily create and manage invoices, and get paid faster. Contact us today to learn more and take the first step towards smarter invoicing with our smart invoice solutions.",
  },
  {
    imgUrl: serviceImg15,
    title: "Software Development",
    description:
      "Bring your ideas to life with our software development services. Our expert team will help you design, develop, and deploy customized software solutions that meet your unique business needs. From web applications to mobile apps, we've got you covered. Contact us today to learn more ",
  },
];
