import React from "react";
import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { PartnersImg } from "components";
import partnerImg from "../../assets/images/partner-img.png";
import partner1 from "../../assets/images/partner1.png";
import partner2 from "../../assets/images/partner2.png";
import partner3 from "../../assets/images/partner3.png";
import partner4 from "../../assets/images/partner4.png";
import partner5 from "../../assets/images/partner5.png";
import partner6 from "../../assets/images/partner6.png";
import partner7 from "../../assets/images/partner7.png";
import partner8 from "../../assets/images/partner8.png";
import partner9 from "../../assets/images/partner9.png";
import partner10 from "../../assets/images/partner10.png";
import partner11 from "../../assets/images/partner11.png";
import partner12 from "../../assets/images/partner12.png";
import partner13 from "../../assets/images/partner13.png";

const UWPartners = () => {
  const theme = useTheme();

  const partnersData = [
    {
      imageUrl: partner1,
    },
    {
      imageUrl: partner2,
    },
    {
      imageUrl: partner3,
    },
    {
      imageUrl: partner4,
    },
    {
      imageUrl: partner5,
    },
    {
      imageUrl: partner6,
    },
    {
      imageUrl: partner7,
    },
    {
      imageUrl: partner8,
    },
    {
      imageUrl: partner9,
    },
    {
      imageUrl: partner10,
    },
    {
      imageUrl: partner11,
    },
    {
      imageUrl: partner12,
    },
    {
      imageUrl: partner13,
    },
  ];

  return (
    <>
      <Box>
        <Box
          sx={{
            position: "relative",
            m: "auto",
            p: {
              xs: "1rem",
              md: "5rem 4rem 8rem 4rem",
              lg: "8rem 6rem 12rem 6rem",
            },
            backgroundImage: `url(${partnerImg})`,
            backgroundRepeat: "no-repeat",
            // backgroundPositionX: { xs: "-25rem", sm: "-20rem", md: "0" },
            backgroundSize: "cover",
            overflow: "hidden",
            "&::before": {
              content: "''",
              position: "absolute",
              left: "0",
              right: "0",
              top: "0",
              bottom: "0",
              width: "inherit",
              height: "inherit",
              backgroundImage:
                "linear-gradient( to right bottom, rgba(0, 0, 0, .95), rgba(0, 0, 0, 0.25))",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              // left: { xs: "5%", md: "7%", lg: "10%" },
              // top: { xs: "10%", md: "18%", lg: "20%" },
              // p: { xs: "0 1rem", lg: "0" },
              "& .MuiTypography-root": { color: theme.palette.secondary.main },
            }}
          >
            <Box sx={{ zIndex: 20 }}>
              <Box display="flex">
                <Typography
                  sx={{
                    fontFamily: "Corinthia",
                    m: "auto .8rem auto 0",
                    fontSize: "3.5rem !important",

                    // transform: "scale(1.2)",
                  }}
                  variant="h1"
                >
                  uwl
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "2rem !important",
                      md: "3.5rem !important",
                      lg: "3.5rem !important",
                    },
                    fontWeight: "700",
                    m: "auto auto auto 0",
                  }}
                  variant="h1"
                >
                  Partners
                </Typography>
              </Box>
              <Typography
                sx={{
                  my: { xs: "0rem", md: ".4rem", lg: "1rem" },
                  fontSize: {
                    xs: "1rem !important",
                    sm: "1.4rem !important",
                    lg: "1rem !important",
                  },
                }}
                variant="h5"
              >
                Partnering with the best to create boundless impact
              </Typography>
              <Typography
                sx={{
                  width: { xs: "100%", sm: "85%", md: "70%", lg: "40%" },
                  fontSize: {
                    xs: "1rem !important",
                    sm: "1.4rem !important",
                    lg: "1rem !important",
                  },
                  mb: "1rem",
                }}
                variant="h5"
              >
                We don't take our partnerships lightly. Diverse perspectives are
                necessary to create a better world. Our partners are nimble
                startups that are quick to market and top tech companies that
                are reshaping society. You can have them as well.
                {/* <br /> */}
              </Typography>
              <Typography
                sx={{
                  width: { xs: "100%", sm: "85%", md: "70%", lg: "40%" },
                  fontSize: {
                    xs: "1rem !important",
                    sm: "1.4rem !important",
                    lg: "1rem !important",
                  },
                  // my: "1rem",
                }}
                variant="h5"
              >
                While achieving digital maturity requires effort, the process
                also yields many insights, especially if you are working with
                the right partner. We are dedicated to forming strategic
                alliances that will alter our industry. Will you join us?
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            bgcolor: theme.palette.blue[500],
            p: { xs: "1rem", md: "6rem" },
            "& .MuiTypography-h3": {
              color: theme.palette.secondary.main,
              mb: "4rem",
              fontWeight: "500",
              textAlign: "center",
            },
            "& .MuiTypography-h4": {
              color: theme.palette.secondary.main,
              fontWeight: 600,
              mb: ".5rem",
            },
            "& .MuiTypography-h6": {
              fontWeight: 400,
              color: theme.palette.secondary.main,
            },
          }}
        >
          <Container maxWidth="lg">
            <Typography variant="h3">Together we can...</Typography>
            <Grid
              container
              spacing={{ xs: 2, sm: 2, md: 2, lg: 15 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Typography variant="h4">Embrace the mission</Typography>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  We can increase the diversity of your workplace, workforce,
                  and technology through our specialized inclusion programming
                  and collaborations in digital accessibility.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Typography variant="h4">Achieve business goals</Typography>
                <Typography variant="h6">
                  Your businesses are aided and strengthened by our resources,
                  communities, and campaigns as we work to fulfill our
                  objective.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Typography variant="h4">Make a difference</Typography>
                <Typography variant="h6">
                  Delivering technology aids in attracting new customers,
                  enhancing your brand, and increasing customer loyalty.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Container maxWidth="lg">
          <Box sx={{ p: { xs: "1rem 0", md: "4rem 0" } }}>
            <Typography
              sx={{
                color: theme.palette.blue[500],
                fontWeight: "600",
                mb: ".6rem",
              }}
              variant="h3"
            >
              Uridium Works Partners
            </Typography>
            <Typography
              sx={{
                color: theme.palette.blue[500],
                fontWeight: "500",
                width: { xs: "100%", md: "70%" },
              }}
              variant="body1"
            >
              We generate limitless Impact by collaborating with leaders and
              innovators in the industry. Find out more about the work that we
              do together.
            </Typography>

            <Box
              component="div"
              display="flex"
              sx={{ flowGrow: "1", mt: "6rem" }}
            >
              <Grid
                container
                spacing={{ xs: 3, sm: 3, md: 3, lg: 5 }}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                {partnersData?.map((item, index) => (
                  <Grid item xs={6} sm={4} md={3} lg={3} key={index}>
                    <PartnersImg imageUrl={item?.imageUrl}></PartnersImg>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default UWPartners;
