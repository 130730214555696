import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { CommonButton, ServicesCard, TrainingCard } from "components";
import React from "react";
import { servicesData } from "assets/data/servicesData";
import dotImg from "assets/images/dotImg.png";
import strategyImg1 from "assets/images/strategyImg1.png";
import trainingImg1 from "assets/images/training/card1.png";
import trainingImg2 from "assets/images/training/card2.png";
import { Link } from "react-router-dom";
const OurServices = () => {
  const theme = useTheme();

  const trainingData = [
    {
      imgUrl: trainingImg1,
      title: "GCES",
      description:
        "We have built a solution that helps enforce control in Microsoft SQL server such that if anyone not authorized (developer or support staff, depending on your organizational access control policy) takes the username and password from an application config file and tries to logon through SSMS or Toad or any known tool, such application user will be denied access and thereby ensure the application user is only used by the application and not by a staff or any human. ",
    },
    {
      imgUrl: trainingImg2,
      title: "No Blame Culture",
      description:
        "This training seeks to open the eyes of engineers to see the impact of the monetary value they make their organization to lose once the blame culture is embraced and helps them come out of that mindset and focus more on the common interest and the goal of the organization thereby fostering collaboration, support and common interest amongst them.",
    },
  ];

  return (
    <>
      <Box>
        <Typography
          sx={{
            textAlign: "center",
            color: theme.palette.blue[500],
            fontWeight: "800",
            p: "5rem 0 2rem 0",
          }}
          variant="h1"
        >
          Our Services
        </Typography>
        <Typography
          sx={{
            color: theme.palette.grey[900],
            p: { xs: "1rem", lg: "0 20rem 4rem 20rem" },
            textAlign: "center",
            fontWeight: "500",
          }}
          variant="h4"
        >
          We are a Software company that provides Fintechsolutions, Training and
          Software Development services that adds value to our client enterprise
          business.
        </Typography>
        <Box sx={{ bgcolor: theme.palette.grey[100], p: "10rem 0" }}>
          <Container maxWidth="lg">
            <Grid
              container
              spacing={{ xs: 2, sm: 2, md: 2, lg: 4 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              // wrap={"wrap-reverse"}
            >
              {servicesData.map((service, index) => (
                <Grid item xs={12} sm={6} lg={3} key={index++}>
                  {/* <Link to="/single-services"> */}
                  <ServicesCard
                    imgUrl={service.imgUrl}
                    title={service.title}
                    description={service.description}
                  />
                  {/* </Link> */}
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        <Box sx={{ p: "10rem 0" }}>
          <Container maxWidth="lg">
            <Typography
              sx={{
                color: theme.palette.blue[500],
                fontWeight: "800",
                p: "0 0 1rem 0",
              }}
              variant="h1"
            >
              Consultancy and Training
            </Typography>
            <Typography
              sx={{
                color: theme.palette.grey[700],
                p: "1rem 0 2rem 0",
                width: "100%",
              }}
              variant="h6"
            >
              We have corporate expertise and professionals whose objectives are
              to help guide your business, with different consultants
              specializing in various industries and areas, including strategy
              and management, operations, human resources, finances, funding
              opportunities, IT, sales and marketing
            </Typography>

            <Box mt={4}>
              <Grid
                container
                spacing={{ xs: 2, sm: 2, md: 2, lg: 10 }}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                {trainingData.map((training, index) => (
                  <Grid item xs={12} sm={6} md={6} lg={6} key={++index}>
                    <TrainingCard
                      imgUrl={training.imgUrl}
                      title={training.title}
                      description={training.description}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
          <Box display="flex" sx={{ mt: "6rem", "& a": { m: "auto" } }}>
            <Link to="/training">
              <CommonButton
                size="large"
                variant="contained"
                sx={{
                  bgcolor: theme.palette.blue[500],
                  color: theme.palette.secondary[1000],
                  borderRadius: "30rem",
                  p: ".7rem 2rem",
                  "&:hover": {
                    bgcolor: theme.palette.blue[500],
                    color: theme.palette.secondary[1000],
                  },
                }}
                children="See All"
              />
            </Link>
          </Box>
        </Box>
        <Box sx={{ p: { sx: "1rem", md: "2rem", lg: "0  0 10rem 0" } }}>
          <Container maxWidth="lg">
            <Grid
              container
              spacing={{ xs: 2, sm: 2, md: 2, lg: 10 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <Grid item xs={12} sm={6} lg={6}>
                <Box display="flex" height="100%">
                  <Box margin="auto">
                    <Typography
                      sx={{
                        color: theme.palette.blue[500],
                        fontWeight: "800",
                        p: "0 0 1rem 0",
                      }}
                      variant="h1"
                    >
                      Our Strategy
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.grey[700],
                        fontWeight: "500",
                        p: "1rem 0 0 0",
                      }}
                      variant="h2"
                    >
                      Our Company Is Here
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.blue[500],
                        fontWeight: "500",
                        p: "0 0 1rem 0",
                      }}
                      variant="h2"
                    >
                      To Help You
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.grey[700],
                        fontWeight: "500",
                        p: "1rem 0 0 0",
                      }}
                      variant="h5"
                    >
                      We listen, collaborate and co-create solutions that meets
                      our customer’s expectations.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <img src={strategyImg1} width="100%" alt="s-img" />
              </Grid>
            </Grid>
            <Box
              sx={{
                p: { xs: "3rem 1rem", md: "2rem", lg: "6rem 5rem" },
                m: "8rem 2rem 2rem 2rem",
                borderRadius: "1.5rem",
                bgcolor: theme.palette.blue[500],
                boxShadow: ".2rem .2rem .6rem rgba(0,0,0,.1)",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: theme.palette.secondary.main,
                  fontWeight: "300",
                  p: "1rem 0 1rem 0",
                }}
                variant="h3"
              >
                ARE YOU READY?
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  color: theme.palette.grey[100],
                  fontWeight: "500",
                  p: { xs: "1rem 2rem", lg: "1rem 17rem" },
                }}
                variant="h1"
              >
                Lets Get Your Project Started
              </Typography>
              <Box display="flex" sx={{ "& a": { m: "auto" } }} mt="2rem">
                <Link to="/contact-us">
                  <CommonButton
                    sx={{
                      borderRadius: "3rem",
                      border: `1px solid ${theme.palette.secondary.main}`,
                      m: "auto",
                      p: ".3rem 1.5rem",
                      "&:hover": {
                        color: theme.palette.secondary.main,
                      },
                    }}
                    variant="outlined"
                    size="large"
                    children="Get in touch"
                  />
                </Link>
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  right: "-10rem",
                  top: "15rem",
                  "& img": {
                    height: "15rem",
                    opacity: ".2",
                  },
                }}
              >
                <img src={dotImg} alt="dot" />
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default OurServices;
