import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import uinvoiceHero from "../../assets/images/uinvoice-hero.png";
import uinvoiceImg2 from "../../assets/images/uinvoice-img2.png";
import { keyModulesData } from "../../assets/data/keyModulesData.js";
import { ArrowRightAlt } from "@mui/icons-material";
import { KeyModulesCard } from "components";

const UInvoice = () => {
  const theme = useTheme();

  return (
    <>
      <Box>
        <Box
          sx={{
            overflow: "hidden",
            pb: "5rem",
            height: "100vh",
            position: "relative",
            bgcolor: { xs: theme.palette.blue[500], lg: "transparent" },
          }}
        >
          <Box
            sx={{
              // bgcolor: "red !important",
              height: "77vh",
              // height: { xs: "90vh", lg: "85vh" },
              "& img": {
                width: { xs: "270%", lg: "100%" },
                height: "100%",
              },
            }}
          >
            <img src={uinvoiceHero} alt="uinvoice" />
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: { xs: "1rem", lg: "2rem" },
              width: "100%",
              p: "0  0 10rem 0",
            }}
          >
            <Container maxWidth="lg">
              <Grid
                container
                spacing={{ xs: 2, sm: 2, md: 2, lg: 10 }}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box display="flex" height="100%">
                    <Box margin="auto">
                      <Typography
                        sx={{
                          color: theme.palette.neutral[1000],
                          fontWeight: "700",
                          p: { sx: "0 0 1.5rem 0", lg: "0 0 5rem 0" },
                          fontSize: {
                            xs: "2rem !important",
                            lg: "3.5rem !important",
                          },
                        }}
                        variant="h1"
                      >
                        UInvoice
                      </Typography>
                      <Typography
                        sx={{
                          color: theme.palette.neutral[1000],
                          fontWeight: "700",
                          p: { xs: "0 0 .2rem 0", lg: "0 0 1rem 0" },
                        }}
                        variant="h2"
                      >
                        Online invoicing made easy with UInvoice
                      </Typography>
                      <Typography
                        sx={{
                          color: theme.palette.neutral[1000],
                          fontWeight: "500",
                          p: { xs: ".2rem 0 0 0", lg: "1rem 0 0 0" },
                        }}
                        variant="h5"
                      >
                        We are creating tools that will help your firm expand to
                        sell online, offer free invoices, handle payments,
                        create financial products, or process payments.
                      </Typography>
                      {/* <Box display="flex">
                        <Typography
                          sx={{
                            color: theme.palette.neutral[1000],
                            bgcolor: theme.palette.blue[500],
                            fontWeight: "500",
                            p: "1rem",
                            mt: { xs: "1rem", lg: "3rem" },
                          }}
                          variant="h3"
                        >
                          Coming Soon
                        </Typography>
                      </Box> */}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box
                    sx={{
                      display: "flex",
                      "& img": { width: "90%", m: "auto" },
                    }}
                  >
                    <img src={uinvoiceImg2} alt="uinvoice-img" />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
        <Container>
          <Box p margin="4rem auto">
            <Typography
              sx={{
                mt: "2rem ",
                color: theme.palette.blue[500],
                fontWeight: "700",
              }}
              variant="h2"
            >
              UInvoice
            </Typography>
            <Typography
              sx={{
                color: theme.palette.neutral[100],
                fontWeight: "500",
                pt: "2rem",
              }}
              variant="h5"
            >
              This is an online invoice management system that allows users to
              generate and send invoices to its client. With this, users do not
              need to bother about getting manual invoices. All you need do is
              log on to the platform, enter the details for the invoice and
              press send. The system will generate the invoice (pdf) and email
              it to the client. I am proposing that we have two different types
              of users (one-time users and re-current users).{" "}
              <b> UInvoice will keep track </b> of all invoice data issued from
              the first day till the current date. It will ide her users daily,
              weekly, monthly and yearly reports, exportable to word, excel or
              pdf. With <b> UInvoice </b>, individual clients get to manage
              their own space and control who does pieces of stuff within their
              space. UInvoice tends to target any institution or firm that
              issues invoices to its customers.
            </Typography>
          </Box>
          <Box p margin="4rem auto">
            <Typography
              sx={{
                mt: "2rem ",
                color: theme.palette.blue[500],
                fontWeight: "700",
              }}
              variant="h2"
            >
              Benefits of UInvoice
            </Typography>
            <Box
              sx={{
                display: "flex",
                mt: "2rem",
                "& svg": {
                  color: theme.palette.blue[500],
                  fontSize: "2.5rem",
                  mr: ".5rem",
                },
              }}
            >
              <ArrowRightAlt />
              <Typography
                sx={{
                  color: theme.palette.neutral[100],
                  fontWeight: "500",
                  m: "0 auto auto 0",
                }}
                variant="h5"
              >
                <b> Maintaining Records </b> — The ability to keep legal record
                is one of the most important benefits of an invoice. It helps to
                find out when a good was sold, who bought and who sold it.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                mt: "2rem",
                "& svg": {
                  color: theme.palette.blue[500],
                  fontSize: "2.5rem",
                  mr: ".5rem",
                },
              }}
            >
              <ArrowRightAlt />
              <Typography
                sx={{
                  color: theme.palette.neutral[100],
                  fontWeight: "500",
                  m: "0 auto auto 0",
                }}
                variant="h5"
              >
                <b> Legal Protection </b> — Invoice is a legal proof of an
                agreement between buyer and seller on a set price.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                mt: "2rem",
                "& svg": {
                  color: theme.palette.blue[500],
                  fontSize: "2.5rem",
                  mr: ".5rem",
                },
              }}
            >
              <ArrowRightAlt />
              <Typography
                sx={{
                  color: theme.palette.neutral[100],
                  fontWeight: "500",
                  m: "0 auto auto auto",
                }}
                variant="h5"
              >
                <b>Business Analytics </b> — Analysing invoices can help
                businesses gather information from their customer’s buying
                patterns and identify trends, popular products, peak buying
                times and more. This would help develop effective marketing
                strategies. UInvoice keeps track of your record of invoices and
                you can export it the way you want for your business analytics.
              </Typography>
            </Box>
          </Box>
          <Box p margin="4rem auto">
            <Typography
              sx={{
                mt: "2rem ",
                color: theme.palette.blue[500],
                fontWeight: "700",
              }}
              variant="h2"
            >
              How It Works
            </Typography>
            <Typography
              sx={{
                color: theme.palette.neutral[100],
                fontWeight: "500",
                pt: "2rem",
              }}
              variant="h5"
            >
              UInvoice allows her customer to sign up on the platform with
              email, phone, and a few other details. The reason for this is so
              that the customer can come back and be able to see her record of
              issued invoices. During this process, the customer is able to
              select if he is an individual customer or an organization. After
              signing up, the customer is able to generate an invoice and email
              it to her target customer. The target customer is able to see the
              pdf format of the generated invoice and then click on the embedded
              link to proceed to the payment gateway, where payment is made for
              the invoice, he/she received. <br />
              <br /> The customer immediately receives the payment into his bank
              account and the record is updated on UInvoice platform that the
              said payment has been completed. Upon successfully completing
              payment, settlement happens and some percentage goes to UInvoice
              as agreed with the payment provider. The customer has the option
              to choose whether to use a payment gateway or to receive payment
              directly into her account. I.e allowing the proposed payer to pay
              for the invoice by bank transfer. We intend to allow each
              institution’s admin to be able to approve or decline each invoice
              prepared, before sending it out to her clients or customers
            </Typography>
          </Box>
        </Container>
        <Typography
          sx={{
            color: theme.palette.blue[500],
            //   bgcolor: theme.palette.blue[500],
            textAlign: "center",
            width: "100%",
            m: "auto",
            fontWeight: "700",
          }}
          variant="h1"
        >
          Key Modules
        </Typography>
        <Container maxWidth="lg">
          <Box
            component="div"
            display="flex"
            sx={{ flowGrow: "1", p: "4rem 0" }}
          >
            <Grid
              container
              spacing={{ xs: 3, sm: 3, md: 3 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              {keyModulesData?.map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <KeyModulesCard
                    // key={index++}
                    imgUrl={item.imgUrl}
                    title={item.title}
                    description={item.description}
                    showmore={true}
                    height={true}
                  ></KeyModulesCard>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box mb="4rem">
            <Typography
              sx={{
                mt: "2rem ",
                color: theme.palette.blue[500],
                fontWeight: "700",
              }}
              variant="h2"
            >
              What We Are Building
            </Typography>
            <Typography
              sx={{
                color: theme.palette.neutral[100],
                fontWeight: "500",
                pt: "2rem",
              }}
              variant="h5"
            >
              We are building a web-based application and a mobile application,
              fully powered by APIs.
            </Typography>
          </Box>
          <Box mb="4rem">
            <Typography
              sx={{
                mt: "2rem ",
                color: theme.palette.blue[500],
                fontWeight: "700",
              }}
              variant="h2"
            >
              Why UInvoice?
            </Typography>
            <Typography
              sx={{
                color: theme.palette.neutral[100],
                fontWeight: "500",
                pt: "2rem",
              }}
              variant="h5"
            >
              Many at times, paper invoices get missing or even become bulky
              especially if they are much, which will make the office look
              untidied.
              <br />
              <br />
              <b>UInvoice </b> gives her customers the flexibility of keeping
              this electronically. These invoices can be called up or retrieved
              at any given time because they are stored in the cloud.
              <br />
              <br />
              <b>UInvoice </b> allows customers the flexibility of making
              payments with a click of a button.
              <br />
              <br />
              <b>UInvoice </b> is not limited to products, but also allows the
              flexibility of switching between products and services. It also
              allows customers the flexibility to create products on the fly,
              should the product not exist in the list provided.
              <br />
              <br />
              <b>With UInvoice</b>, each firm or institution gets to control
              what her staff does.
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default UInvoice;
