import {
  Box,
  Divider,
  Grid,
  Typography,
  useTheme,
  // CardActions,
  // Button,
  // Link,
} from "@mui/material";
import React from "react";
// import { Link as RouterLink } from "react-router-dom";

import trainingImg from "../../../assets/images/training/trainingImg1.png";

const TrainingContent1 = () => {
  const theme = useTheme();

  return (
    <>
      <Box className="max__height">
        <Box sx={{ m: "0 0 4rem 0" }}>
          <Grid
            container
            spacing={{ xs: 0, sm: 0, md: 0, lg: 0 }}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            // wrap={"wrap-reverse"}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  bgcolor: theme.palette.blue[500],
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.secondary[1000],
                    fontWeight: "bold",
                    m: "auto",
                    p: "2rem",
                  }}
                  variant="h2"
                >
                  Governance And Control Enforcement Services (GCES)
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  "& img": {
                    margin: "auto",
                    width: "100%",
                  },
                }}
              >
                <img src={trainingImg} alt="img1" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: "4rem" }}>
          <Typography
            sx={{
              color: theme.palette.blue[600],
              fontWeight: "bold",
              textAlign: "center",
              mb: "2rem",
            }}
            variant="h2"
          >
            GOVERNANCE AND CONTROL ADVISORY
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              textAlign: "left",
              // mb: "2rem"
            }}
          >
            Organizations are looking for how to block any form of access that
            could lead to financial leakage/loss intentionally or
            unintentionally and hence, We have built a solution that helps
            enforce control in Microsoft SQL server such that if anyone not
            authorized (developer or support staff, depending on your
            organizational access control policy) takes the username and
            password from an application config file and tries to logon through
            SSMS or Toad or any known tool, such application user will be denied
            access and thereby ensure the application user is only used by the
            application and not by a staff or any human. <br />
            <br />
            This will help check and ensure discipline amongst developers and
            also put your position in a good light to regulators as an
            additional layer of control that ensures checks and balances within
            the engineering space.
          </Typography>
        </Box>
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "bold",
              m: "2.5rem auto 1.5rem auto",
            }}
            variant="h3"
          >
            Importance Of (GCES)
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
            }}
          >
            A company without governance & control is deliberately exposing it’s
            control weakness that staffs with bad intentions can take advantage
            of. Customer's funds and your organizations income, needs to be
            protected through proper controls and governance to build trust.
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "2.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            The danger
          </Typography>
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                mt: "1.5rem",
                color: theme.palette.secondary[100],
              }}
            >
              Risk of losing funds
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "1rem",
              }}
            >
              If you run a payment system or a system that holds a form of
              monetary value, then you stand a risk of losing funds because a
              rogue staff could intentionally or unintentionally test the system
              by passing data directly into your database for onward processing
              just because they have direct access to the application database.
            </Typography>
          </Box>

          <Box
            sx={{
              color: theme.palette.secondary[100],
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                mt: "2.5rem",
              }}
            >
              Fraud
            </Typography>
            <Typography
              sx={{
                fontWeight: "500",
                lineHeight: "2",
                pt: "1rem",
              }}
            >
              Some developers or support staff can use tools like SSMS or Toad
              to connect with the application username and password seen in the
              config file of the application they support and make changes to
              data or add new record for your payment system to process and they
              delete such record without any trace.
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "2.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Our Services
          </Typography>
          <Box
            sx={{
              color: theme.palette.secondary[100],
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                m: "2.5rem auto 1.5rem auto",
              }}
            >
              Team of experts
            </Typography>
            <Typography
              sx={{
                fontWeight: "500",
                lineHeight: "2",
              }}
            >
              Uridiumworks have a team of experts that are experienced in
              payment solutions with proper control and governance practices in
              application architecture, implementation and design.
            </Typography>
          </Box>

          <Box
            sx={{
              color: theme.palette.secondary[100],
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                m: "2.5rem auto 1.5rem auto",
              }}
            >
              Quality testing
            </Typography>
            <Typography
              sx={{
                fontWeight: "500",
                lineHeight: "2",
              }}
            >
              We have built a script that helps enforce control in Microsoft SQL
              server such that if anyone (developer or support staff) takes the
              username and password from an application config file and tries to
              log-on through SSMS or Toad, such application user will be denied
              access and thereby ensure the application user is only used by the
              application and not by a staff.
            </Typography>
          </Box>

          <Box
            sx={{
              color: theme.palette.secondary[100],
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                m: "2.5rem auto 1.5rem auto",
              }}
            >
              Safe payment implementation
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
              }}
            >
              Our advisory services can help your institution implement your
              payment solution such that even if someone goes through the
              backend to input record directly, your payment system will ignore
              and report such record.
            </Typography>
          </Box>
        </Box>
        <Divider />
      </Box>
    </>
  );
};

export default TrainingContent1;
