import { useTheme } from "@emotion/react";


const useCustomStyles = () => {
  const theme = useTheme();

  return {
    buttonHover: {
      border: `1px solid ${theme.palette.secondary[1000]}`,
      bgcolor: `${theme.palette.red.main}`,
      // bgcolor: "transparent",
      boxShadow: "none",
      "transition-duration": "0.5s",
      color: theme.palette.secondary[1000],
    },
    
    commonButton: {
      borderRadius: "5rem",
      fontSize: "1rem",
      boxShadow: "none",
      border: `1px solid ${theme.palette.secondary[1000]}`,
      // bgcolor: theme.palette.blue[500],
      color: theme.palette.secondary[1000],
      textTransform: "capitalize",
    }
  }
}

export {useCustomStyles};
