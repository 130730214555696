import React from "react";

const TextArea = ({ placeholder, value, name, onChange }) => {
  return (
    <>
      <textarea
        className="text__area"
        placeholder={placeholder}
        id="w3review"
        name={name}
        onChange={onChange}
        rows="17"
      >
        {value}
      </textarea>
    </>
  );
};

export default TextArea;
