import {
  Box,
  Divider,
  Grid,
  Typography,
  useTheme,
  // CardActions,
  // Button,
  // Link,
} from "@mui/material";
import React from "react";
// import { Link as RouterLink } from "react-router-dom";

import trainingImg from "../../../assets/images/training/trainingImg7.png";

const TrainingContent8 = () => {
  const theme = useTheme();
  const style = {
    parentPosition: {
      position: "relative",
    },
    textBottom: {
      position: "absolute",
      bottom: 0,
    },
  };

  return (
    <>
      <Box className="max__height">
        <Box
          sx={{
            m: "0 0 4rem 0",
            "& *": {
              lineHeight: 2,
            },
          }}
        >
          <Grid
            container
            spacing={{ xs: 0, sm: 0, md: 0, lg: 0 }}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            // wrap={"wrap-reverse"}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  bgcolor: theme.palette.blue[500],
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.secondary[1000],
                    fontWeight: "bold",
                    m: "auto",
                    p: "2rem",
                  }}
                  variant="h2"
                >
                  C#
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  "& img": {
                    margin: "auto",
                    width: "100%",
                  },
                }}
              >
                <img src={trainingImg} alt="img1" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: "4rem" }}>
          <Typography
            sx={{
              color: theme.palette.blue[600],
              fontWeight: "bold",
              textAlign: "center",
              mb: "2rem",
            }}
            variant="h2"
          >
            C#
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              textAlign: "left",
              // mb: "2rem"
            }}
          >
            C# is a versatile and powerful programming language used to build a
            wide range of applications, from Windows desktop applications to
            mobile apps and games. With its simple syntax, robust class
            libraries, and strong typing system, C# is an ideal choice for
            developing scalable and maintainable software solutions. Whether
            you're an aspiring developer or an experienced programmer, C#
            training can help you master this essential language and take your
            career to the next level. So, enroll now and unlock the full
            potential of C# for your next project!
          </Typography>
        </Box>
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Beginner's Curriculum
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Introduction to C#
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Overview of C# and the .NET Framework
              <br />
              Installing Visual Studio
              <br />
              Writing a "Hello, World!" program
            </Typography>
          </Box>
          <Box sx={{ width: "15%", ...style.parentPosition }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
                ...style.textBottom,
              }}
              variant="h3"
            >
              Day 1
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Variables and Data Types and Control Structures
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Declaring and initializing variables
              <br />
              Primitive data types (int, double, bool, etc.)
              <br />
              Arithmetic operators
            </Typography>
          </Box>
          <Box sx={{ width: "15%", ...style.parentPosition }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
                ...style.textBottom,
              }}
              variant="h3"
            >
              Day 2
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Arrays and Lists
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Creating and initializing arrays
              <br />
              Accessing and modifying array elements
              <br />
              Lists and their methods (Add, Remove, Count, etc.)
            </Typography>
          </Box>
          <Box sx={{ width: "15%", ...style.parentPosition }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
                ...style.textBottom,
              }}
              variant="h3"
            >
              Day 3
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Portfolio Project:
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "3.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Intermediate Curriculum
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Web Development with ASP.NET Core
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Overview of ASPNET Core
              <br />
              Creating web applications using MVC pattern, minimal Apis
              <br />
              Handling HTTP requests and responses
              <br />
              Database Programming with ADO.NET
              <br />
              Connecting to databases using ADO.NET
              <br />
              Querying and updating data
              <br />
              Using CRM: Entity Framework Core and dapper.
            </Typography>
          </Box>
          <Box sx={{ width: "15%", ...style.parentPosition }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
                ...style.textBottom,
                bottom: 60,
              }}
              variant="h3"
            >
              Day 1 - 3
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Portfolio Project
          </Typography>
        </Box>
        <Divider />
      </Box>
    </>
  );
};

export default TrainingContent8;
