import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
// import { CommonButton } from "components";
import React from "react";
import { Link } from "react-router-dom";
import phone1 from "../../assets/images/phone1.png";
import phone2 from "../../assets/images/phone2.png";
import uinvoiceImg from "../../assets/images/uinvoice-img.png";
import uMarketPlace from "../../assets/images/umartketplace-img.png";
import uPayroll from "../../assets/images/upayroll-img.png";
import { CommonButton } from "components";

const Product = () => {
  const theme = useTheme();

  return (
    <>
      <Box>
        <Typography
          sx={{
            textAlign: "center",
            color: theme.palette.blue[500],
            fontWeight: "800",
            p: "5rem 0 2rem 0",
          }}
          variant="h1"
        >
          Our Products
        </Typography>
        <Typography
          sx={{
            color: theme.palette.grey[900],
            p: { xs: "1rem", lg: "0 20rem 4rem 20rem" },
            textAlign: "center",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
          variant="h4"
        >
          We have a living and changing collection of records that reflect our
          accomplishments, skills, experiences, and attributes.
        </Typography>
        <Container maxWidth="lg" sx={{ p: "10rem 0" }}>
          <Box
            sx={{
              overflow: "hidden",
              backgroundColor: theme.palette.grey[200],
              border: `1px solid ${theme.palette.grey[300]}`,
              borderRadius: ".5rem",
            }}
          >
            <Grid
              container
              spacing={{ xs: 2, sm: 2, md: 2, lg: 10 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box
                  sx={{
                    display: "flex",
                    // overflow: "hidden",
                    "& div": {
                      marginRight: "1.5rem",
                    },
                    "& div:last-child": { marginRight: "0" },
                  }}
                >
                  {/* <Box sx={{ display: "flex" }}> */}
                  <Box
                    height="100%"
                    sx={{
                      m: "auto",
                      // display: "flex",
                      position: "relative",
                      width: "100%",
                      height: "20rem",
                      "& img": {
                        position: "absolute",
                        pt: "1rem",
                        // m: "auto",
                        height: { xs: "100%", lg: "20rem" },
                      },
                      "& .phone1": {
                        top: "50%",
                        left: "45%",
                        transform: "translate(-50%, -50%)",
                      },
                      "& .phone2": {
                        top: "50%",
                        left: "55%",
                        transform: "translate(-50%, -50%)",
                      },
                    }}
                  >
                    <img src={phone1} className="phone1" alt="phone1" />
                    <img src={phone2} className="phone2" alt="phone2" />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Link to="/ussd">
                  <Box display="flex" height="100%">
                    <Box
                      sx={{
                        p: "1.5rem",
                        m: "auto",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "600",
                          m: "2rem 0",
                          color: theme.palette.primary[500],
                        }}
                        variant="h1"
                        gutterBottom
                      >
                        USSD
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          m: "2rem 0",
                          color: theme.palette.primary[500],
                        }}
                        variant="h4"
                        gutterBottom
                      >
                        Unstructured Supplementary Service <br />
                        Data without internet connection
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              height: { xs: "auto", lg: "20rem" },
              position: "relative",
              overflow: "hidden",
              backgroundColor: theme.palette.grey[200],
              mt: "3rem",
              border: `1px solid ${theme.palette.grey[300]}`,
              p: "0 2.5rem",
              borderRadius: ".5rem",
            }}
          >
            {/* <Box
                component="div"
                display="flex"
                // className="uinvoice__img"
                sx={{
                  position: "absolute",
                  left: "0",
                  right: "0",
                  top: "0",
                  bottom: "0",
                  width: "100%",
                  zIndex: "10",
                  bgcolor: "rgba(0, 0, 0, 0.55)",
                  display: "flex",
                }}
              >
                <Box margin="auto" textAlign="center" sx={{ zIndex: "200" }}>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      m: "2rem 0",
                      color: theme.palette.secondary[500],
                    }}
                    variant="h1"
                    gutterBottom
                  >
                    UInvoice
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      m: "2rem 0",
                      color: theme.palette.secondary[500],
                    }}
                    variant="h4"
                    gutterBottom
                  >
                    Create a Professional Invoice for your Business in minutes!
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      m: "2rem 0",
                      color: theme.palette.red[500],
                    }}
                    variant="h1"
                    gutterBottom
                    className="animate__flicker"
                  >
                    Coming Soon !!
                  </Typography>
                </Box>
               </Box> */}
            <Grid
              container
              spacing={{ xs: 3, sm: 3, md: 6 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      m: "5rem 0 1rem 0",
                      color: theme.palette.blue[500],
                    }}
                    variant="h4"
                    gutterBottom
                  >
                    Smart Invoice
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      mb: "1rem",
                      color: theme.palette.secondary[300],
                      width: { xs: "100%", lg: "25rem" },
                    }}
                    variant="h6"
                    gutterBottom
                  >
                    Create a Professional Invoice for your Business in minutes!
                  </Typography>
                  <a
                    href="https://calendly.com/uridiumworks/30min"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <CommonButton
                      variant="contained"
                      sx={{
                        bgcolor: theme.palette.primary[500],
                        color: theme.palette.secondary[900],
                        p: ".5rem 2.5rem",
                        mb: "1rem",
                        "&:hover": {
                          bgcolor: theme.palette.primary[500],
                          color: theme.palette.secondary[900],
                        },
                      }}
                      children="Request a Demo"
                    />
                  </a>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Link to="/u-invoice">
                  <Box
                    sx={{
                      // position: "absolute",
                      // display: "flex",
                      // right: "1.5rem",
                      // zIndex: "-10",
                      "& img": { width: { xs: "90%" } },
                    }}
                  >
                    <img src={uinvoiceImg} alt="uinvoice" />
                  </Box>
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Box
            component="div"
            display="flex"
            // className="uinvoice__img"
            sx={{
              height: { xs: "auto", lg: "20rem" },
              overflow: "hidden",
              position: "relative",
              mt: "3rem",
              p: "0 2.5rem",
              borderRadius: ".5rem",
              border: `1px solid ${theme.palette.grey[300]}`,
              backgroundColor: theme.palette.grey[200],
            }}
          >
            {/* <Box
                sx={{
                  position: "absolute",
                  left: "0",
                  right: "0",
                  top: "0",
                  bottom: "0",
                  width: "100%",
                  zIndex: "10",
                  bgcolor: "rgba(0, 0, 0, 0.55)",
                  display: "flex",
                }}
              >
                <Box margin="auto" textAlign="center" sx={{ zIndex: "200" }}>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      m: "2rem 0",
                      color: theme.palette.secondary[500],
                    }}
                    variant="h1"
                    gutterBottom
                  >
                    UMarketPlace
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      m: "2rem auto",
                      color: theme.palette.secondary[500],
                      textAlign: "center",
                      width: { xs: "100%", lg: "35rem" },
                    }}
                    variant="h4"
                    gutterBottom
                  >
                    A service aggregation solution that aggregates services and
                    makes it a self-service for customers
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      m: "2rem 0",
                      color: theme.palette.red[500],
                    }}
                    variant="h1"
                    gutterBottom
                    className="animate__flicker"
                  >
                    Coming Soon !!
                  </Typography>
                </Box>
              </Box> */}
            <Grid
              container
              spacing={{ xs: 3, sm: 3, md: 6 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      m: "5rem 0 1rem 0",
                      color: theme.palette.blue[500],
                    }}
                    variant="h4"
                    gutterBottom
                  >
                    UMarketplace
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      mb: "1rem",
                      color: theme.palette.secondary[300],
                      width: { xs: "100%", lg: "25rem" },
                    }}
                    variant="h6"
                    gutterBottom
                  >
                    A service aggregation solution that aggregates services and
                    makes it a self-service for customers
                  </Typography>
                  <a
                    href="https://calendly.com/uridiumworks/30min"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <CommonButton
                      variant="contained"
                      sx={{
                        bgcolor: theme.palette.primary[500],
                        color: theme.palette.secondary[900],
                        p: ".5rem 2.5rem",
                        mb: "1rem",
                        "&:hover": {
                          bgcolor: theme.palette.primary[500],
                          color: theme.palette.secondary[900],
                        },
                      }}
                      children="Request a Demo"
                    />
                  </a>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Link to="/u-marketplace">
                  <Box
                    sx={{
                      // position: "absolute",
                      // display: "flex",
                      // right: "1.5rem",
                      // zIndex: "-10",
                      "& img": { width: { xs: "90%" } },
                    }}
                  >
                    <img src={uMarketPlace} alt="uinvoice" />
                  </Box>
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Box
            component="div"
            display="flex"
            sx={{
              height: { xs: "auto", lg: "20rem" },
              overflow: "hidden",
              position: "relative",
              mt: "3rem",
              p: "0 2.5rem",
              borderRadius: ".5rem",
              border: `1px solid ${theme.palette.grey[300]}`,
              backgroundColor: theme.palette.grey[200],
            }}
          >
            {/* <Box
                sx={{
                  position: "absolute",
                  left: "0",
                  right: "0",
                  top: "0",
                  bottom: "0",
                  width: "100%",
                  zIndex: "10",
                  bgcolor: "rgba(0, 0, 0, 0.55)",
                  display: "flex",
                }}
              >
              <Box margin="auto" textAlign="center" sx={{ zIndex: "200" }}>
                  <Typography
                  sx={{
                    fontWeight: "600",
                      m: "2rem 0",
                      color: theme.palette.secondary[500],
                    }}
                    variant="h1"
                    gutterBottom
                    >
                    UMarketPlace
                    </Typography>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      m: "2rem auto",
                      color: theme.palette.secondary[500],
                      textAlign: "center",
                      width: { xs: "100%", lg: "35rem" },
                    }}
                    variant="h4"
                    gutterBottom
                  >
                    A service aggregation solution that aggregates services and
                    makes it a self-service for customers
                    </Typography>
                    <Typography
                    sx={{
                      fontWeight: "600",
                      m: "2rem 0",
                      color: theme.palette.red[500],
                    }}
                    variant="h1"
                    gutterBottom
                    className="animate__flicker"
                  >
                    Coming Soon !!
                  </Typography>
                </Box>
              </Box> */}
            <Grid
              container
              spacing={{ xs: 3, sm: 3, md: 6 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      m: "5rem 0 1rem 0",
                      color: theme.palette.blue[500],
                    }}
                    variant="h4"
                    gutterBottom
                  >
                    UPayroll
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      mb: "1rem",
                      color: theme.palette.secondary[300],
                      width: { xs: "100%", lg: "25rem" },
                    }}
                    variant="h6"
                    gutterBottom
                  >
                    The platform provides a seamless experience for managing all
                    aspects of payroll, from calculating wages and taxes to
                    generating payslips and making payments.
                  </Typography>
                  <a
                    href="https://calendly.com/uridiumworks/30min"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <CommonButton
                      variant="contained"
                      sx={{
                        bgcolor: theme.palette.primary[500],
                        color: theme.palette.secondary[900],
                        p: ".5rem 2.5rem",
                        mb: "1rem",
                        "&:hover": {
                          bgcolor: theme.palette.primary[500],
                          color: theme.palette.secondary[900],
                        },
                      }}
                      children="Request a Demo"
                    />
                  </a>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Link to="#">
                  {/* <Link to="/u-marketplace"> */}
                  <Box
                    sx={{
                      // position: "absolute",
                      // display: "flex",
                      // right: "1.5rem",
                      // zIndex: "-10",
                      "& img": { width: { xs: "90%" } },
                    }}
                  >
                    <img src={uPayroll} alt="upayroll" />
                  </Box>
                </Link>
              </Grid>
            </Grid>
          </Box>
          {/* <Box sx={{ display: "flex" }}>
            <Link to="/u-invoice" style={{ margin: "auto" }}>
              <CommonButton
                sx={{
                  textTransform: "none",
                  borderRadius: "4rem",
                  m: "2.5rem auto auto auto",
                  alignItems: "center",
                  p: ".5rem 2rem",
                  fontWeight: "bold",
                  bgcolor: theme.palette.blue[500],
                  color: theme.palette.secondary[1000],
                  "&:hover": {
                    bgcolor: theme.palette.blue[400],
                  },
                }}
                size="large"
                variant="contained"
                children="See more"
              ></CommonButton>
            </Link>
          </Box> */}
        </Container>
      </Box>
    </>
  );
};

export default Product;
