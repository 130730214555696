import React, { useEffect } from "react";
import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const KeyModulesCard = ({ title }) => {
  const theme = useTheme();
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  const boxVariant = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1, ease: "easeInOut" },
    },
    hidden: { opacity: 0, x: -50 },
  };

  return (
    <>
      <motion.div
        ref={ref}
        variants={boxVariant}
        initial="hidden"
        animate={control}
      >
        <Card
          sx={{
            // maxWidth: 300,
            bgcolor: theme.palette.blue[600],
            height: "13rem",
            "&:last-child": {
              // marginRight: "0",
            },
            "& .MuiTypography-h3": {
              color: theme.palette.neutral[1000],
              fontWeight: "600",
              textAlign: "center",
              margin: "auto",
            },
            margin: "auto",
            boxShadow: "none",
            borderRadius: "7px",
          }}
        >
          <Box
            component="div"
            sx={{
              p: "1.5rem .2rem",
              height: "100%",
            }}
          >
            <CardContent
              sx={{
                height: "100%",
                display: "flex",
                fontColor: theme.palette.secondary.dark,
                // textAlign: align,
              }}
            >
              <Typography gutterBottom variant="h3" component="div">
                {title}
              </Typography>
            </CardContent>
          </Box>
        </Card>
      </motion.div>
    </>
  );
};

export default KeyModulesCard;
