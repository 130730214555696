import {
  Box,
  Divider,
  Grid,
  Typography,
  useTheme,
  // CardActions,
  // Button,
  // Link,
} from "@mui/material";
import React from "react";
// import { Link as RouterLink } from "react-router-dom";

import trainingImg from "../../../assets/images/training/trainingImg4.png";

const TrainingContent5 = () => {
  const theme = useTheme();

  return (
    <>
      <Box className="max__height">
        <Box
          sx={{
            m: "0 0 4rem 0",
            "& *": {
              lineHeight: 2,
            },
          }}
        >
          <Grid
            container
            spacing={{ xs: 0, sm: 0, md: 0, lg: 0 }}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            // wrap={"wrap-reverse"}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  bgcolor: theme.palette.blue[500],
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.secondary[1000],
                    fontWeight: "bold",
                    m: "auto",
                    p: "2rem",
                  }}
                  variant="h2"
                >
                  Robotics Process Automation Using UIPATH
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  "& img": {
                    margin: "auto",
                    width: "100%",
                  },
                }}
              >
                <img src={trainingImg} alt="img1" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: "4rem" }}>
          <Typography
            sx={{
              color: theme.palette.blue[600],
              fontWeight: "bold",
              textAlign: "center",
              mb: "2rem",
            }}
            variant="h2"
          >
            DESCRIPTION
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              textAlign: "left",
              // mb: "2rem"
            }}
          >
            Today, everything is automated; businesses that were previously
            paper based have now adopted automated software systems to help them
            with their tasks. Every organization is looking to reduce staff by
            making time-consuming processes self-regulated, thereby increasing
            productivity. Robotic Process Automation (RPA) is the use of
            software robots to automate business processes. <br />
            <br />
            This course will begin by teaching you about the scope and
            techniques of automation, as well as what should and can be
            automated. You'll learn how robotic process automation works, its
            benefits, components, and the various platforms where it can be
            used. Learn how to use UiPath Studio and its various features such
            as the user interface, panels, and task recorder. You will also
            learn about different Sequences, Flowcharts, and Control Flows such
            as if-else, while, delay, break, switch, and so on. Aside from data
            manipulation and file operations, you will also learn about various
            plugins and extensions such as Terminal plugin, SAP automation, Java
            plugin, Citrix automation, Mail plugin, PDF plugin, Web integration,
            Excel and Word plugins, and so on. Learn how to debug a programmed
            robot, including logging and handling exceptions. By the end of this
            course, you'll be able to deploy and control Bots with UiPath
            Orchestrator while maintaining code version and source control. This
            course will teach you how to build your first bot and how to deploy
            and monitor all the above features. You can use this bot to perform
            various data manipulation and file management tasks.
            <br />
            <br /> By the end of this course, you will not only be able to build
            your first software bot, but you will also be able to wire it to
            perform various automation tasks using best bot deployment
            practices.
          </Typography>
        </Box>
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Requirements
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            A modem windows computer with internet connectionA modem windows
            computer with Internet connection
            <br />
            Basic job experience at a small, medium, or large company.
            <br />
            Be familiar with the roles that MS Excel spreadsheets, MS Word,
            websites, and software applications fill in daily business life.
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Who is this course for:
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Anyone who wants to improve their chances of getting and keeping a
            white-collar job in the near future
            <br />
            Test automation engineers who want to make a larger impact and
            increase earning potential
            <br />
            Business leaders who want to automate operations
            <br />
            Anyone wanting to save time, reduce errors, and improve efficiency
            by automating common business tasks People who use computers
            frequently but don't feel confident about their technical skills
            <br />
            People who use websites but have no idea how they were created or
            how we can automate them
            <br />
            People who have never dealt with variables and data types &
            structures like int, decimal string list, etc
            <br />
            Anyone who has never had to read & write data to files using
            computer programming techniques
            <br />
            Anyone who wants to improve their chances of getting and keeping a
            white-collar job in the near future
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Course Content
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            <Typography component="span" fontWeight="bold">
              Overview:
            </Typography>
            <br />
            About the course
            <br />
            About Me
            <br />
            Why UiPath
            <br />
            UiPath infrastructure & licenses overview
            <br />
            End to End Demo: Build, publish, and run a simple robot
            <br />
            Where to go for fast support
            <br />
            Exercise: Join our community
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            UiPath Installation (Windows)
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            2023 Installation overview
            <br />
            2023 Install UiPath
            <br />
            Install Important Packages
            <br />
            Examine the installed Activities
            <br />
            Set up browsers
            <br />
            2023 UiPath version upgrades
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            First Look-UiPath Studio
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Overview
            <br />
            Create a new project (2023)
            <br />
            User Interface quick overview (2023)
            <br />
            Fee Basic Settings
            <br />
            Naming Conventions
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            UiPath Skills
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Overview
            <br />
            Project structure choices.
            <br />
            Sequence basics
            <br />
            Flowchart basica
            <br />
            State machine basics
            <br />
            Display a message box
            <br />
            Logging to the output window
            <br />
            Open and read a text file
            <br />
            Looping to repeat work-overview For each loop demo
            <br />
            Send email
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Debugging with UiPath
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Run in debug mode
            <br />
            Set a break point and debug line by line
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Data Handling with UiPath
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Overview
            <br />
            HTML5 Basics, tags elements and attributes
            <br />
            Using the Variables pane
            <br />
            Browsing for special variable types
            <br />
            Using the "Assign Activity
            <br />
            Variables as the output of activities
            <br />
            Variable scope
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Control Flow in UiPath
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            It/Else Making a choice
            <br />
            Flowchart decision diamond
            <br />
            Switch: Handling many choices
            <br />
            Flow Switch Handling many choices
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Error Handling
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Try/Catch intro-Catch an exception
            <br />
            Create & throw a new exception
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Robot Control with UiPath Orchestrator
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Orchestrator overview
            <br />
            UPath cloud platform notes
            <br />
            Log in to Cloud/Orchestrator account
            <br />
            Login setup, and tour the Ul
            <br />
            Connect a robot
            <br />
            Create environment and associate robot
            <br />
            Publish a package
            <br />
            Create a process from the package
            <br />
            Run an attended robot and examine logs
            <br />
            Fix the robot and re-publish
            <br />
            Trigger an unattended robot at a specific time
            <br />
            Assets: overview & creation
            <br />
            Assets: consuming from a robot
            <br />
            Robot-level input parameters
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Web Automation
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Create the assets & project
            <br />
            Retrieve the assets
            <br />
            Open and close the browser
            <br />
            Login and logout
            <br />
            Perform a search
            <br />
            Enabling pop-up windows
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Reusable Workflow
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Overview
            <br />
            Create a reusable/shareable workflow
            <br />
            Pass data into a reusable workflow
            <br />
            Get data out of a reusable workflow
            <br />
            "Extract" a workflow"
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Data Tables
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Overview
            <br />
            Tour of data table activities
            <br />
            Create a data table
            <br />
            Edit data table columns
            <br />
            Add data table rows
            <br />
            Loop through the rows of a data table
            <br />
            Query the table
            <br />
            Delete a data table row
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Excel Automation Basics
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Overview
            <br />
            Excel/worksheet activity overview
            <br />
            Read worksheet into a data table
            <br />
            Write data table into a worksheet
            <br />
            Append data table into a worksheet
            <br />
            Read and write worksheet CELL data
            <br />
            Read range, transform table, and write range
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Database Automation Basics
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Overview
            <br />
            Database interaction with UiPath
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Source Control in UiPath
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Overview
            <br />
            Create a remote GIT repo
            <br />
            Connect to GIT repo
            <br />
            Add robot project to GIT
            <br />
            User 1 open & lock
            <br />
            Collaboration User1 Collaboration - User 2
            <br />
            Bulk-unda bad changes
            <br />
            Use labels for milestones
            <br />
            View project change history
            <br />
            UiPath built-in GIT notes
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Using Queues and Transactions
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Overview
            <br />
            About Queues
            <br />
            About Queueltema
            <br />
            Explore Queue-related activities
            <br />
            User 1 open & lock
            <br />
            Create a Queue
            <br />
            Queue item vs Transaction
            <br />
            DEMO: Add a queue item
            <br />
            Get queue item
            <br />
            Add transaction item
            <br />
            Transaction output
            <br />
            Set transaction progress
            <br />
            Manual review features
            <br />
            Auto-Retry deep dive
            <br />
            Get queue items
            <br />
            Process a queue with multiple bots
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Where to Go from Here?
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Thank You!
            <br />
            Become an Alumni
            <br />
            Linkedin Endorsements
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Bonus Section
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Introduction to REFramework
          </Typography>
        </Box>
        <Divider />
      </Box>
    </>
  );
};

export default TrainingContent5;
