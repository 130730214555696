import trainingImg1 from "../images/training/img1.png";
import trainingImg2 from "../images/training/img2.png";
import trainingImg3 from "../images/training/img3.png";
import trainingImg4 from "../images/training/img4.png";
import trainingImg5 from "../images/training/img5.png";
import trainingImg6 from "../images/training/img6.png";
import trainingImg7 from "../images/training/img7.png";
import trainingImg8 from "../images/training/img8.png";
import trainingImg9 from "../images/training/img9.png";

export const trainingData = [
  {
    imgUrl: trainingImg1,
    title: "Governance And Control Enforcement Services (GCES)",
    description:
      "Governance & Control Advisory services Organizations are looking for how to block any form of access that could lead to financial leakage/loss intentionally or unintentionally and hence, We have .....",
  },
  {
    imgUrl: trainingImg2,
    title:
      "No Blame Culture Training for Engineers “It is your fault... not mine”...",
    description:
      "This training seeks to open the eyes of engineers (Software Developers, Application management and support, QA & DevOps, etc.) to see the impact of the monetary value they make in their organization...",
  },
  {
    imgUrl: trainingImg3,
    title: "Automation and Business Applications Training Using Power Platform",
    description:
      "We invest in training for Power Apps, Power Automate, and SharePoint which can help organizations increase productivity, improve collaboration, create customized solutions, reduce costs, gain a competitive advantage, and future-proof their operations...",
  },
  {
    imgUrl: trainingImg4,
    title: "Front end Development",
    description:
      "This training is an online curriculum which helps Frontend Web Developers to understand the history and evolution of the web, how it works and learn basics of HTML, its tags, elements and attributes,  basics of CSS and advanced concepts of CSS such as Flexbox, Grid Animations etc...",
  },
  {
    imgUrl: trainingImg5,
    title: "Robotics Process Automation Using UIPATH",
    description:
      "Today, everything is automated; businesses that were previously paper based have now adopted automated software systems to help them with their tasks. Every organization is looking to reduce staff by making time-consuming processes self-regulated, thereby increasing productivity...",
  },
  {
    imgUrl: trainingImg6,
    title: "Python for Data Science",
    description:
      "This training helps you Learn the basic introduction to python, why learn python for data analytics, and installation of the jupyter notebook using anaconda for your coding and the different data types. You will also learn how to do print formatting, placeholder, indexing and slicing, dictionaries, tuples, set, Boolean, exercise on input and print formatting...",
  },
  {
    imgUrl: trainingImg7,
    title: "Product Design UI/UX Course",
    description:
      "This training will help you oversee the design process of a product from start to finish or the improvement of an existing product. You will learn an overview of UI & UX Design Process and the difference between User Interface (UI) vs User Experience (UX) and how to make Quantitative and Qualitative Research...",
  },
  {
    imgUrl: trainingImg8,
    title: "C# Training",
    description:
      "The C# programming language offers flexible and comprehensive libraries that make it easy to work across operating systems. That’s why it’s widely used by companies like Microsoft and Stack Overflow. Whether you want to develop websites, apps, video games, or virtual reality, building your foundation is key. In this course, you’ll learn C# basics so you can become a more versatile programmer.",
  },
  {
    imgUrl: trainingImg9,
    title: "Data Engineering",
    description:
      "Unlock the power of data and elevate your career with our Data Engineering Training. Our comprehensive program covers everything you need to know to become a skilled data engineer, from data modeling and storage to ETL processes and pipeline management. You'll learn the latest tools and techniques used in the industry, including Python, SQL, Hadoop, and more. Our expert instructors will.",
  },
];
