import { Box, Container, Typography, useTheme } from "@mui/material";
import { CommonButton } from "components";
import React from "react";
import heroImg from "../../assets/images/ussd/hero-img.png";
import img1 from "../../assets/images/ussd/img1.png";
import img2 from "../../assets/images/ussd/img2.png";

const Ussd = () => {
  const theme = useTheme();
  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            mb: 10,
            backgroundImage: `linear-gradient( to left bottom, #F6F6F6, #E3E3E3)`,

            // bgcolor: theme.palette.grey[200],
          }}
        >
          <Box
            sx={{
              m: "auto",
              width: { xs: "100%", lg: "60%" },
              p: { xs: "2rem", lg: "6rem 0 6rem 6rem" },
              //   bgcolor: "red !important",
            }}
          >
            <Typography
              variant="h1"
              fontWeight="bold"
              color={theme.palette.blue[600]}
              sx={{ mb: 2 }}
            >
              No internet connectivity
            </Typography>
            <Typography
              variant="h1"
              fontWeight="bold"
              color={theme.palette.blue[500]}
              sx={{ mb: 2 }}
            >
              No problem.
            </Typography>
            <Typography
              variant="h4"
              color={theme.palette.grey[900]}
              sx={{ mb: 2, width: { xs: "100%", lg: "70%" } }}
            >
              USSD can help your customers access your services without having
              data or internet connections
            </Typography>

            <CommonButton
              variant="outlined"
              size="large"
              sx={{
                border: `1px solid ${theme.palette.blue[500]}`,
                color: theme.palette.blue[500],
                fontWeight: "500",
                borderRadius: "30rem",
                "&:hover": {
                  border: `1px solid ${theme.palette.blue[500]}`,
                  color: theme.palette.blue[500],
                },
              }}
              children="Get started"
            />
          </Box>
          <Box
            sx={{
              display: { xs: "none", lg: "flex" },
              width: "40%",
              "& img": {
                m: "auto auto auto 0",
                width: "50%",
              },
            }}
          >
            <img src={heroImg} alt="hero-img" />
          </Box>
        </Box>
        <Container maxWidth="lg">
          <Box>
            <Box flexWrap="wrap-reverse" sx={{ display: "flex", mb: 10 }}>
              <Box
                sx={{
                  m: "auto",
                  width: { xs: "100%", lg: "30%" },
                  p: "4rem 0",
                  "& .MuiTypography-h4": {
                    mb: 2,
                    textAlign: { xs: "center", lg: "left" },
                    color: theme.palette.blue[500],
                  },
                }}
              >
                <Typography variant="h4">Registration</Typography>
                <Typography variant="h4">Intra-bank transfer</Typography>
                <Typography variant="h4">Inter-bank transfer</Typography>
                <Typography variant="h4">Airtime purchase (Self)</Typography>
                <Typography variant="h4">Airtime purchase (Other)</Typography>
                <Typography variant="h4">Data purchase (Self)</Typography>
                <Typography variant="h4">Data purchase (Others)</Typography>
                <Typography variant="h4">Balance enquiry</Typography>
                <Typography variant="h4">Opt-out (Deregister)</Typography>
              </Box>
              <Box
                sx={{
                  m: "auto",
                  width: { xs: "100%", lg: "70%" },
                  "& img": {
                    width: "100%",
                    borderRadius: "1rem",
                  },
                }}
              >
                <img src={img1} alt="img1" />
              </Box>
            </Box>
            <Box flexWrap="wrap" sx={{ display: "flex", mb: 10 }}>
              <Box
                sx={{
                  m: "auto",
                  width: { xs: "100%", lg: "50%" },
                  "& img": {
                    width: { xs: "100%", lg: "70%" },
                    //   pr: "10rem",
                    borderRadius: "1rem",
                  },
                }}
              >
                <img src={img2} alt="img2" />
              </Box>
              <Box
                sx={{
                  m: "auto",
                  width: { xs: "100%", lg: "50%" },
                  p: "4rem 0",
                }}
              >
                <Typography
                  variant="h1"
                  fontWeight="400"
                  color={theme.palette.grey[900]}
                  sx={{ mb: 2 }}
                >
                  USSD ADOPTION
                </Typography>
                <Typography
                  variant="h4"
                  color={theme.palette.grey[900]}
                  sx={{ mb: 2, width: { xs: "100%", lg: "90%" } }}
                >
                  The adoption rate of USSD cuts across different sectors of the
                  economy such as Banking,Telecoms, Government agencies and the
                  likes.
                </Typography>

                <CommonButton
                  variant="outlined"
                  size="large"
                  sx={{
                    border: `1px solid ${theme.palette.blue[500]}`,
                    color: theme.palette.blue[500],
                    fontWeight: "500",
                    borderRadius: "30rem",
                    "&:hover": {
                      border: `1px solid ${theme.palette.blue[500]}`,
                      color: theme.palette.blue[500],
                    },
                  }}
                  children="Build with us"
                />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Ussd;
