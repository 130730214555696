import {
  Box,
  Divider,
  Grid,
  Typography,
  useTheme,
  // CardActions,
  // Button,
  // Link,
} from "@mui/material";
import React from "react";
// import { Link as RouterLink } from "react-router-dom";

import trainingImg from "../../../assets/images/training/trainingImg8.png";

const TrainingContent9 = () => {
  const theme = useTheme();

  return (
    <>
      <Box className="max__height">
        <Box
          sx={{
            m: "0 0 4rem 0",
            "& *": {
              lineHeight: 2,
            },
          }}
        >
          <Grid
            container
            spacing={{ xs: 0, sm: 0, md: 0, lg: 0 }}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            // wrap={"wrap-reverse"}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  bgcolor: theme.palette.blue[500],
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.secondary[1000],
                    fontWeight: "bold",
                    m: "auto",
                    p: "2rem",
                  }}
                  variant="h2"
                >
                  Data Engineering
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  "& img": {
                    margin: "auto",
                    width: "100%",
                  },
                }}
              >
                <img src={trainingImg} alt="img1" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: "4rem" }}>
          <Typography
            sx={{
              color: theme.palette.blue[600],
              fontWeight: "bold",
              textAlign: "center",
              mb: "2rem",
            }}
            variant="h2"
          >
            DATA ENGINEERING
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              textAlign: "left",
              // mb: "2rem"
            }}
          >
            Unlock the power of data and elevate your career with our Data
            Engineering Training. Our comprehensive program covers everything
            you need to know to become a skilled data engineer, from data
            modeling and storage to ETL processes and pipeline management.
            You'll learn the latest tools and techniques used in the industry,
            including Python, SQL, Hadoop, and more. Our expert instructors will
            guide you every step of the way, ensuring you have the skills to
            build robust and scalable data systems. Don't miss out on this
            opportunity to transform your career in data engineering.
          </Typography>
        </Box>
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Introduction to Data Engineering
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            What is DE?
            <br />
            Data Engineering, Data Analysis and Data Science - Differences and
            point of meet.
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Concept of ETL
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            ETL tools and Languages
            <br />
            ETL Process
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Data Ingestion/ Extraction
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Definition and process
            <br />
            Common tools and how they work.
            <br />
            Ingestion/Extraction Quality Check
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Data Transformation and Loading
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Definition and Process
            <br />
            Data Storage
            <br />
            Transformation Quality Check
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Data Validation and Quality Check
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Uniqueness.
            <br />
            Completeness
            <br />
            Timeline
            <br />
            Accuracy
            <br />
            Consistency
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Generating Reports
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Date Manipulation using SQL, Python
            <br />
            Data Activation/Visualization
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
          }}
        >
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            Data Activation/Visualization
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              lineHeight: "2",
              pt: "2rem",
            }}
          >
            Excel
            <br />
            Power BI
            <br />
            Tableau
            <br />
            Zoho
          </Typography>
        </Box>
        <Divider />
      </Box>
    </>
  );
};

export default TrainingContent9;
