import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Container, Link, Typography, useTheme } from "@mui/material";
import img1 from "../../../src/assets/images/why-choose-us/img1.png";
import img2 from "../../../src/assets/images/why-choose-us/img2.png";
import img3 from "../../../src/assets/images/why-choose-us/img3.png";
import img4 from "../../../src/assets/images/why-choose-us/img4.png";
import { CommonButton } from "components";

const WhyChooseUs = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <>
      <Box sx={{ pb: "10rem" }}>
        <Typography
          sx={{
            textAlign: "center",
            color: theme.palette.blue[500],
            fontWeight: "800",
            p: "5rem 0 2rem 0",
          }}
          variant="h1"
        >
          Why Choose Us?
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            color: theme.palette.secondary[100],
            fontWeight: "500",
            p: "0 0 7rem 0",
            width: "95%",
            m: "auto",
          }}
          variant="h5"
        >
          We make it easy for you to build what your customers want
        </Typography>
        <Container maxWidth="lg">
          <Box
            sx={{
              display: { sm: "block", lg: "flex" },
              justifyContent: "space-between",
              mb: "3rem",
            }}
          >
            <Box
              sx={{
                width: { sm: "100%", lg: "30%" },
                // bgcolor: "red !important",
                //   m: "auto",
                "& .MuiTypography-root": {
                  textAlign: "center",
                  color: theme.palette.blue[500],
                },
                "& .MuiTypography-h5": { fontWeight: "700", p: "1rem 0" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  "& img": {
                    m: "auto",
                    width: { xs: "90%", sm: "17%", lg: "22%" },
                  },
                }}
              >
                <img src={img1} alt="img1" />
              </Box>
              <Typography variant="h5" color="initial">
                First Working Processs
              </Typography>
              <Typography variant="h6" color="initial">
                We listen, collaborate and co-create solutions that meets our
                customer’s expectations.
              </Typography>
            </Box>
            <Box
              sx={{
                width: { sm: "100%", lg: "30%" },
                // bgcolor: "red !important",
                //   m: "auto",
                "& .MuiTypography-root": {
                  textAlign: "center",
                  color: theme.palette.blue[500],
                },
                "& .MuiTypography-h5": { fontWeight: "700", p: "1rem 0" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  "& img": {
                    m: "auto",
                    width: { xs: "90%", sm: "17%", lg: "22%" },
                  },
                }}
              >
                <img src={img2} alt="img1" />
              </Box>
              <Typography variant="h5" color="initial">
                Dedicated Team
              </Typography>
              <Typography variant="h6" color="initial">
                We have an effective team and leaders who’s relationships are
                solidly built on trust and loyalty.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: { sm: "100%", lg: "30%" },
                // bgcolor: "red !important",
                m: "auto",
                "& .MuiTypography-root": {
                  textAlign: "center",
                  color: theme.palette.blue[500],
                },
                "& .MuiTypography-h5": { fontWeight: "700", p: "1rem 0" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  "& img": {
                    m: "auto",
                    width: { xs: "90%", sm: "17%", lg: "22%" },
                  },
                }}
              >
                <img src={img3} alt="img1" />
              </Box>
              <Typography variant="h5" color="initial">
                Dedicated Team
              </Typography>
              <Typography variant="h6" color="initial">
                We have an effective team and leaders who’s relationships are
                solidly built on trust and loyalty.
              </Typography>
            </Box>
          </Box>

          <Box>
            <Box
              sx={{
                display: { sm: "block", lg: "flex" },
                justifyContent: "space-between",
                "& >.MuiBox-root": {
                  mt: "5rem",
                },
              }}
            >
              <Box
                sx={{
                  m: { sm: "auto", lg: "auto 1rem auto auto" },
                  p: { xs: "1.5rem 1rem", lg: "2.5rem 3.5rem" },
                  width: { xs: "100%", lg: "45%" },
                  borderRadius: ".5rem",
                  bgcolor: theme.palette.blue[900],
                  boxShadow: ".2rem .2rem 1rem rgba(0, 0, 0,.13)",
                }}
              >
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  Join <b> over 2,000 businesses </b> that leverage on uridium
                  works products to delight their customers with awesome, fast,
                  personal support.
                </Typography>
                <Box sx={{ display: "flex", mt: "1.5rem" }}>
                  <Link
                    to="/contact-us"
                    component={RouterLink}
                    sx={{ m: "auto", width: "100%" }}
                  >
                    <CommonButton
                      variant="contained"
                      sx={{
                        width: "100%",
                        borderRadius: "2rem",
                        p: ".7rem 0",
                        bgcolor: theme.palette.blue[500],
                        color: theme.palette.secondary[900],
                        "&:hover": {
                          bgcolor: theme.palette.blue[500],
                          color: theme.palette.secondary[900],
                        },
                      }}
                      children="Contact Us"
                    />
                  </Link>
                </Box>
              </Box>

              <Box
                onClick={() => navigate("/careers")}
                sx={{
                  cursor: "pointer",
                  m: "auto",
                  width: { xs: "100%", lg: "45%" },
                  borderRadius: ".5rem",
                  boxShadow: ".2rem .2rem 1rem rgba(0, 0, 0,.13)",
                  p: {
                    xs: "1.5rem 1rem 0 1rem",
                    lg: "2.5rem 3.5rem 0 3.5rem",
                  },
                  //   bgcolor: theme.palette.blue[900],
                  "& .MuiTypography-h5": {
                    fontWeight: "700",
                    mt: "1rem",
                    color: theme.palette.secondary[100],
                  },
                  "& .MuiTypography-h6": {
                    color: theme.palette.secondary[200],
                    //
                  },
                }}
              >
                {/* <Link
                  sx={{
                    //
                    m: "0",
                    // p: "0",
                    bgcolor: "red !important",
                  }}
                  component={RouterLink}
                  underline="none"
                  to="/careers"
                > */}
                <Typography variant="h5" sx={{ textAlign: "center" }}>
                  Careers
                </Typography>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  We're Hiring!
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    "& img": {
                      m: "1rem auto 0 auto",
                      width: "63%",
                    },
                  }}
                >
                  <img src={img4} alt="img4" />
                </Box>
                {/* </Link> */}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default WhyChooseUs;
