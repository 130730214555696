import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQs = () => {
  const theme = useTheme();

  const [expanded, setExpanded] = React.useState(false);

  const accordionData = [
    {
      panel: "panel1",
      question: "What is the purpose of this project?",
      answer:
        "Our training typically includes course materials, access to alearning management system, live or pre- recorded lectures, hands-on exercises, and support from our expert instructors.",
    },
    {
      panel: "panel2",
      question: "How long is the training?",
      answer:
        "The length of our trainings can vary depending on the course, but typically range from a few days to several weeks",
    },
    {
      panel: "panel3",
      question: "Who can attend the training?",
      answer:
        "Our trainings are designed for professionals at all levels, from beginners to advanced practitioners, and are open to anyone who is interested in learning the skills and tools required for the specific topic.",
    },
    {
      panel: "panel4",
      question: "What kind of support is available during the training?",
      answer:
        "Our expert instructors are available to answer your questions and provide support throughout the training. We also provide access to a learning management system where you can connect with other participants and access additional resources.",
    },
    {
      panel: "panel5",
      question: "What is the format of the training?",
      answer:
        "Our trainings are typically delivered online, with both live and pre-recorded lectures available. We also provide hands-on exercises to ensure that you have practical experience with the topics covered.",
    },
    {
      panel: "panel6",
      question: "Will I receive a certificate upon completion?",
      answer:
        "Yes, we provide a certificate of completion for our trainings. This can be a valuable asset to include on your resume or CV.",
    },
    {
      panel: "panel7",
      question: "What are the payment options?",
      answer:
        "We offer a variety of payment options, including credit card, PayPal, and bank transfer. Please contact us for more information about payment options for specific trainings.",
    },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Box
        sx={{
          bgcolor: theme.palette.blue[500],
          color: theme.palette.secondary.main,
          p: { xs: "1rem", lg: "3rem" },
          //   mt: "3rem",
          "& .MuiAccordion-root": {
            bgcolor: theme.palette.neutral[1000],
            color: theme.palette.neutral[50],
            mb: "1rem",
            "& .MuiTypography-root": {
              fontWeight: "600",
            },
          },
          "& .MuiAccordionSummary-root svg": {
            color: theme.palette.neutral[1000],
            borderRadius: "50%",
            bgcolor: theme.palette.blue[500],
            p: ".1rem",
          },
          "& .MuiAccordionDetails-root .MuiTypography-root": {
            display: "inline",
            "&::after": {
              content: '" "',
            },
            "&:nth-child(1)": {
              fontWeight: 800,
            },
          },
        }}
      >
        <Typography
          variant="h2"
          sx={{ p: "1rem 0 3rem 0", textAlign: "center", fontWeight: "600" }}
        >
          Frequently Asked Questions (FAQ)
        </Typography>
        {accordionData?.map((item, index) => (
          <Accordion
            key={index++}
            expanded={expanded === item?.panel}
            onChange={handleChange(item?.panel)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>{item?.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{item?.question}</Typography>
              <Typography>{item?.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </>
  );
};

export default FAQs;
