import { Box } from "@mui/material";
import React from "react";

const PartnersImg = ({ imageUrl }) => {
  return (
    <>
      <Box display="flex">
        <img src={imageUrl} style={{margin:"auto"}} width="110" alt="partners logo" />
      </Box>
    </>
  );
};

export default PartnersImg;
