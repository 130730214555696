import { Box, Container, Typography, useTheme } from "@mui/material";
import { ProductUpdateSlider } from "components";
import React from "react";

const OurPortfolio = () => {
  const theme = useTheme();
  return (
    <Box component="section">
      <Container maxWidth="lg" sx={{ m: "6rem auto 0 auto" }}>
        <Typography
          sx={{
            fontWeight: "700",
            m: "1rem 0",
            color: theme.palette.blue[500],
          }}
          variant="h1"
          gutterBottom
        >
          Product update
        </Typography>
        <Typography
          sx={{
            fontWeight: "500",
            m: "2rem 0",
            color: theme.palette.secondary[100],
          }}
          variant="h4"
          gutterBottom
        >
          Take a look at what we’ve created
        </Typography>
      </Container>
      <ProductUpdateSlider />
    </Box>
  );
};

export default OurPortfolio;
