import React from "react";
import { Box } from "@mui/material";
// import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
// import { useGetUserQuery } from "../state/api";
import { Footer, Navbar } from "../components";

const Layout = () => {
  return (
    <Box>
      <Box flexGrow={1}>
        <Navbar />
        <Outlet />
        <Footer />
      </Box>
    </Box>
  );
};

export default Layout;
