import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import {
  // BlueSection,
  CommonButton,
  CommonCard,
  PartnersCard,
} from "components";
import React from "react";
import img1 from "../../assets/images/img1.png";
// import img2 from "../../assets/images/img2.png";

import partnerImg1 from "../../assets/images/partnerImg/partnerImg1.png";
import partnerImg2 from "../../assets/images/partnerImg/partnerImg2.png";
import partnerImg3 from "../../assets/images/partnerImg/partnerImg3.png";
import partnerImg4 from "../../assets/images/partnerImg/partnerImg4.png";
import partnerImg5 from "../../assets/images/partnerImg/partnerImg5.png";
import partnerImg6 from "../../assets/images/partnerImg/partnerImg6.png";

// import whyImg1 from "../../assets/images/why-img1.png";
// import whyImg2 from "../../assets/images/why-img2.png";
// import whyImg3 from "../../assets/images/why-img3.png";

import { ReactComponent as Corevalue1 } from "../../assets/svgs/core-value1.svg";
import { ReactComponent as Corevalue2 } from "../../assets/svgs/core-value2.svg";
import { ReactComponent as Corevalue3 } from "../../assets/svgs/core-value3.svg";
import { useNavigate } from "react-router-dom";
import img3 from "../../assets/images/img3.png";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, EffectFade, FreeMode, Autoplay } from "swiper";
// import { useCustomStyles } from "components/styles/useStyles";

const AboutUs = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  //const customClasses = customStyles();

  // const customStyles = useCustomStyles();

  const partnersData = [
    {
      imgUrl: partnerImg1,
      title: "Commercial Banks",
      borderColor: "#05255F",
    },
    {
      imgUrl: partnerImg2,
      title: "Microfinance Banks",
      borderColor: "#FF382E",
    },
    {
      imgUrl: partnerImg3,
      title: "Fintechs",
      borderColor: "#832AC8",
    },
    {
      imgUrl: partnerImg4,
      title: "Telcos",
      borderColor: "#4D0AA4",
    },
    {
      imgUrl: partnerImg5,
      title: "Restaurants",
      borderColor: "#9D6510",
    },
    {
      imgUrl: partnerImg6,
      title: "Insurance firms",
      borderColor: "#0DAB8F",
    },
  ];

  const ourCoreValuesData = [
    {
      svgUrl: <Corevalue1 />,
      title: "Partnership",
      description:
        "Great things in business are never achieved by walking alone but by having the right people who have the same mentality of excellence. At UWL, we work with you to achieve your business goals and objectives from start to finish - we are not contractors but partners who are willing to go with you on every step of the way taking your business as ours and helping you succeed.",
    },
    {
      svgUrl: <Corevalue2 />,
      title: "Value",
      description:
        "With a comprehensive strategy made of definitive value elements, we approach each project with a goal to create, add, and reinforce value in your business. Our solutions are value-driven and value-added on all fronts. Our problem-solving skills and experiences are what puts us ahead and make our clients come back again and again. Our secret is - we focus on your needs.",
    },
    {
      svgUrl: <Corevalue3 />,
      title: "Teamwork",
      description:
        "Great teamwork requires more than just great tools. Our proven methods, guides, and expertise are hinged on collaborative efforts which help our people at UWL work better, while being happy to always move things forward. Here, there is no boss, only leaders who are create a cohesive environment that makes everyone feel involved, smart and respected",
    },
  ];
  return (
    <>
      <Box sx={{ pb: "10rem" }}>
        {/* <Typography
          sx={{
            textAlign: "center",
            color: theme.palette.blue[500],
            fontWeight: "800",
            p: "5rem 0 7rem 0",
          }}
          variant="h1"
        >
          About Us
        </Typography> */}
        <Box sx={{ bgcolor: theme.palette.grey[10], pt: "3rem" }}>
          <Container maxWidth="lg">
            <Grid
              container
              spacing={{ xs: 2, sm: 2, md: 2, lg: 10 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              wrap={"wrap-reverse"}
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box sx={{ display: "flex", height: "100%" }}>
                  <Box sx={{ m: "auto" }}>
                    <Typography
                      sx={{
                        color: theme.palette.blue[500],
                        fontWeight: "bold",
                        pt: "1.5rem",
                        width: { xs: "100%", sm: "90%", lg: "100%" },
                      }}
                      variant="h1"
                    >
                      About Us
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.blue[500],
                        // fontWeight: "bold",
                        pt: "2rem",
                        // width: "35rem",
                      }}
                      variant="h5"
                    >
                      Uridium Works Limited, a sister company to Uridium
                      Technologies, is a Software company that architects and
                      develops Fintech solutions, Corporate Training, Software
                      Development & consultancy services, code reviews that add
                      value to our client's enterprise business.
                      <br />
                      <br />
                      The company commenced operation fully in January 2023 with
                      a dedicated team of experienced tech experts in banking,
                      payment solutions, smart invoice, API driven Payroll,
                      etc., and other technology ecosystem.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box
                  sx={{
                    display: "flex",
                    "& img": {
                      margin: "auto",
                      width: "90%",
                    },
                  }}
                >
                  <img src={img1} alt="img1" />
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="lg">
            <Box
              sx={{
                display: "flex",
                // p: "4rem 0",
                "& .MuiTypography-h1": {
                  fontWeight: "600",
                  textAlign: "center",
                  color: theme.palette.blue[500],
                  mb: "1.5rem",
                },
                "& .MuiTypography-h5": {
                  fontWeight: "500",
                  textAlign: "center",
                  color: theme.palette.blue[500],
                  width: "60%",
                  m: "auto",
                },
                "& .MuiBox-root": {
                  m: "4rem auto auto auto",
                },
              }}
            >
              <Box>
                <Box>
                  <Typography variant="h1">Mission</Typography>
                  <Typography variant="h5">
                    To be the global leader in the Fintech space by building on
                    our relationships, technologies, competencies that adds
                    value to our stakeholders.
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h1">Vision</Typography>
                  <Typography variant="h5">
                    To be the Financial Technology company of choice.
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h1">Strategy</Typography>
                  <Typography variant="h5">
                    We listen, collaborate and co-create solutions that meet our
                    customers’ expectations.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Container>
          {/* <Box sx={{ m: "6rem 0" }}>
            <BlueSection />
          </Box> */}

          <Container maxWidth="lg">
            <Box sx={{ pb: "4rem" }}>
              <Typography
                variant="h1"
                sx={{
                  color: theme.palette.blue[500],
                  fontWeight: "bold",
                  m: "7rem auto 2rem auto",
                  textAlign: "center",
                }}
              >
                Our Values
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.blue[500],
                  m: "auto auto 5rem auto",
                  textAlign: "center",
                  width: { xs: "100%", lg: "60%" },
                }}
              >
                Great teamwork requires more than just technology. Check out our
                proven work, guides and exercises that helps streamline your
                business success to achieve unmatched efficieny
              </Typography>
              <Box>
                <Grid
                  container
                  spacing={{ xs: 3, sm: 3, md: 6 }}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{ display: "flex" }}
                >
                  {ourCoreValuesData?.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <CommonCard
                        key={index++}
                        imgUrl={item.imgUrl}
                        title={item.title}
                        description={item.description}
                        svg={item.svgUrl}
                        paddingTop="2rem"
                        ourCoreValuesCard={true}
                      ></CommonCard>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Container>
          <Box
            component="div"
            // className="about__img1"
            sx={{
              position: "relative",
              m: "auto",
              p: {
                xs: "6rem 1rem",
                md: "10rem 4rem 10rem 4rem",
                lg: "16rem 6rem 16rem 6rem",
              },
              backgroundImage: `url(${img3})`,
              backgroundRepeat: "no-repeat",
              // backgroundPositionX: { xs: "-25rem", sm: "-20rem", md: "0" },
              backgroundSize: "cover",
              overflow: "hidden",
              "&::before": {
                content: "''",
                position: "absolute",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                width: "inherit",
                height: "inherit",
                backgroundColor: "rgba(0, 0, 0,.75)",
                // backgroundImage:
                //   "linear-gradient( to right bottom, rgba(0, 0, 0, .95), rgba(0, 0, 0, 0.25))",
                // zIndex: -2,
              },
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "100%",
                  zIndex: 20,
                  color: theme.palette.secondary[500],
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    textAlign: "center",
                    fontWeight: "400",
                    m: "auto",
                    width: { xs: "100%", sm: "80%", lg: "50%" },
                  }}
                >
                  We will help you to achieve your goals and to grow your
                  business
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <CommonButton
                    variant="outlined"
                    sx={{
                      m: "4rem auto auto auto",
                      borderRadius: "3rem",
                      // bgcolor: theme.palette.blue[500],
                      color: theme.palette.secondary[500],
                      fontWeight: "500",
                      border: `1px solid ${theme.palette.secondary[500]}`,
                      textTransform: "none",
                      "&:hover": {
                        border: `1px solid ${theme.palette.secondary[500]}`,
                        // bgcolor: theme.palette.red[500]
                      },
                    }}
                    children="Why Choose Us?"
                    size="large"
                    onClick={() => {
                      navigate("/why-choose-us");
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ p: { xs: "1rem", sm: "2rem", lg: "10rem 0 0 0" } }}>
            <Typography
              variant="h2"
              sx={{
                color: theme.palette.blue[500],
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              Current Identified Partners/industries
            </Typography>
            {/* <Box sx={{ mt: "5rem",
                bgcolor:"red !important",
                width:"100%",
              }}> */}
            <Box
              sx={{
                display: "flex",
                mt: "5rem",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  // width: { xs: "100%", sm: 470, md: "50rem", lg: "100%" },
                  overflow: "hidden",
                  width: "86vw",
                  // width: { xs: "100%", sm: 670, md: 1000, lg: 1500 },
                  m: "auto",
                }}
              >
                <Swiper
                  // width="100px !important"
                  style={{ width: "inherit" }}
                  loop={true}
                  loopedSlides={8}
                  modules={[Navigation, EffectFade, FreeMode, Autoplay]}
                  autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                  }}
                  // spaceBetween={50}
                  // slidesPerView={3}
                  breakpoints={{
                    0: { slidesPerView: 1, spaceBetween: 10 },
                    480: { slidesPerView: 2, spaceBetween: 10 },
                    768: { slidesPerView: 3, spaceBetween: 15 },
                    980: { slidesPerView: 5, spaceBetween: 15 },
                  }}
                  grabCursor={true}
                  freeMode={{ enabled: true, sticky: true }}
                  pagination={{
                    clickable: true,
                    // el: `swiper-container swiper-container-testClass`,
                    // bulletClass: `swiper-pagination-bullet swiper-pagination-testClass`,
                  }}
                  // wrapperTag="ul"
                  // navigation={true}
                  // navigation={{
                  //   prevEl: ".swiperNavPrevRef",
                  //   nextEl: ".swiperNavNextRef",
                  // }}
                  // effect={"fade"}
                  // speed={800}
                  // onInit={(swiper) => {
                  //   // swiper.params.navigation.prevEl = swiperNavPrevRef.current;
                  //   // swiper.params.navigation.nextEl = swiperNavNextRef.current;
                  //   swiper.navigation.init();
                  //   swiper.navigation.update();
                  // }}
                  // onSlideChange={() => console.log("slide change")}
                  // onSwiper={(swiper) => console.log(swiper)}
                >
                  {partnersData?.map((item, index) => (
                    <SwiperSlide key={index++}>
                      <PartnersCard
                        imageUrl={item?.imgUrl}
                        title={item?.title}
                        borderColor={item?.borderColor}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                {/* </Box> */}
              </Box>
            </Box>
          </Box>
          {/* <Box sx={{ p: { xs: "1rem", sm: "2rem", lg: "6rem" } }}>
            <Typography
              variant="h2"
              sx={{ color: theme.palette.blue[500], fontWeight: "700" }}
            >
              Meet With Our Team Members
            </Typography>
            <Box sx={{ mt: "5rem" }}>
              <Grid
                container
                spacing={{ xs: 3, sm: 3, md: 6, lg: 6 }}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                {ourTeamData?.map((item, index) => (
                  <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
                    <CommonTeamCard
                      key={index++}
                      imgUrl={item?.imgUrl}
                      title={item?.title}
                      subTitle={item?.subTitle}
                      align="center"
                      svg={item?.svgUrl}
                      marginRight="5.5rem"
                      paddingTop="2rem"
                      titleMarginTop="3.5rem"
                      width="20rem"
                      link1={item?.link1}
                    ></CommonTeamCard>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default AboutUs;
