import { Button } from "@mui/material";
import React from "react";

const CommonButton = ({
  children,
  color,
  disabled,
  size,
  sx,
  variant,
  buttonIcon,
  onSubmit,
  onClick,
  loading,
  className
}) => {
  return (
    <Button
      disableElevation
      color={color}
      disabled={disabled}
      size={size}
      variant={variant}
      // style={{boxShadow: "none",}}
      sx={sx}
      endIcon={buttonIcon}
      onSubmit={onSubmit}
      onClick={onClick}
      className={className}
    >
      {children}
      {loading}
    </Button>
  );
};

export default CommonButton;
