import React from "react";
import { Divider, Link, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import CommonButton from "../CommonButton";

const TrainingCard = ({ imgUrl, title, description }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const goToTraining = () => {
    navigate("/training");
  };
  return (
    <>
      <Box
        sx={{
          p: "1.5rem",
          // border: `1px solid ${theme.palette.grey[500]}`,
          boxShadow: ".2rem 0rem 1.5rem rgba(0, 0, 0, 0.15)",
          borderRadius: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            "& img": {
              m: "0 auto 2rem auto",
              width: "100%",
            },
          }}
        >
          <img src={imgUrl} alt="training-img" />
        </Box>
        <Typography
          sx={{
            color: theme.palette.grey[900],
            pb: "2rem",
            textAlign: "left",
            fontWeight: "700",
          }}
          variant="h3"
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.secondary[100],
            pb: "1rem",
            textAlign: "left",
            height: { xs: "12rem", sm:"23rem", md:"14rem", lg: "10rem" },
            fontWeight: "500",
          }}
          variant="body1"
        >
          {description}
        </Typography>
        <Link underline="none" component={RouterLink} to="/contact-us">
          <Typography variant="h4" color={theme.palette.blue[500]}>
            Contact Sales
          </Typography>
        </Link>
        <Divider
          sx={{ border: `1px solid ${theme.palette.grey[300]}`, m: "1.5rem 0" }}
        />
        <Box>
          <CommonButton
            variant="contained"
            size="large"
            sx={{
              p: ".7rem 1.5rem",
              color: theme.palette.secondary[1000],
              bgcolor: theme.palette.blue[500],
              borderRadius: "30rem",
              "&:hover": {
                color: theme.palette.secondary[1000],
                bgcolor: theme.palette.blue[500],
              },
            }}
            children="Explore Now"
            onClick={goToTraining}
          />
        </Box>
        {/* <Box sx={{ display: "flex", mt: "2rem" }}>
          <Button
            sx={{
              textTransform: "capitalize",
              m: "auto",
              fontSize: ".7rem",
              // p: ".5rem 0 0 1rem",
              color: theme.palette.red[500],
              fontWeight: "bold",
            }}
            size="small"
            onClick={goToTraining}
          >
            Learn More &gt;&gt;
          </Button>
        </Box> */}
      </Box>
    </>
  );
};

export default TrainingCard;
