import {
  Box,
  Divider,
  Grid,
  Typography,
  useTheme,
  // CardActions,
  // Button,
  // Link,
} from "@mui/material";
import React from "react";
// import { Link as RouterLink } from "react-router-dom";

import trainingImg from "../../../assets/images/training/trainingImg9.png";

const TrainingContent3 = () => {
  const theme = useTheme();

  return (
    <>
      <Box className="max__height">
        <Box sx={{ m: "0 0 4rem 0" }}>
          <Grid
            container
            spacing={{ xs: 0, sm: 0, md: 0, lg: 0 }}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            // wrap={"wrap-reverse"}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  bgcolor: theme.palette.blue[500],
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.secondary[1000],
                    fontWeight: "bold",
                    m: "auto",
                    p: "2rem",
                  }}
                  variant="h2"
                >
                  Automation and Business Applications Training Using Power
                  Platform
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  "& img": {
                    margin: "auto",
                    width: "100%",
                  },
                }}
              >
                <img src={trainingImg} alt="img1" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: "4rem" }}>
          {/* <Typography
            sx={{
              color: theme.palette.blue[600],
              fontWeight: "bold",
              textAlign: "center",
              mb: "2rem",
            }}
            variant="h2"
          >
            PRODUCT DESIGN
          </Typography> */}
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              textAlign: "left",
              mb: "2rem",
            }}
          >
            Learn business process automation and application development using
            the Microsoft Power Platform Tools; Power Apps, Power Automate and
            SharePoint.
          </Typography>
          <Typography
            sx={{
              color: theme.palette.blue[600],
              fontWeight: "bold",
              //textAlign: "center",
              mt: "4.5rem",
            }}
            variant="h2"
          >
            POWER APPS TRAINING CURRICULUM
          </Typography>
        </Box>
        <Box sx={{ mt: "1.5rem" }}>
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            BEGINNER LEVEL
          </Typography>
        </Box>
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Introduction to Power Apps
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Introduction to Power Apps
              <br />
              Creating a new app
              <br />
              Building a basic app
              <br />
              Introduction to data sources
              <br />
              Connecting to data sources
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 1
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Building and Customizing Apps
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Designing the app layout
              <br />
              Using themes and colours
              <br />
              Creating forms and screens
              <br />
              Working with galleries
              <br />
              Debugging your app
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 2
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ mt: "3.5rem" }}>
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            INTERMEDIATE LEVEL
          </Typography>
        </Box>
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Advanced Features of Power Apps
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Building a responsive app
              <br />
              Creating custom controls
              <br />
              Using formulas and expressions
              <br />
              Introduction to model-driven apps
              <br />
              Building model-driven apps
              <br />
              Integrating Power Apps with Power BI
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 3
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Building Apps for Specific Scenarios
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
              variant="h5"
            >
              Building apps for mobile devices
              <br />
              Creating offline apps
              <br />
              Using location and maps in Power Apps
              <br />
              Integrating with SharePoint and OneDrive
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 4 & 5
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ mt: "3.5rem" }}>
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            ADVANCED LEVEL
          </Typography>
        </Box>
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Advanced Topics
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
              variant="h5"
            >
              Working with business process flows
              <br />
              Creating custom connectors
              <br />
              Building custom connectors using Power Automate
              <br />
              Introduction to Al Builder
              <br />
              Security and permissions in Power Apps
              <br />
              Managing app versions and publishing apps
              <br />
              Best practices for app development
              <br />
              Troubleshooting common issues
              <br />
              Q&A session
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 6 & 7
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Typography
          sx={{
            m: "4.5rem 0",
            color: theme.palette.blue[600],
            fontWeight: "bold",
            //textAlign: "center",
            // mb: "2rem",
          }}
          variant="h2"
        >
          POWER AUTOMATE TRAINING CURRICULUM
        </Typography>
        <Box sx={{ mt: "1.5rem" }}>
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            BEGINNER LEVEL
          </Typography>
        </Box>
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Introduction to Power Automate
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Introduction to Power Automate
              <br />
              Understanding the Power Automate environment
              <br />
              Building your first flow
              <br />
              Triggers and actions in Power Automate
              <br />
              Flow templates and connectors
              <br />
              Working with data in Power Automate
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 8 & 9
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Building and Customizing Flows
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Understanding conditions and loops
              <br />
              Building conditional flows
              <br />
              Creating parallel branches
              <br />
              Using expressions in Power Automate Working with arrays and
              collections Debugging your flow
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 10
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ mt: "3.5rem" }}>
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            INTERMEDIATE LEVEL
          </Typography>
        </Box>
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Advanced Features of Power Automate
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Building custom connectors
              <br />
              Building custom connectors using Azure Functions
              <br />
              Introduction to Al Builder
              <br />
              Building flows with Al Builder
              <br />
              Using gateways to access on-premises data.
              <br />
              Building flows with SharePoint Online
              <br />
              Building flows with Dynamics 365
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 8 & 9
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Building Flows for Specific Scenarios
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Building approval flows
              <br />
              Building notification flows
              <br />
              Building data collection flows
              <br />
              Building scheduled flows
              <br />
              Building automated reporting flows
              <br />
              Best practices for flow development
              <br />
              Troubleshooting common issues
              <br />
              Q&A session
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 10
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ mt: "3.5rem" }}>
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            ADVANCED LEVEL
          </Typography>
        </Box>
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Advanced Topics
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Advanced expressions and functions
              <br />
              Building nested flows
              <br />
              Building flows with Power Apps
              <br />
              Building flows with Power BI
              <br />
              Security and permissions in Power Automate
              <br />
              Managing flow versions and publishing flows
              <br />
              Best practices for flow deployment
              <br />
              Troubleshooting advanced issues
              <br />
              Q&A session
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 8 & 9
            </Typography>
          </Box>
        </Box>
        <Divider />

        <Typography
          sx={{
            m: "4.5rem 0",
            color: theme.palette.blue[600],
            fontWeight: "bold",
            //textAlign: "center",
            // mb: "2rem",
          }}
          variant="h2"
        >
          SHAREPOINT TRAINING CURRICULUM
        </Typography>
        <Box sx={{ mt: "1.5rem" }}>
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            BEGINNER LEVEL
          </Typography>
        </Box>
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Introduction to SharePoint Online
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Introduction to SharePoint Online
              <br />
              Introduction to SharePoint Online
              <br />
              Understanding the SharePoint environment
              <br />
              Working with SharePoint lists and libraries
              <br />
              Creating and customizing SharePoint pages Managing SharePoint
              permissions
              <br />
              Working with SharePoint web parts
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 8 & 9
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Building and Customizing SharePoint Sites
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Creating custom site columns and content types
              <br />
              Building SharePoint workflows
              <br />
              Introduction to Power Automate for SharePoint
              <br />
              Creating custom SharePoint apps
              <br />
              Working with SharePoint metadata
              <br />
              Understanding SharePoint site templates
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 10
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ mt: "3.5rem" }}>
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            INTERMEDIATE LEVEL
          </Typography>
        </Box>
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Advanced SharePoint Features
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Building SharePoint forms with Power Apps
              <br />
              Building SharePoint dashboards with Power BI
              <br />
              Building SharePoint search solutions
              <br />
              Using SharePoint taxonomy and managed metadata
              <br />
              Creating custom SharePoint solutions with SharePoint Framework
              (SPFx)
              <br />
              Integrating SharePoint with Teams
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 8 & 9
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Building SharePoint Solutions for Specific Scenarios
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              Building SharePoint communication sites
              <br />
              Building SharePoint team sites
              <br />
              Building SharePoint document management solutions
              <br />
              Building SharePoint knowledge management solutions
              <br />
              Building SharePoint project management solutions
              <br />
              Best practices for SharePoint development Troubleshooting common
              issues
              <br />
              Q&A session
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 10
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ mt: "3.5rem" }}>
          <Typography
            sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
            variant="h3"
          >
            ADVANCED LEVEL
          </Typography>
        </Box>
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Advanced Topics
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              SharePoint site architecture and design
              <br />
              Building SharePoint solutions with custom code
              <br />
              Building SharePoint solutions with Microsoft Power Platform
              <br />
              Managing SharePoint solutions and customizations
              <br />
              Best practices for SharePoint deployment Troubleshooting advanced
              issues
              <br />
              Q&A session
            </Typography>
          </Box>
          <Box sx={{ width: "15%" }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
              }}
              variant="h3"
            >
              Week 8 & 9
            </Typography>
          </Box>
        </Box>
        <Divider />
      </Box>
    </>
  );
};

export default TrainingContent3;
