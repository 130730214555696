import React from "react";
import {
  Hero,
  OurPortfolio,
  Partners,
  WhatWeDo,
  // OurCoreValues,
  // Testimonials,
} from "components";
import { Box } from "@mui/material";

const Home = () => {
  return (
    <>
      <Box>
        <Hero />
        <WhatWeDo />
        <OurPortfolio />
        {/* <OurCoreValues /> */}
        {/* <Testimonials /> */}
        <Partners />
      </Box>
    </>
  );
};

export default Home;
