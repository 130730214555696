import {
  Box,
  Divider,
  Grid,
  Typography,
  useTheme,
  // CardActions,
  // Button,
  // Link,
} from "@mui/material";
import React from "react";
// import { Link as RouterLink } from "react-router-dom";

import trainingImg from "../../../assets/images/training/trainingImg5.png";
import noBlameImg from "../../../assets/images/training/no-blame-img.png";

const TrainingContent2 = () => {
  const theme = useTheme();

  return (
    <>
      <Box className="max__height">
        <Box sx={{ m: "0 0 4rem 0" }}>
          <Grid
            container
            spacing={{ xs: 0, sm: 0, md: 0, lg: 0 }}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            // wrap={"wrap-reverse"}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  bgcolor: theme.palette.blue[500],
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.secondary[1000],
                    fontWeight: "bold",
                    m: "auto",
                    p: "2rem",
                  }}
                  variant="h2"
                >
                  No Blame Culture Training for Engineers “It Is Your
                  Fault...Not Mine”...
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  margin: "auto 0",
                  "& img": {
                    width: "100%",
                  },
                }}
              >
                <img src={trainingImg} alt="img1" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: "4rem" }}>
          <Typography
            sx={{
              color: theme.palette.blue[600],
              fontWeight: "bold",
              textAlign: "center",
              mb: "2rem",
            }}
            variant="h2"
          >
            NO BLAME CULTURE
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              textAlign: "left",
              // mb: "2rem"
            }}
          >
            This training seeks to open the eyes of engineers (Software
            Developers, Application management and support, QA & DevOps, etc.)
            to see the impact of the monetary value they make their organization
            to lose once the blame culture is embraced and helps them come out
            of that mindset and focus more on the common interest and the goal
            of the organization thereby fostering collaboration, support and
            interest amongst them.
          </Typography>
        </Box>
        <Typography
          sx={{
            color: theme.palette.grey[900],
            fontWeight: "bold",
            textAlign: "center",
            mb: 2,
          }}
          variant="h2"
        >
          Training Outcome
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            color: theme.palette.secondary[100],
          }}
          variant="h4"
        >
          Experience the best value added propositions aimed at business growth
        </Typography>
        <Box
          sx={{
            display: "flex",
            m: "4rem 1rem",
            "& img": {
              width: "100%",
            },
          }}
        >
          <img src={noBlameImg} alt="no-blame-img" />
        </Box>
        <Typography
          sx={{
            textAlign: "center",
            color: theme.palette.grey[900],
            fontWeight: "bold",
          }}
          variant="h3"
        >
          What to Learn
        </Typography>
        <Typography
          sx={{
            color: theme.palette.secondary[200],
            fontWeight: "500",
            p: "2rem 0 1rem 0",
          }}
        >
          What is a team?
        </Typography>
        <Divider sx={{ border: `1px solid ${theme.palette.secondary[200]}` }} />
        <Typography
          sx={{
            color: theme.palette.secondary[200],
            fontWeight: "500",
            p: "1rem 0",
          }}
        >
          How do you think your organization make money?
        </Typography>
        <Divider />
        <Typography
          sx={{
            color: theme.palette.secondary[200],
            fontWeight: "500",
            p: "1rem 0",
          }}
        >
          Does customers care about your technical jargons?
        </Typography>
        <Divider />
        <Typography
          sx={{
            color: theme.palette.secondary[200],
            fontWeight: "500",
            p: "1rem 0",
          }}
        >
          Real life experience in a financial sector (the impact of blame
          culture to organizations bottom line)
        </Typography>
        <Divider />
        <Typography
          sx={{
            color: theme.palette.secondary[200],
            fontWeight: "500",
            p: "1rem 0",
          }}
        >
          What does blame culture mean?
        </Typography>
        <Divider />
        <Typography
          sx={{
            color: theme.palette.secondary[200],
            fontWeight: "500",
            p: "1rem 0",
          }}
        >
          Listening to understand and not to react
        </Typography>
        <Divider />
        <Typography
          sx={{
            color: theme.palette.secondary[200],
            fontWeight: "500",
            p: "1rem 0",
          }}
        >
          How to avoid the blame game and work together as a team
        </Typography>
        <Divider />
        <Typography
          sx={{
            color: theme.palette.secondary[200],
            fontWeight: "500",
            p: "1rem 0",
          }}
        >
          Become a responsible engineer
        </Typography>
        <Divider />
        <Typography
          sx={{
            color: theme.palette.secondary[200],
            fontWeight: "500",
            p: "1rem 0",
          }}
        >
          Steps you must take to become a responsible engineer.
        </Typography>
        <Divider />
      </Box>
    </>
  );
};

export default TrainingContent2;
