import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  Typography,
  Link,
  useTheme,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
// import CommonButton from "../CommonButton";
import UWlogo from "../../../assets/images/UW-logo.png";
import { useLocation } from "react-router-dom";
// import { useDispatch } from "react-redux";

const drawerWidth = "77vw";
// const drawerWidth = 240;
const navItems = [
  "Home",
  "About Us",
  "Services",
  "Product",
  // "UW Partners",
  "Careers",
  "Team",
  "Contact Us",
];

const Navbar = () => {
  // const dispatch = useDispatch();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [subMenu1, setSubMenu1] = useState(false);
  const [subMenu2, setSubMenu2] = useState(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
    setSubMenu1(false);
    setSubMenu2(false);
  };
  const subMenu1Toggle = () => {
    setSubMenu1((prevState) => !prevState);
  };
  const subMenu2Toggle = () => {
    setSubMenu2((prevState) => !prevState);
  };

  const goTo = (item) => {
    let urlPath = "/";

    switch (item) {
      case "Home":
        urlPath = "./home";
        break;
      case "About Us":
        urlPath = "./about-us";
        break;
      case "Services":
        urlPath = "#";
        break;
      case "Team":
        urlPath = "#";
        break;
      case "Product":
        urlPath = "./product";
        break;
      case "UW Partners":
        urlPath = "./uw-partners";
        break;
      case "Careers":
        urlPath = "./careers";
        break;
      case "Contact Us":
        urlPath = "./contact-us";
        break;
      default:
        break;
    }

    // if (item === "Home") {
    //   urlPath = "./home";
    // } else if (item === "About Us") {
    //   urlPath = "./about-us";
    // } else if (item === "Services") {
    //   urlPath = "#";
    // } else if (item === "Product") {
    //   urlPath = "./product";
    // } else if (item === "UW Partners") {
    //   urlPath = "./uw-partners";
    // } else if (item === "Careers") {
    //   urlPath = "./careers";
    // } else if (item === "Team") {
    //   urlPath = "#";
    // }

    return urlPath;
  };

  const goToMobile = (item) => {
    switch (item) {
      case "Home":
        handleDrawerToggle();
        navigate("./home");
        break;
      case "About Us":
        handleDrawerToggle();
        navigate("./about-us");
        break;
      case "Services":
        subMenu1Toggle();
        setSubMenu2(false);
        break;
      case "Team":
        subMenu2Toggle();
        setSubMenu1(false);
        break;
      case "Product":
        handleDrawerToggle();
        navigate("./product");
        break;
      case "UW Partners":
        handleDrawerToggle();
        navigate("./uw-partners");
        break;
      case "Careers":
        handleDrawerToggle();
        navigate("./careers");
        break;
      case "Contact Us":
        handleDrawerToggle();
        navigate("./contact-us");
        break;

      default:
        break;
    }
  };

  const drawer = (
    <Box
      //  onClick={handleDrawerToggle}
      //
      sx={{ textAlign: "left" }}
    >
      <Box sx={{ display: "flex" }}>
        <Link
          sx={{ m: ".5rem 0 auto .5rem" }}
          component={RouterLink}
          underline="none"
          to="/home"
        >
          <img src={UWlogo} alt="logo" width="70" />
        </Link>
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item}>
            <ListItemButton
              disableGutters
              sx={{
                textAlign: "left",
              }}
            >
              <Box
                onClick={() => goToMobile(item)}
                // onClick={() => subMenu1Toggle()}
                // to={goTo(item)}
                // component={RouterLink}
                // underline="none"
                sx={{
                  color:
                    item.toLowerCase().replace(/\s+/g, "-") ===
                      pathname.substring(1) ||
                    (item.toLowerCase().replace(/\s+/g, "-") === "team" &&
                      (pathname.substring(1) === "board-of-directors" ||
                        pathname.substring(1) === "our-team")) ||
                    (item.toLowerCase().replace(/\s+/g, "-") === "services" &&
                      (pathname.substring(1) === "our-services" ||
                        pathname.substring(1) === "training")) ||
                    (item.toLowerCase().replace(/\s+/g, "-") === "product" &&
                      (pathname.substring(1) === "ussd" ||
                        pathname.substring(1) === "u-invoice" ||
                        pathname.substring(1) === "u-marketplace")) ||
                    (item.toLowerCase().replace(/\s+/g, "-") === "about-us" &&
                      pathname.substring(1) === "why-choose-us")
                      ? theme.palette.red[500]
                      : theme.palette.blue[500],
                  ml: "1rem",
                  display: "flex",
                  "&:hover": {
                    color: theme.palette.red[500],
                  },
                  p: ".8rem 0",
                  position: "relative",
                  // "&:hover .dropdown1": {
                  //   display: "block",
                  // },
                  // "&:hover .dropdown2": {
                  //   display: "block",
                  // },
                }}
                // onClick={
                //   item.toLowerCase().replace(/\s+/g, "-") === "team" &&
                //   (pathname.substring(1) === "board-of-directors" ||
                //     pathname.substring(1) === "our-team")
                //     ? () => subMenu1Toggle()
                //     : ""
                // }
              >
                <Typography
                  variant="h5"
                  // component="div"
                  sx={{
                    fontWeight: "700",
                  }}
                >
                  {item}
                  {item === "Services" && (
                    <Box
                      className="dropdown1"
                      onClick={handleDrawerToggle}
                      sx={{
                        position: "absolute",
                        display: subMenu1 ? "block" : "none",
                        bgcolor: theme.palette.secondary[1000],
                        boxShadow: "0 .1rem .5rem rgba(0, 0, 0, .12)",
                        zIndex: "999",
                        top: "3rem",
                        // right: "0",
                        // left: "0",
                        left: "-1rem",
                        borderRadius: ".2rem",
                        "& .MuiTypography-h5": {
                          borderBottom: "1px solid rgba(0, 0, 0, .1)",
                          fontWeight: "bold",
                          p: ".6rem 1rem",
                          textAlign: "left",
                          whiteSpace: "nowrap",
                          // width: "9rem",
                          color: theme.palette.blue[500],
                          "&:hover": {
                            color: theme.palette.red[500],
                          },
                        },
                      }}
                    >
                      <Link
                        to="./our-services"
                        component={RouterLink}
                        underline="none"
                      >
                        <Typography variant="h5">Our Services</Typography>
                      </Link>
                      <Link
                        to="./training"
                        component={RouterLink}
                        underline="none"
                      >
                        <Typography variant="h5">Training</Typography>
                      </Link>
                    </Box>
                  )}
                  {item === "Team" && (
                    <Box
                      className="dropdown2"
                      onClick={handleDrawerToggle}
                      sx={{
                        position: "absolute",
                        display: subMenu2 ? "block" : "none",
                        // display: subMenu1 ? "block" : "none",
                        bgcolor: theme.palette.secondary[1000],
                        boxShadow: "0 .1rem .5rem rgba(0, 0, 0, .12)",
                        zIndex: "999",
                        top: "3rem",
                        // right: "0",
                        left: "-1rem",
                        borderRadius: ".2rem",
                        "& .MuiTypography-h5": {
                          borderBottom: "1px solid rgba(0, 0, 0, .1)",
                          fontWeight: "bold",
                          p: ".6rem 1rem",
                          textAlign: "left",
                          // width: "9rem",
                          whiteSpace: "nowrap",
                          color: theme.palette.blue[500],
                          "&:hover": {
                            color: theme.palette.red[500],
                          },
                        },
                      }}
                    >
                      <Link
                        to="./board-of-directors"
                        // to="./careers"
                        component={RouterLink}
                        underline="none"
                      >
                        <Typography variant="h5" noWrap>
                          Board of Directors
                        </Typography>
                      </Link>
                      <Link
                        to="./our-team"
                        component={RouterLink}
                        underline="none"
                      >
                        <Typography variant="h5">Our Team</Typography>
                      </Link>
                    </Box>
                  )}
                </Typography>
              </Box>
              {/* <ListItemText primary={item} /> */}
            </ListItemButton>
          </ListItem>
        ))}

        {/* <Box sx={{ m: "auto auto auto 2rem" }} onClick={handleDrawerToggle}>
          <a
            href="https://calendly.com/uridiumworks/30min"
            target="_blank"
            rel="noreferrer"
          >
            <CommonButton
              size="small"
              variant="contained"
              sx={{
                bgcolor: theme.palette.primary[500],
                color: theme.palette.secondary[900],
                p: ".5rem 2rem",
                fontSize: ".7rem",
                mt: "1rem",
                // ml: "2rem",
                "&:hover": {
                  bgcolor: theme.palette.primary[500],
                  color: theme.palette.secondary[900],
                },
              }}
              children="Request a Demo"
            />
          </a>
        </Box> */}

        {/* <Link
          onClick={handleDrawerToggle}
          component={RouterLink}
          to="./contact-us"
          underline="none"
          sx={{
            ml: "2rem",
          }}
        >
          <CommonButton
            variant="contained"
            sx={{
              borderRadius: "4rem",
              boxShadow: "none",
              p: ".3rem 1.3rem",
              bgcolor:
                "contact-us" === pathname.substring(1)
                  ? theme.palette.red[500]
                  : theme.palette.blue[500],
              color: theme.palette.secondary[1000],
              "&:hover": {
                boxShadow: "none",
                bgcolor: theme.palette.red[500],
              },
              fontWeight: "bold",
              textTransform: "none",
            }}
            children="Contact Us"
          ></CommonButton>
        </Link> */}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        sx={{
          boxShadow: "none",
          backgroundColor: theme.palette.secondary[1000],
          borderBottom: `2px solid ${theme.palette.secondary[700]}`,
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: { xs: "flex", lg: "none" },
              justifyContent: "space-between",
              // display: { lg: "none" },
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Link
                sx={{ m: ".5rem 0 auto .5rem" }}
                component={RouterLink}
                underline="none"
                to="/home"
              >
                <img src={UWlogo} alt="logo" width="70" />
              </Link>
            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { lg: "none" } }}
            >
              <MenuIcon sx={{ color: "black" }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", lg: "block" },
              bgcolor: "red",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Link
                sx={{ m: ".5rem 0 auto .5rem" }}
                component={RouterLink}
                underline="none"
                to="/home"
              >
                <img src={UWlogo} alt="logo" width="90" />
              </Link>
            </Box>
          </Box>
          <Box sx={{ display: { xs: "none", lg: "flex" } }}>
            {navItems.map((item) => {
              return (
                <Link
                  key={item}
                  component={RouterLink}
                  to={goTo(item)}
                  underline="none"
                  sx={{
                    color:
                      item.toLowerCase().replace(/\s+/g, "-") ===
                        pathname.substring(1) ||
                      (item.toLowerCase().replace(/\s+/g, "-") === "team" &&
                        (pathname.substring(1) === "board-of-directors" ||
                          pathname.substring(1) === "our-team")) ||
                      (item.toLowerCase().replace(/\s+/g, "-") === "services" &&
                        (pathname.substring(1) === "our-services" ||
                          pathname.substring(1) === "training")) ||
                      (item.toLowerCase().replace(/\s+/g, "-") === "product" &&
                        (pathname.substring(1) === "ussd" ||
                          pathname.substring(1) === "u-invoice" ||
                          pathname.substring(1) === "u-marketplace")) ||
                      (item.toLowerCase().replace(/\s+/g, "-") === "about-us" &&
                        pathname.substring(1) === "why-choose-us")
                        ? theme.palette.red[500]
                        : theme.palette.blue[500],
                    margin: "0 1.2rem",
                    display: "flex",
                    p: "1rem 0",
                    "&:hover": {
                      color: theme.palette.red[500],
                    },
                    position: "relative",
                    "&:hover .dropdown1": {
                      display: "block",
                    },
                    "&:hover .dropdown2": {
                      display: "block",
                    },
                  }}
                >
                  <Typography
                    variant="h5"
                    // component="div"
                    sx={{
                      fontWeight: "700",
                      m: "auto",
                    }}
                  >
                    {item}
                    {item === "Services" && (
                      <Box
                        className="dropdown1"
                        sx={{
                          position: "absolute",
                          display: "none",
                          bgcolor: theme.palette.secondary[1000],
                          boxShadow: "0 .1rem .5rem rgba(0, 0, 0, .12)",
                          zIndex: "999",
                          top: "3rem",
                          // right: "0",
                          left: "0",
                          borderRadius: ".2rem",
                          "& .MuiTypography-h5": {
                            borderBottom: "1px solid rgba(0, 0, 0, .1)",
                            fontWeight: "bold",
                            p: ".6rem 1rem",
                            textAlign: "left",
                            width: "9rem",
                            color: theme.palette.blue[500],
                            "&:hover": {
                              color: theme.palette.red[500],
                            },
                          },
                        }}
                      >
                        <Link
                          to="./our-services"
                          component={RouterLink}
                          underline="none"
                        >
                          <Typography variant="h5">Our Services</Typography>
                        </Link>
                        <Link
                          to="./training"
                          component={RouterLink}
                          underline="none"
                        >
                          <Typography variant="h5">Training</Typography>
                        </Link>
                      </Box>
                    )}
                    {item === "Team" && (
                      <Box
                        className="dropdown2"
                        sx={{
                          position: "absolute",
                          display: "none",
                          bgcolor: theme.palette.secondary[1000],
                          boxShadow: "0 .1rem .5rem rgba(0, 0, 0, .12)",
                          zIndex: "999",
                          top: "3rem",
                          // right: "0",
                          left: "0",
                          borderRadius: ".2rem",
                          "& .MuiTypography-h5": {
                            borderBottom: "1px solid rgba(0, 0, 0, .1)",
                            fontWeight: "bold",
                            p: ".6rem 1rem",
                            textAlign: "left",
                            // width: "9rem",
                            color: theme.palette.blue[500],
                            "&:hover": {
                              color: theme.palette.red[500],
                            },
                          },
                        }}
                      >
                        <Link
                          to="./board-of-directors"
                          component={RouterLink}
                          underline="none"
                        >
                          <Typography variant="h5" noWrap>
                            Board of Directors
                          </Typography>
                        </Link>
                        <Link
                          to="./our-team"
                          component={RouterLink}
                          underline="none"
                        >
                          <Typography variant="h5">Our Team</Typography>
                        </Link>
                      </Box>
                    )}
                  </Typography>
                </Link>
              );
            })}
            {/* <Box display="flex">
              <Box sx={{ m: "auto auto auto auto" }}>
                <a
                  href="https://calendly.com/uridiumworks/30min"
                  target="_blank"
                  rel="noreferrer"
                >
                  <CommonButton
                    size="small"
                    variant="contained"
                    sx={{
                      bgcolor: theme.palette.primary[500],
                      color: theme.palette.secondary[900],
                      p: ".5rem 2rem",
                      fontSize: ".7rem",
                      // mr: "3rem",
                      "&:hover": {
                        bgcolor: theme.palette.primary[500],
                        color: theme.palette.secondary[900],
                      },
                    }}
                    children="Request a Demo"
                  />
                </a>
              </Box>
            </Box> */}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: theme.palette.primary.light,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Toolbar />
    </Box>
  );
};

export default Navbar;
