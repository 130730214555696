import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import bod from "../../assets/images/bod-img.png";
import bod1 from "../../assets/images/bod/bod-img1.png";
import bod2 from "../../assets/images/bod/bod-img2.png";
import bod3 from "../../assets/images/bod/bod-img3.png";

const BoardOfDirectors = () => {
  const theme = useTheme();
  return (
    <>
      <Box>
        <Box
          sx={{
            "& img": {
              width: "100%",
            },
          }}
        >
          <img src={bod} alt="board-of-directors-img" />
        </Box>
        <Container maxWidth="lg">
          <Typography
            variant="h2"
            sx={{
              textAlign: "center",
              fontWeight: "800",
              color: theme.palette.blue[500],
              m: "1rem 0",
            }}
            color="initial"
          >
            Board Of Directors
          </Typography>
          <Box sx={{ m: "4rem 0 2rem 0" }}>
            <Grid
              container
              spacing={{ xs: 3, sm: 3, md: 2, lg: 10 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <Grid sx={{ margin: "auto" }} item xs={12} sm={12} md={6} lg={4}>
                <Box
                  sx={{
                    display: "flex",
                    margin: "auto",
                    "& img": {
                      width: "100%",
                    },
                  }}
                >
                  <img src={bod1} style={{ margin: "auto" }} alt="img2" />
                </Box>
              </Grid>
              <Grid
                sx={{
                  margin: "0 auto auto auto",
                  p: { xs: "2rem", sm: "0", lg: "5rem" },
                }}
                item
                xs={12}
                md={6}
                lg={8}
              >
                <Typography
                  sx={{
                    color: theme.palette.secondary[100],
                    fontWeight: "500",
                    pt: "2rem",
                    // width: "35rem",
                  }}
                  variant="h5"
                >
                  With over 20 years of expertise working internationally in the
                  IT industry, Johnson is a very talented sales expert.
                  <br />
                  <br /> He has a vast amount of expertise working in the
                  technology sector, having held positions with companies like
                  Computer Warehouse and IBM as well as the position of Country
                  Manager for Commvault Systems Inc. in West Africa.
                  <br />
                  <br /> He has worked on a number of IT initiatives, including
                  managed services, infrastructure, and applications.
                  <br />
                  <br /> Customer satisfaction is his main passion, and he will
                  do whatever it takes to give his customers the finest care.
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider
            sx={{
              backgroundImage: "linear-gradient(to right, #05255F, #FF382E)",
              width: "100%",
              height: ".4rem",
              border: "none",
              m: "6rem 0",
            }}
          />
          <Box sx={{ m: "2rem 0" }}>
            <Grid
              container
              spacing={{ xs: 3, sm: 3, md: 2, lg: 10 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              wrap={"wrap-reverse"}
            >
              <Grid
                sx={{
                  margin: "0 auto auto auto",
                  p: { xs: "2rem", sm: "0", lg: "5rem" },
                }}
                item
                xs={12}
                md={6}
                lg={8}
              >
                <Typography
                  sx={{
                    color: theme.palette.secondary[100],
                    fontWeight: "500",
                    pt: "2rem",
                    // width: "35rem",
                  }}
                  variant="h5"
                >
                  Olushola Abolaji is a Certified Management Consultant with
                  over 20 years of experience in the ICT Industry in a variety
                  of positions in Nigeria and Pan-African companies. He is also
                  an accomplished ICT Business Transformation and General
                  Engineering Management professional.
                  <br />
                  <br /> He is also a Certified Management Expert and Certified
                  Contact Center Quality Analyst from the London Graduate
                  School.
                  <br />
                  <br /> He directs the Managed Services and General Engineering
                  Business Projects as well as Operations & Strategic Planning
                  at Uridium. <br />
                  <br />
                  Olushola’s great passion for Modesty, Heartfelt and
                  High-Performance Team (HPT) Philosophy has been his guiding
                  principle in his personal and professional engagements.
                </Typography>
              </Grid>
              <Grid sx={{ margin: "auto" }} item xs={12} sm={12} md={6} lg={4}>
                <Box
                  sx={{
                    display: "flex",
                    margin: "auto",
                    "& img": {
                      width: "100%",
                    },
                  }}
                >
                  <img src={bod2} style={{ margin: "auto" }} alt="img2" />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider
            sx={{
              backgroundImage: "linear-gradient(to right, #05255F, #FF382E)",
              width: "100%",
              height: ".4rem",
              border: "none",
              m: "6rem 0",
            }}
          />
          <Box sx={{ m: "2rem 0 6rem 0" }}>
            <Grid
              container
              spacing={{ xs: 3, sm: 3, md: 2, lg: 10 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <Grid sx={{ margin: "auto" }} item xs={12} sm={12} md={6} lg={4}>
                <Box
                  sx={{
                    display: "flex",
                    margin: "auto",
                    "& img": {
                      width: "100%",
                    },
                  }}
                >
                  <img src={bod3} style={{ margin: "auto" }} alt="img2" />
                </Box>
              </Grid>
              <Grid
                sx={{
                  margin: "0 auto auto auto",
                  p: { xs: "2rem", sm: "0", lg: "5rem" },
                }}
                item
                xs={12}
                md={6}
                lg={8}
              >
                <Typography
                  sx={{
                    color: theme.palette.secondary[100],
                    fontWeight: "500",
                    pt: "2rem",
                    // width: "35rem",
                  }}
                  variant="h5"
                >
                  Chigozie is an ex-Senior Manager/Head enterprise solutions
                  engineering manager spanning over 14 years in the banking
                  industry (Sterling Bank Plc), with solid experience in system
                  design, component architecture and payment systems. <br />
                  <br /> He has strong managerial skills that has also enabled
                  him to create other leaders in the banking industry and
                  analytical skills, issue debugging skills. He is skilled at
                  architecting payment solutions, lending solutions, and
                  corporate solutions with in-depth knowledge of cutting-edge
                  technology stacks that bring value to organizations.
                  <br />
                  <br /> He brings to Uridium his wealth of experience to take
                  on new responsibility and challenge as the CEO of Uridium
                  Works Limited and is ready to ensure that UWL is well
                  positioned in the Nigerian ecosystem and outside Nigeria.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default BoardOfDirectors;
