import { Box, FormControl, MenuItem, Select, useTheme } from "@mui/material";
import React from "react";

const DropDownField = ({ positions, positionsList, handleChange }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        "& .MuiFormControl-root": {
          color: `${theme.palette.secondary[100]}`,
          bgcolor: `${theme.palette.secondary[900]}`,
          mt: ".6rem",
          "&:hover": {
            border: "none",
          },
        },
        "& .MuiSelect-standard": {
          p: "1.3rem 1rem",
          color: `${theme.palette.secondary[100]}`,
          bgcolor: `${theme.palette.secondary[900]}`,
          fontWeight: "500",
        },
        "& .MuiSelect-select:focus": {
          bgcolor: `${theme.palette.secondary[900]}`,
        },
        "& .MuiInput-root:before": {
          border: "none",
        },
        "& .MuiInput-root:after": {
          border: "none",
        },
        "& svg": {
          color: theme.palette.grey[900],
        },
      }}
    >
      <FormControl
        variant="standard"
        sx={{
          width: "100%",
          bgcolor: theme.palette.secondary[900],
          "&:hover": {
            borderBottom: "none",
          },
        }}
      >
        <Select
          defaultValue=""
          disableUnderline
          // value={positions}
          name="positions"
          onChange={handleChange}
          label="Positions"
          placeholder="Select a position"
          displayEmpty
          // renderValue={
          //   positions !== ""
          //     ? undefined
          //     : () => <placeholder>Answer</placeholder>
          // }
          renderValue={positions !== "" ? undefined : () => "Select a position"}
        >
          {positionsList.map((option) => (
            <MenuItem
              sx={{ p: "1rem 1rem" }}
              // nChange={handleChange}
              value={option.value}
              key={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropDownField;
