import {
  // useCallback,
  useEffect,
  useState,
  // useState,
} from "react";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import heroSliderImg1 from "../../assets/images/hero/hero-slider-img1.png";
import heroNewyearImg from "../../assets/images/hero/hero-newyear-img.png";
import UMarketPlaceLogo from "../../assets/images/hero/u-marketplace-logo.png";
import SmartInvoiceLogoBlue from "../../assets/images/hero/smartinvoice-logo-blue.png";
import SmartInvoiceHeroImg from "../../assets/images/hero/smartinvoice-hero-img.png";
import NibssLogo from "../../assets/images/hero/nibss-logo.png";
import {
  AccessBank,
  EcoBank,
  FCMBBank,
  FidelityBank,
  GlobusBank,
  PolarisBank,
  SterlingBank,
  TajBank,
  UBABank,
  ZenthBank,
} from "assets/images/banks";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade, FreeMode, Autoplay } from "swiper";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/free-mode";
import "swiper/css";
// import { Link as RouterLink } from "react-router-dom";

const Hero = () => {
  const theme = useTheme();
  const [swiper, setSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(1);
  // const [state, setState] = useState({
  //   count: 0,
  //   show: true,
  // });

  const control = useAnimation();
  const [inView] = useInView();
  // const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  // const boxVariant = {
  //   visible: {
  //     opacity: 1,
  //     y: 0,
  //     transition: { duration: 1, ease: "easeInOut" },
  //   },
  //   hidden: { opacity: 0, y: 20 },
  // };

  // const reload = useCallback(() => {
  //   // setState({ show: false });
  //   // setTimeout(
  //   //   () => setState((state) => ({ count: state.count + 1, show: true })),
  //   //   500
  //   // );
  // }, [setState]);

  // useEffect(() => {
  //   reload();
  // }, [reload]);

  const slideTo = (index) => {
    swiper.slideTo(index + 3);
    swiper.update();
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Swiper
          style={{
            "--swiper-pagination-color": "#000",
            "--swiper-pagination-bullet-inactive-color": "#000",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-size": "36px",
            "--swiper-pagination-bullet-horizontal-gap": "20px",
          }}
          effect={"fade"}
          loop={true}
          loopedSlides={4}
          modules={[Navigation, EffectFade, FreeMode, Autoplay]}
          // modules={[Navigation, EffectFade, FreeMode, Autoplay, Pagination]}
          autoplay={{
            delay: 15000,
            disableOnInteraction: false,
          }}
          // spaceBetween={50}
          // slidesPerView={3}
          breakpoints={{
            0: { slidesPerView: 1, spaceBetween: 10 },
            480: { slidesPerView: 1, spaceBetween: 10 },
            768: { slidesPerView: 1, spaceBetween: 15 },
            980: { slidesPerView: 1, spaceBetween: 15 },
          }}
          grabCursor={true}
          freeMode={{ enabled: true, sticky: true }}
          pagination={{
            clickable: true,
            // el: `swiper-container swiper-container-testClass`,
            // bulletClass: `swiper-pagination-bullet swiper-pagination-testClass`,
          }}
          onSwiper={(swiper) => {
            setSwiper(swiper);
            // setActiveIndex((prev) => prev + 1);
            // console.log(swiper);
          }}
          onActiveIndexChange={(swiper) => {
            setActiveIndex(swiper?.activeIndex - 2);
            // setActiveIndex(!activeIndex);
            // setActiveIndex(1);
          }}
        >
          {/* <SwiperSlide>
            <Box
              sx={{
                height: { xs: "1000px", lg: "700px" },
                width: "100%",
                // backgroundImage: `url(${heroNewyearImg})`,
                backgroundRepeat: "no-repeat",
                // backgroundSize: "cover",
                // backgroundSize: "contain",
                // overflow: "auto",
                backgroundColor: "rgba(255, 255, 255)",
                overflow: "hidden",
                "& img": {
                  width: "100%",
                  // height: "100%",
                  backgroundPositionY: {
                    xs: "-5rem",
                    sm: "-5rem",
                    md: "-5rem",
                  },
                  marginTop: {
                    xs: "0",
                    sm: "-9rem",
                    md: "-9rem",
                  },
                  // marginTop: "-9rem",
                },
                "&::before": {
                  content: "''",
                  position: "absolute",
                  left: "0",
                  right: "0",
                  top: "0",
                  bottom: "0",
                  width: "inherit",
                  height: "inherit",
                  // backgroundColor: "rgba(18, 64, 113, 0.83)",
                  // backgroundImage:
                  //   "linear-gradient( to right bottom, rgba(0, 0, 0, .95), rgba(0, 0, 0, 0.25))",
                  // zIndex: -2,
                },
              }}
            >
              <img src={heroNewyearImg} alt="UMarketPlaceLogo" />
            </Box>
          </SwiperSlide> */}

          <SwiperSlide>
            <Box
              sx={{
                height: { xs: "1000px", lg: "700px" },
                width: "100%",
                backgroundColor: "#E1E6EF",
                overflow: "auto",
              }}
            >
              <Box
                sx={{
                  m: "auto",
                  paddingX: { xs: "1rem", lg: "7rem" },
                  paddingY: "3rem",
                  // backgroundColor: theme.palette.primary[900],
                }}
              >
                <Grid
                  container
                  spacing={{ xs: 3, sm: 3, md: 3 }}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <Box
                      sx={{
                        paddingBottom: "1.2rem",
                        "& img": {
                          width: { xs: "2.4rem", lg: "4rem" },
                          mr: "auto",
                        },
                      }}
                    >
                      <img src={UMarketPlaceLogo} alt="UMarketPlaceLogo" />
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "600", fontSize: ".6rem" }}
                        color={theme.palette.primary[600]}
                      >
                        UMARKET PLACE
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        marginBottom: "1.3rem",
                        fontWeight: "400",
                        fontSize: "1.2rem",
                      }}
                      variant="h2"
                      color="initial"
                    >
                      Drive with Confidence, Pay Your Car Insurance Easily with
                      Umarketplace
                    </Typography>
                    <Typography
                      sx={{
                        marginBottom: "1.3rem",
                      }}
                      variant="body1"
                      color="initial"
                    >
                      Uridium in partnership with <b>NIBSS</b> have launched
                      <b>UMarketplace</b> to enable all banks customers renew
                      their car insurance (Third-party or comprehensive) through
                      their banking app (Mobile, Internet Banking) via Ebills
                    </Typography>

                    <Box>
                      <Typography
                        variant="h5"
                        marginBottom={1.4}
                        fontWeight={600}
                        color="initial"
                      >
                        Steps to renew your car insurance are:
                      </Typography>
                      <Box
                        sx={{ display: { xs: "block", lg: "flex" }, gap: 3 }}
                      >
                        <Box sx={{ flex: 1 }}>
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            color="initial"
                          >
                            Internet Banking/Mobile App
                          </Typography>
                          <Box
                            sx={{
                              "& .MuiTypography-root": {
                                borderLeft: `2px solid ${theme.palette.primary[600]}`,
                                paddingLeft: ".7rem",
                                my: "1rem",
                                fontWeight: "500",
                              },
                            }}
                          >
                            <Typography variant="body2">
                              open the app
                            </Typography>
                            <Typography variant="body2">
                              Navigate to Bills Payment &gt; Ebills
                            </Typography>
                            <Typography variant="body2">
                              Locate UMarketplace
                            </Typography>
                            <Typography variant="body2">
                              Input your vehicle info
                            </Typography>
                            <Typography variant="body2">
                              Click 'Validate' to get the premium quote
                            </Typography>
                            <Typography variant="body2">
                              Input your vehicle info
                            </Typography>
                            <Typography variant="body2">
                              Receive your Insurance Certificate via email
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            color="initial"
                          >
                            In-Branch
                          </Typography>
                          <Box
                            sx={{
                              "& .MuiTypography-root": {
                                borderLeft: `2px solid ${theme.palette.primary[600]}`,
                                paddingLeft: ".7rem",
                                my: "1rem",
                                fontWeight: "500",
                              },
                            }}
                          >
                            <Typography variant="body2">
                              Visit your bank
                            </Typography>
                            <Typography variant="body2">
                              Request Ebills
                            </Typography>
                            <Typography variant="body2">
                              Umarketplace Biller and Car Insurance renewal
                            </Typography>
                            <Typography variant="body2">
                              Provide your vehicle registration number and
                              contact information
                            </Typography>
                            <Typography variant="body2">
                              Review the premium quote
                            </Typography>
                            <Typography variant="body2">
                              Approve and make the payment
                            </Typography>
                            <Typography variant="body2">
                              Receive your Insurance Certificate via email
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <Box sx={{}}>
                        <Box
                          sx={{
                            m: "auto",
                            width: { xs: "95%", lg: "70%" },
                            display: "flex",
                            paddingLeft: "1rem",
                            borderLeft: `5px solid ${theme.palette.primary[500]}`,
                            "& img": {
                              m: "auto",
                              width: { xs: "100%", lg: "100%" },
                              paddingBottom: "1rem",
                              borderBottom: `5px solid ${theme.palette.primary[500]}`,
                            },
                          }}
                        >
                          <img src={heroSliderImg1} alt="heroSliderImg1" />
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ my: "2rem" }}>
                      <Box
                        sx={{
                          // display: "flex",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            textAlign: "center",
                            fontWeight: "500",
                            width: { xs: "95%", lg: "100%" },
                            m: "auto",
                            "& img": {
                              width: "3rem",
                              marginX: ".3rem",
                            },
                          }}
                          color="initial"
                        >
                          With the successful integration with
                          <span>
                            <img src={NibssLogo} alt="NibssLogo" />
                          </span>
                          Ebills, you can now renew your car insurance (third
                          party or comprehensive ) through ebills in the
                          following banks:
                        </Typography>
                      </Box>
                      <Grid
                        container
                        spacing={{ xs: 2, sm: 2, md: 2 }}
                        columns={{ xs: 10, sm: 10, md: 10 }}
                        sx={{
                          paddingY: "1rem",
                          "& .MuiGrid-item": {
                            display: "flex",
                            m: "auto",
                            "& img": {
                              width: { xs: "1.2rem", lg: "3rem" },
                              m: "auto",
                            },
                          },
                        }}
                      >
                        <Grid item xs={2} sm={2} md={2}>
                          <img src={ZenthBank} alt="ZenthBank" />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2}>
                          <img src={SterlingBank} alt="SterlingBank" />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2}>
                          <img src={GlobusBank} alt="GlobusBank" />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2}>
                          <img src={EcoBank} alt="EcoBank" />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2}>
                          <img src={UBABank} alt="UBABank" />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2}>
                          <img src={FidelityBank} alt="FidelityBank" />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2}>
                          <img src={PolarisBank} alt="PolarisBank" />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2}>
                          <img src={TajBank} alt="TajBank" />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2}>
                          <img src={AccessBank} alt="AccessBank" />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2}>
                          <img src={FCMBBank} alt="FCMBBank" />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </SwiperSlide>

          <SwiperSlide>
            <Box
              sx={{
                height: { xs: "1000px", lg: "700px" },
                // backgroundImage: `url(${heroSliderImg2})`,
                // backgroundRepeat: "no-repeat",
                // backgroundPositionX: { xs: "-25rem", sm: "-20rem", md: "0" },
                // backgroundSize: "cover",
                // overflow: "auto",
                overflow: "hidden",
                backgroundColor: "rgba(255, 255, 255)",
                "&::before": {
                  content: "''",
                  position: "absolute",
                  left: "0",
                  right: "0",
                  top: "0",
                  bottom: "0",
                  width: "inherit",
                  height: "inherit",
                  // backgroundColor: "rgba(18, 64, 113, 0.83)",
                  // backgroundImage:
                  //   "linear-gradient( to right bottom, rgba(0, 0, 0, .95), rgba(0, 0, 0, 0.25))",
                  // zIndex: -2,
                },
              }}
            >
              <Box
                sx={{
                  paddingX: { xs: "1rem", lg: "7rem" },
                  paddingY: "3rem",
                  zIndex: 10,
                  top: 0,
                  bottom: 0,
                  display: "flex",
                  position: "absolute",
                  "& .MuiTypography-root": {
                    // color: theme.palette.secondary[900],
                  },
                }}
              >
                <Grid
                  container
                  spacing={{ xs: 2, sm: 2, md: 2 }}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                  sx={{
                    paddingY: "1rem",
                    "& .MuiGrid-item": {
                      display: "flex",
                      m: "auto",
                      // "& img": {
                      //   width: { xs: "1.2rem", lg: "3rem" },
                      //   m: "auto",
                      // },
                    },
                    overflow: "hidden",
                  }}
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ m: "auto" }}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          marginBottom: "4rem",
                          "& img": {
                            width: { xs: "8.8rem", lg: "15rem" },
                            mr: "auto",
                          },
                        }}
                      >
                        <img
                          src={SmartInvoiceLogoBlue}
                          alt="UMarketPlaceLogo"
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="h1"
                          sx={{
                            fontWeight: "700",
                            fontSize: "2rem",
                            mb: "1rem",
                            width: { xs: "100%", lg: "100%" },
                            color: theme.palette.primary[500],
                          }}
                          color="initial"
                        >
                          Smart Invoice
                        </Typography>
                        <Typography
                          variant="h4"
                          sx={{
                            fontWeight: "400",
                            width: { xs: "100%", lg: "70%" },
                            color: theme.palette.primary[500],
                          }}
                          color="initial"
                        >
                          Manage your business with our smart invoice product.
                          It works for Fintechs, SMEs, Real Estate and other
                          business in general.
                        </Typography>
                      </Box>
                      {/* <Link
                        
                        component={RouterLink}
                        underline="none"
                        to="http://usmartinvoice.com"
                        // to="/u-invoice"
                      > */}
                      <a
                        href="http://usmartinvoice.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          size="large"
                          sx={{
                            marginY: "2rem",
                            paddingX: "1rem",
                            backgroundColor: theme.palette.primary[500],
                            textTransform: "capitalize",
                            "&:hover": {
                              backgroundColor: theme.palette.primary[500],
                            },
                          }}
                        >
                          Learn More
                        </Button>
                      </a>
                      {/* </Link> */}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} sx={{ overflow: "hidden" }}>
                    <Box
                      sx={{
                        display: "flex",
                        "& img": {
                          width: { xs: "100%", lg: "100%" },
                          m: "auto",
                        },
                      }}
                    >
                      <img
                        src={SmartInvoiceHeroImg}
                        alt="SmartInvoiceHeroImg"
                      />
                    </Box>
                  </Grid>
                </Grid>
                {/* <Box
                  sx={{
                    display: "flex",
                    width: { xs: "100%", lg: "80%" },
                    backgroundColor: "rgba(18, 18, 18, 0.10)",
                    marginY: { xs: "2rem", lg: "8rem" },
                    paddingY: "2rem",
                    mr: "auto",
                    borderRadius: ".4rem",
                    flexWrap: "wrap",
                    gap: "2rem",
                    "& .MuiBox-root": {
                      flex: { xs: "", lg: 1 },
                      paddingX: "1rem",
                    },
                  }}
                >
                  <Box>
                    <Typography
                      variant="h4"
                      color="initial"
                      sx={{ marginBottom: "1.5rem", fontWeight: "600" }}
                    >
                      Contract of Sale
                    </Typography>
                    <Typography variant="body2" color="initial">
                      Generate dynamic contract of sale agreement for different
                      customers with different properties
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h4"
                      color="initial"
                      sx={{ marginBottom: "1.5rem", fontWeight: "600" }}
                    >
                      Installment Payment
                    </Typography>
                    <Typography variant="body2" color="initial">
                      Provide your clients and customers with the capability to
                      make payments in installments while enabling you to
                      closely track and oversee their payment progress.
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h4"
                      color="initial"
                      sx={{ marginBottom: "1.5rem", fontWeight: "600" }}
                    >
                      Email Notification
                    </Typography>
                    <Typography variant="body2" color="initial">
                      Get notified of all invoice generated and transactions via
                      your registered email in seconds
                    </Typography>
                  </Box>
                </Box> */}
              </Box>
            </Box>
          </SwiperSlide>
        </Swiper>
        <Box
          sx={{
            position: "absolute",
            bottom: "4rem",
            zIndex: 1,
            display: "flex",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2.5,
              marginX: "auto",
              "& .MuiBox-root": {
                // backgroundColor: theme.palette.grey[500],
                height: "6px",
                borderRadius: "5px",
                width: "5rem",
                cursor: "pointer",
                transition: "background ease-in-out 250ms",
              },
            }}
          >
            <Box
              sx={{
                backgroundColor:
                  activeIndex === 1 || activeIndex === 3
                    ? theme.palette.secondary[900]
                    : "#575757",
                width: "5rem",
                height: "5rem",
              }}
              onClick={() => {
                // swiper.slidePrev();
                slideTo(1);
                setActiveIndex(1);
              }}
              className="swiper-pagination-bullet"
            ></Box>
            <Box
              sx={{
                backgroundColor:
                  activeIndex === 2 || activeIndex === 0
                    ? theme.palette.secondary[900]
                    : "#575757",
              }}
              onClick={() => {
                slideTo(2);
                // swiper.slideNext();
                setActiveIndex(2);
              }}
            ></Box>
            {/* <Box
              sx={{
                backgroundColor:
                  activeIndex === 3 || activeIndex === 0
                    ? theme.palette.secondary[900]
                    : "#575757",
              }}
              onClick={() => {
                slideTo(3);
                // swiper.slideNext();
                setActiveIndex(3);
              }}
            ></Box> */}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Hero;
