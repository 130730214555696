import {
  Box,
  Container,
  // Divider,
  Grid,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import teamHeroImg from "../../assets/images/teamHero-Img.png";
// import quote from "../../assets/images/quote.png";
import ceoImg from "../../assets/images/ceo-img.png";
import team1 from "../../assets/images/team/team1.png";
import team2 from "../../assets/images/team/team2.png";
import team3 from "../../assets/images/team/team3.png";
import team4 from "../../assets/images/team/team4.png";
import { CommonButton } from "components";

const OurTeam = () => {
  const theme = useTheme();

  return (
    <>
      <Box>
        <Box
          sx={{
            overflow: "hidden",
            position: "relative",
            width: "100%",
            height: { xs: "110px", sm: "137px", md: "210px", lg: "350px" },
            "& img": { position: "absolute", width: "100%" },
          }}
        >
          <img src={teamHeroImg} alt="team-hero" />
        </Box>
        <Container maxWidth="lg" sx={{ p: "5rem 1rem" }}>
          <Typography
            sx={{
              color: theme.palette.blue[500],
              fontWeight: "500",
              textAlign: "center",
              p: { xs: "1rem 0", lg: "0 0 3rem 0" },
            }}
            variant="h1"
          >
            Teams at Uridium Works
          </Typography>
          <Typography
            sx={{
              width: { xs: "93%", lg: "27rem" },
              color: theme.palette.secondary[100],
              textAlign: "center",
              fontweight: "900",
              m: "auto",
            }}
            variant="h5"
          >
            We are all responsible for directing the company's future expansion.
            We think the key to our success is building diverse teams with
            complementary strengths. Look through our{" "}
            <Link
              to="/careers"
              underline="none"
              sx={{ color: theme.palette.blue[500], fontWeight: "700" }}
              component={RouterLink}
            >
              career page
            </Link>{" "}
            to find a position that's a good fit for you.
          </Typography>
          {/* <Box display="flex" mt="4rem">
            <Box m="auto">
              <Box display="flex">
                <Box sx={{ alignContent: "flex-start" }}>
                  <img src={quote} alt="quote" />
                </Box>
                <Typography
                  sx={{
                    width: { xs: "100%", lg: "20em" },
                    color: theme.palette.blue[500],
                    textAlign: "left",
                    m: "auto 1rem",
                  }}
                  variant="h4"
                >
                  One thing that has drawn me back to Uridium Works Limited is
                  the fact that there are so many different opportunities to
                  either work with other teams or to try something else or to
                  stretch ourselves in a new way.
                </Typography>
                <Box sx={{ alignSelf: "flex-end" }}>
                  <img src={quote} alt="quote" />
                </Box>
              </Box>
              <Divider
                sx={{
                  m: "2rem 0 0 auto",
                  width: "15rem",
                  border: `1.5px solid ${theme.palette.blue[500]} !important`,
                  bgcolor: "red !important",
                }}
              />
              <Typography
                sx={{
                  // width: { xs: "100%", lg: "20em" },
                  color: theme.palette.blue[500],
                  textAlign: "right",
                  m: "1rem 0 0 0",
                }}
                variant="h3"
              >
                - Chigozie
              </Typography>
              <Typography
                sx={{
                  // width: { xs: "100%", lg: "20em" },
                  color: theme.palette.blue[500],
                  textAlign: "right",
                  m: "0",
                }}
                variant="h6"
              >
                CEO, Uridium Works Limited
              </Typography>
            </Box>
          </Box> */}
        </Container>
        <Box
          sx={{
            // bgcolor: "#000",
            bgcolor: theme.palette.neutral[0],
          }}
        >
          <Container maxWidth="lg">
            <Box p="3rem 0">
              <Grid
                container
                spacing={{ xs: 2, sm: 2, md: 2, lg: 10 }}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Typography
                    sx={{
                      // width: { xs: "100%", lg: "20em" },
                      color: theme.palette.blue[400],
                      textAlign: "left",
                      fontWeight: "bold",
                      mb: "1rem",
                    }}
                    variant="h4"
                  >
                    Meet Our CEO
                  </Typography>
                  <Typography
                    sx={{
                      // width: { xs: "100%", lg: "20em" },
                      color: theme.palette.grey[100],
                      textAlign: "left",
                      mb: "1rem",
                    }}
                    variant="h3"
                  >
                    The Leadership
                  </Typography>
                  <Box
                    sx={{
                      overflow: "hidden",
                      width: "100%",
                      display: "flex",
                      // height: "35rem",
                      "& img": {
                        width: "65%",
                        m: "auto 0",
                      },
                    }}
                  >
                    <img src={ceoImg} alt="ceo" />
                  </Box>
                  <Typography
                    sx={{
                      // width: { xs: "100%", lg: "20em" },
                      color: theme.palette.grey[100],
                      textAlign: "left",
                      m: "3rem 0",
                    }}
                    variant="h3"
                  >
                    Chigozie Anyasor - CEO
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Typography
                    sx={{
                      // width: { xs: "100%", lg: "20em" },
                      color: theme.palette.neutral[400],
                      textAlign: "left",
                      mb: "1rem",
                      ml: "1rem",
                      fontStyle: "italic",
                    }}
                    variant="h5"
                  >
                    Our quest started with a straightforward concept: let's take
                    a short trip to make the world more functional. We work with
                    businesses that are eager to change.
                  </Typography>
                  <Typography
                    sx={{
                      // width: { xs: "100%", lg: "20em" },
                      color: theme.palette.grey[100],
                      textAlign: "left",
                      mb: "1rem",
                    }}
                    variant="h5"
                  >
                    I am an experienced enterprise solutions engineering manager
                    spanning over (2008 to 2022) 14 years and 8 month in the
                    banking industry with solid experience in system design,
                    component architecture and payment systems. I have also been
                    good at leading a team.
                    <br />
                    <br />
                    I have strong analytical skills, issue debugging skills. I
                    architect payment solutions, lending solutions, and
                    corporate solutions with in-depth knowledge of cutting-edge
                    technology stacks that bring value to organizations.
                    <br />
                    <br />
                    I currently manage the Enterprise Solutions Engineering
                    (Software Developers) team, Automation and productivity
                    team, and the enterprise data warehouse team responsible for
                    ensuring data is available to the various business units
                    allowed to access data in Sterling bank.
                    <br />
                    <br />
                    I am also a Data Scientist with domain knowledge expertise
                    in the financial sector, and I love to do a lot of research
                    depending on the domain to enable me add value to any
                    organization I find myself. My love for data and working as
                    a data scientist has grown recently. Hence, I want to focus
                    more on data and data strategy to help drive business and
                    organization through data-driven decision making, data
                    monetization, and building models that add direct value to
                    any organization I find myself.
                    <br />
                    <br />
                    I enrolled in Walden University for a master’s in data
                    science, where I was exposed to making data tell stories,
                    data analysis, building machine learning models, data
                    engineering, and data science
                    <br />
                    <br />
                    {/* Email: chigozie@uridiumworks.com <br />
                    Phone: 123455678 <br />
                    LinkedIn:{" "}
                    <a
                      href="https://www.linkedin.com/in/chigozie-anyasor-9519b938/"
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: theme.palette.blue[400],
                        textDecoration: "underline",
                      }}
                    >
                      Chigozie Anyasor
                    </a> */}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
        <Box bgcolor={theme.palette.blue[500]}>
          <Typography
            variant="h5"
            sx={{
              color: theme.palette.secondary.main,
              p: "1.5rem 1rem",
              textAlign: "center",
              fontSize: { xs: ".8rem", lg: "1.2rem" },
            }}
          >
            Software / Engineering Technology Sales Marketing Corporate
          </Typography>
        </Box>
        <Container maxWidth="lg">
          <Box sx={{ p: "4rem 0" }}>
            <Grid
              container
              spacing={{ xs: 2, sm: 2, md: 2, lg: 10 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                    "& img": { m: "auto", width: "100%" },
                  }}
                >
                  <img src={team1} alt="team1" />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box>
                  <Typography
                    variant="h3"
                    sx={{
                      color: theme.palette.blue[500],
                      p: "0 0 1rem 0",
                      fontWeight: "500",
                    }}
                  >
                    Software / Engineering
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ color: theme.palette.neutral[300] }}
                  >
                    We’re focused on helping our customers solve some of the
                    world’s hardest problems, and our software & services teams
                    create the products that help them succeed. <br />
                    These teams include:
                  </Typography>
                  <Box sx={{ ml: "2rem" }}>
                    <Typography
                      variant="h6"
                      sx={{ color: theme.palette.neutral[200] }}
                    >
                      <br />
                      Applications & Frameworks
                      <br />
                      Cloud & Infrastructure
                      <br />
                      Product Owners
                      <br />
                      Operations & Reliability
                      <br />
                      Information Systems & Technology
                      <br />
                      Security & Privacy
                      <br />
                      Software Quality, Automation, and Tools
                    </Typography>
                  </Box>
                  {/* <Typography
                    variant="h5"
                    sx={{ color: theme.palette.blue[500] }}
                  >
                    --- Current opportunities
                  </Typography> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ p: "4rem 0" }}>
            <Grid
              container
              spacing={{ xs: 2, sm: 2, md: 2, lg: 10 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              wrap={"wrap-reverse"}
            >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box>
                  <Typography
                    variant="h3"
                    sx={{
                      color: theme.palette.blue[500],
                      p: "0 0 1rem 0",
                      fontWeight: "500",
                    }}
                  >
                    Sales
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ color: theme.palette.neutral[300] }}
                  >
                    Our sales & business development teams are the teams that
                    introduce Seequent to the world, and work to ensure our
                    customers are getting an amazing experience.
                    <br />
                    These teams include:
                  </Typography>
                  <Box sx={{ ml: "2rem" }}>
                    <Typography
                      variant="h6"
                      sx={{ color: theme.palette.neutral[200] }}
                    >
                      <br />
                      Business Development
                      <br />
                      Account Management
                      <br />
                      Sales Planning & Operations
                      <br />
                      Corporate Development & Partnerships
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                    "& img": { m: "auto", width: "100%" },
                  }}
                >
                  <img src={team2} alt="" />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ p: "4rem 0" }}>
            <Grid
              container
              spacing={{ xs: 2, sm: 2, md: 2, lg: 10 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                    "& img": { m: "auto", width: "100%" },
                  }}
                >
                  <img src={team3} alt="" />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box>
                  <Typography
                    variant="h3"
                    sx={{
                      color: theme.palette.blue[500],
                      p: "0 0 1rem 0",
                      fontWeight: "500",
                    }}
                  >
                    Marketing
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ color: theme.palette.neutral[300] }}
                  >
                    Our customer experience & marketing teams are responsible
                    for the design and delivery of communications and our wider
                    software experience. They work closely with our software &
                    services teams as products are developed. <br />
                    These teams include:
                  </Typography>
                  <Box sx={{ ml: "2rem" }}>
                    <Typography
                      variant="h6"
                      sx={{ color: theme.palette.neutral[200] }}
                    >
                      <br />
                      Marketing Communications
                      <br />
                      Technical Marketing
                      <br />
                      User Experience
                      <br />
                      Brand, Corporate & Communications
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ p: "4rem 0" }}>
            <Grid
              container
              spacing={{ xs: 2, sm: 2, md: 2, lg: 10 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              wrap={"wrap-reverse"}
            >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box>
                  <Typography
                    variant="h3"
                    sx={{
                      color: theme.palette.blue[500],
                      p: "0 0 1rem 0",
                      fontWeight: "500",
                    }}
                  >
                    Corporate
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ color: theme.palette.neutral[300] }}
                  >
                    We’re a global business with unique challenges, and the
                    corporate functions support our rapidly growing
                    organisation.
                    <br /> These teams include:
                  </Typography>
                  <Box sx={{ ml: "2rem" }}>
                    <Typography
                      variant="h6"
                      sx={{ color: theme.palette.neutral[200] }}
                    >
                      <br />
                      Human Resources
                      <br />
                      Legal & Finance
                      <br />
                      Research
                      <br />
                      Environment & Social Initiatives
                      <br />
                      Administration & Executive
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                    "& img": { m: "auto", width: "100%" },
                  }}
                >
                  <img src={team4} alt="" />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Box
          sx={{
            bgcolor: theme.palette.blue[500],
            p: "4rem 2rem",
            mb: "7rem",
            "& .MuiTypography-root": { color: theme.palette.secondary.main },
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "600",
              fontFamily: "Inter",
              mb: "2rem",
            }}
            variant="h1"
          >
            Join our team
          </Typography>
          <Typography
            sx={{ textAlign: "center", fontWeight: "300", mb: "2rem" }}
            variant="h5"
          >
            We are always looking for talented individuals to join our highly
            motivated team and impact the world.
          </Typography>
          <Box display="flex">
            <Link
              to="/careers"
              component={RouterLink}
              underline="none"
              sx={{ margin: "auto" }}
            >
              <CommonButton
                sx={{ borderRadius: "3rem", p: ".4rem 1.8rem", m: "auto" }}
                variant="outlined"
                children="Join Us"
              />
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OurTeam;
