import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import {
  CommonButton,
  //  BlueSection,
  CommonCard,
} from "components";
import { Link as RouterLink } from "react-router-dom";
import img1 from "../../assets/images/offer1.png";
import img2 from "../../assets/images/offer2.png";
import img3 from "../../assets/images/offer3.png";
import img4 from "../../assets/images/offer4.png";

const WhatWeDo = () => {
  const theme = useTheme();
  const whatWeDoData = [
    {
      imgUrl: img1,
      title: "Software Development",
      description:
        "Uridium works have experienced software engineers with many years of experience working in the financial sector and other technology firms which has positioned Uridium as the go to vendor when it comes to project delivery as we ride on agile methodology in all our implementations with work items broken down for ease of implementation. This implies that if we receive your business request, we plan, architect, build, test and handover to your QA team to takeover and run through your internal process.",
    },
    {
      rowReverse: "row-reverse",
      title: "No blame culture for enginners (Training)",
      imgUrl: img3,
      description:
        "This training seeks to open the eyes of engineers to see the impact of the monetary value they make their organization to lose once the blame culture is embraced and helps them come out of that mindset and focus more on the common interest and the goal of the organization thereby fostering collaboration, support and common interest amongst them",
    },
    {
      imgUrl: img2,
      title: "Staff Augmentation",
      description:
        "We are well positioned in assisting banks, fintech and organizations with getting the right technical resources that ensures business continuity in this era where lots of young developers are leaving the country. We source for both commercial banks, MFBS, Fintechs and Technology company. We source for resources based on any preferred stack (C#,Java, python, Oracle, SQL, etc). A lot of organizations have benefited from us.",
    },
    {
      rowReverse: "row-reverse",
      imgUrl: img4,
      title: "Governance and Control Advisory Services",
      description:
        "Organizations are looking for how to block any form of access that could lead to financial leakage/loss intentionally or unintentionally and hence, We have built a solution that helps enforce control in Microsoft SQL server such that if anyone not authorized (developer or support staff, depending on your organizational access control policy) takes the username and password from an application config file and tries to logon through SSMS or Toad or any known tool, such application user will be denied access and thereby ensure the application user is only used by the application and not by a staff or any human. This will help check and ensure discipline amongst developers and also put your position in a good light to regulators as an additional layer of control that ensures checks and balances within the engineering space.",
    },
  ];
  return (
    <Box component="section">
      <Container maxWidth="lg" sx={{ m: "5rem auto 6rem auto" }}>
        <Typography
          sx={{
            fontWeight: "700",
            m: "2rem 0",
            color: theme.palette.blue[500],
          }}
          variant="h1"
          gutterBottom
        >
          What we do
        </Typography>
        <Typography
          sx={{
            fontWeight: "500",
            m: "2rem 0",
            color: theme.palette.secondary[100],
          }}
          variant="h4"
          gutterBottom
        >
          We provide the perfect solution to your business growth.
        </Typography>

        <Box component="div" display="flex" sx={{ flowGrow: "1" }}>
          <Grid
            container
            spacing={{ xs: 3, sm: 3, md: 3 }}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{
              wrap: "wrap-reverse",
            }}
          >
            {whatWeDoData?.map((item, index) => (
              <Grid item xs={12} sm={12} md={12} key={index}>
                <CommonCard
                  // key={index++}
                  imgUrl={item?.imgUrl}
                  title={item?.title}
                  rowReverse={item?.rowReverse}
                  description={item?.description}
                  height={true}
                  width={true}
                  showmore={false}
                  whatWeDoCard={true}
                ></CommonCard>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box display="flex">
          <Link
            sx={{
              m: "3rem auto auto auto",
            }}
            component={RouterLink}
            to="/our-services"
            underline="none"
          >
            <CommonButton
              variant="contained"
              size="large"
              sx={{
                bgcolor: theme.palette.blue[500],
                color: theme.palette.neutral[1000],
                borderRadius: "40px",
                fontSize: "1rem",
                p: ".6rem 3rem",
                "&:hover": {
                  bgcolor: theme.palette.blue[500],
                  color: theme.palette.neutral[1000],
                },
              }}
              children="See more"
            />
          </Link>
        </Box>
      </Container>
      {/* <BlueSection /> */}
    </Box>
  );
};

export default WhatWeDo;
