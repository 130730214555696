import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade, FreeMode, Autoplay, Pagination } from "swiper";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/free-mode";
import "swiper/css";

import { trainingData } from "assets/data/trainingData";
import { FAQs, TrainingCard2 } from "components";
import {
  TrainingContent1,
  TrainingContent2,
  TrainingContent3,
  TrainingContent4,
  TrainingContent5,
  TrainingContent7,
  TrainingContent6,
  TrainingContent8,
  TrainingContent9,
} from "components/TrainingContent";

import arrow1 from "../../assets/images/arrow1.png";
import arrow2 from "../../assets/images/arrow2.png";

const Training = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const moreDetails = useRef(null);
  const [scrolled, setScrolled] = useState(false);

  const [swiper, setSwiper] = useState(null);

  const slideTo = (index) => {
    swiper.slideTo(index + 3);
    swiper.update();
  };

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: moreDetails.current.offsetTop,
      behavior: "smooth",
    });
    // console.log(window.scrollY);
  };

  useEffect(() => {
    const handleScroll = (_) => {
      // pageYoffset 1984
      // scrollY 1922
      if (
        window.pageYOffset >= 1622 &&
        moreDetails.current?.offsetTop >= 1984
        // window.pageYOffset < 3700
      ) {
        setScrolled(true);
        // && window.pageYOffset >= 1984
        // console.log(scrolled);

        // else if (window.pageYOffset > 3800) {
        //   setScrolled(false);
        // }
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return (_) => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  // console.log(window.scrollY);
  // console.log(moreDetails.current?.offsetTop);
  return (
    <>
      <Box sx={{ pb: "10rem" }}>
        <Typography
          sx={{
            textAlign: "center",
            color: theme.palette.blue[500],
            fontWeight: "bold",
            p: "5rem 0 0 0",
          }}
          variant="h1"
        >
          Consultancy And Training
        </Typography>
        <Box sx={{ pt: "2rem" }}>
          <Container maxWidth="lg">
            <Typography
              sx={{
                // textAlign: "center",
                color: theme.palette.secondary[200],
                fontWeight: "500",
                m: "auto",
                textAlign: "center",
                width: "100%",
                lineHeight: "1.5",
              }}
              variant="h5"
            >
              Continuous Learning programs helps Us achieve higher output with
              the same resources and time Frames by improving employee
              productivity. We Enable Inquisitive Learners to innovate more,
              collaborate better, and operate with higher efficiency levels,
              Which In Turn Boost Their Confidence Levels To Perform At Peak
              Capacity
            </Typography>
            <Typography
              sx={{
                color: theme.palette.blue[500],
                fontWeight: "bold",
                textAlign: "center",
                p: "2rem 0 4rem 0",
              }}
              variant="h2"
            >
              Trainings We Offer
            </Typography>
          </Container>
        </Box>
        <Box sx={{ bgcolor: theme.palette.grey[300], p: "6rem 0" }}>
          <Container maxWidth="lg">
            <Grid
              container
              spacing={{ xs: 2, sm: 2, md: 2, lg: 4 }}
              columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              // wrap={"wrap-reverse"}
            >
              {trainingData?.map((training, index) => (
                <Grid item xs={12} sm={6} lg={4} key={index++}>
                  <TrainingCard2
                    imgUrl={training.imgUrl}
                    title={training.title}
                    description={training.description}
                    scrollToSection={scrollToSection}
                    changeSlider={() => {
                      // setSwiper(swiper?.realIndex + 1);
                      scrollToSection();
                      slideTo(index);
                      // slideTo(training?.slideIndex);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        <Box sx={{}} ref={moreDetails}>
          <Box
            sx={{
              // bgcolor: theme.palette.red[500],
              // fontWeight: "bold",
              // textAlign: "center",
              position: "sticky",
              top: 65,
              p: { xs: ".4rem", lg: "2rem" },
              zIndex: 100,
            }}
          >
            <Box
              sx={{
                position: "relative",
                top: 90,
                bottom: -50,
                display: "flex",
                "& img": {
                  transition: "160ms ease-in-out",
                  // opacity: scrolled ? "1" : "0",
                },
                "& .swiperNavPrevRef": {
                  position: "absolute",
                  // pt: "22rem",
                  left: 0,
                  zIndex: 100,
                },
                "& .swiperNavNextRef": {
                  position: "absolute",
                  // mt: "22rem",
                  right: 0,
                  zIndex: 100,
                },
              }}
            >
              <Box
                sx={{
                  margin: "auto",
                  cursor: "pointer",
                }}
                className="swiperNavPrevRef"
                // onClick={() => swiper.slideNext(250)}
                variant="container"
              >
                <img src={arrow1} width="35" alt="arrow1" />
              </Box>
              <Box
                sx={{
                  margin: "auto auto auto .5rem",
                  cursor: "pointer",
                }}
                className="swiperNavNextRef"
                // onClick={() => swiper.slideNext(250)}
                variant="container"
              >
                <img src={arrow2} width="35" alt="arrow2" />
              </Box>
            </Box>
          </Box>
          <Container maxWidth="lg">
            <Typography
              sx={{
                color: theme.palette.blue[500],
                fontWeight: "bold",
                textAlign: "center",
                p: "4rem",
              }}
              variant="h2"
            >
              Detailed Content On Our Trainings
            </Typography>

            <Box
              sx={{
                top: 0,
                overflow: "hidden",
                // bgcolor: theme.palette.grey[200],
                p: "5rem 0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    width: { xs: "100%", sm: 520, md: 750, lg: 1000 },
                    m: "auto",
                    "& .max__height": {
                      height: "120rem",
                      overflow: "auto",
                      p: "0 1rem",
                    },
                    "& .max__height::-webkit-scrollbar": {
                      width: "8px",
                      height: "8px",
                    },
                    "& .max__height::-webkit-scrollbar-track": {
                      backgroundColor: theme.palette.grey[1000],
                      // borderRadius: "2rem",
                    },
                    "& .max__height::-webkit-scrollbar-thumb": {
                      backgroundColor: theme.palette.grey[500],
                      borderRadius: "2rem",
                    },
                    "& .MuiTypography-h5": {
                      // h5 fontSize for both mobile and desktop xs = extra small, lg = large
                      // fontSize: { xs: ".5rem", lg: "1rem" },
                    },
                    "& .MuiDivider-root": {
                      border: `1px solid ${theme.palette.secondary[200]}`,
                    },
                  }}
                >
                  <Swiper
                    style={{
                      "--swiper-pagination-color": "#000",
                      "--swiper-pagination-bullet-inactive-color": "#000",
                      "--swiper-pagination-bullet-inactive-opacity": "1",
                      "--swiper-pagination-bullet-size": "36px",
                      "--swiper-pagination-bullet-horizontal-gap": "20px",
                    }}
                    loop={true}
                    loopedSlides={4}
                    modules={[
                      Navigation,
                      EffectFade,
                      FreeMode,
                      Autoplay,
                      Pagination,
                    ]}
                    // autoplay={{
                    //   delay: 3500,
                    //   disableOnInteraction: false,
                    // }}
                    // spaceBetween={50}
                    // slidesPerView={3}
                    breakpoints={{
                      0: { slidesPerView: 1, spaceBetween: 10 },
                      480: { slidesPerView: 1, spaceBetween: 10 },
                      768: { slidesPerView: 1, spaceBetween: 15 },
                      980: { slidesPerView: 1, spaceBetween: 15 },
                    }}
                    grabCursor={true}
                    freeMode={{ enabled: true, sticky: true }}
                    pagination={{
                      clickable: true,
                      // el: `swiper-container swiper-container-testClass`,
                      // bulletClass: `swiper-pagination-bullet swiper-pagination-testClass`,
                    }}
                    navigation={{
                      prevEl: ".swiperNavPrevRef",
                      nextEl: ".swiperNavNextRef",
                    }}
                    onSlideChange={() => {
                      // console.log(s.realIndex);
                      // setSwiper(s);
                    }}
                    onSwiper={(s) => {
                      setSwiper(s);
                    }}
                  >
                    <SwiperSlide>
                      <TrainingContent1 id="trainingcontent1" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <TrainingContent2 />
                    </SwiperSlide>
                    <SwiperSlide>
                      <TrainingContent3 />
                    </SwiperSlide>
                    <SwiperSlide>
                      <TrainingContent4 />
                    </SwiperSlide>
                    <SwiperSlide>
                      <TrainingContent5 />
                    </SwiperSlide>
                    <SwiperSlide>
                      <TrainingContent6 />
                    </SwiperSlide>
                    <SwiperSlide>
                      <TrainingContent7 />
                    </SwiperSlide>
                    <SwiperSlide>
                      <TrainingContent8 />
                    </SwiperSlide>
                    <SwiperSlide>
                      <TrainingContent9 />
                    </SwiperSlide>
                  </Swiper>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box
          sx={{ bgcolor: theme.palette.blue[500], p: "2.5rem 0", mt: "6rem" }}
        >
          <Container component="section">
            <Box
              sx={{
                display: { sx: "block", lg: "flex" },
                justifyContent: "space-between",
              }}
            >
              <Box display="flex">
                <Box sx={{ m: "auto", color: theme.palette.secondary.main }}>
                  <Box display="flex">
                    <Box sx={{ textAlign: { md: "center", lg: "left" } }}>
                      <Typography
                        sx={{ fontWeight: "600", mb: ".5rem" }}
                        variant="h2"
                      >
                        Want to know the Duration and Cost?
                      </Typography>
                      <Typography
                        sx={{ color: theme.palette.red[500] }}
                        variant="h4"
                      >
                        Let’s get your training started
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box display="flex">
                <Button
                  sx={{
                    p: ".5rem 2rem",
                    mt: { xs: "1rem" },
                    borderRadius: "4rem",
                    border: `1px solid ${theme.palette.secondary.main}`,
                    ":hover": {
                      color: theme.palette.secondary.main,
                    },
                    m: "auto",
                  }}
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                  variant="outlined"
                >
                  Get in touch
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>

        <Box sx={{ width: { xs: "97%", lg: "85%" }, mx: "auto", mt: "10vh" }}>
          <FAQs />
        </Box>
      </Box>
    </>
  );
};

export default Training;
