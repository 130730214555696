import React from "react";

const TextField = ({
  type,
  title,
  pattern,
  placeholder,
  value,
  name,
  onChange,
}) => {
  return (
    <>
      <input
        type={type}
        // id="name"
        title={title}
        pattern={pattern}
        name={name}
        onChange={onChange}
        value={value}
        className="input__field"
        placeholder={placeholder}
      />
    </>
  );
};

export default TextField;
