import React, { useState } from "react";
import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { CommonButton, TextArea, TextField } from "components";
import { ReactComponent as Email } from "../../assets/svgs/svg-email.svg";
import { ReactComponent as Map } from "../../assets/svgs/svg-map.svg";
import { ReactComponent as Phone } from "../../assets/svgs/svg-phone.svg";

const ContactUs = () => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    project: "",
    mobile: "",
    enquiry: "",
  });

  const { name, email, project, mobile, enquiry } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: [e.target.value] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Box sx={{ pb: "10rem" }}>
        <Typography
          sx={{
            textAlign: "center",
            color: theme.palette.blue[500],
            fontWeight: "800",
            p: "5rem 0",
          }}
          variant="h1"
        >
          Contact Us
        </Typography>
        <Box sx={{ bgcolor: theme.palette.grey[100], p: "4rem 0" }}>
          <Container maxWidth="lg">
            <Typography
              sx={{
                // textAlign: "center",
                color: theme.palette.blue[500],
                fontWeight: "bold",
                p: "2rem 0 0 0",
              }}
              variant="h3"
            >
              Get in Touch with us
            </Typography>
            <Box component="form" onSubmit={handleSubmit}>
              <Box
                display="flex"
                m="3rem auto 1rem auto"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <Grid
                  container
                  spacing={{ xs: 3, sm: 3, md: 6 }}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <Box>
                      <label>
                        Name <span>*</span>
                      </label>
                      <TextField
                        type="text"
                        name="name"
                        value={name}
                        placeholder="Enter name"
                        onChange={handleChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <label>
                      Email <span>*</span>
                    </label>
                    <TextField
                      type="email"
                      name="email"
                      value={email}
                      placeholder="Enter email"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <label>
                      Project <span>*</span>
                    </label>
                    <TextField
                      type="text"
                      name="project"
                      value={project}
                      placeholder="Enter Project"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <label>
                      Mobile <span>*</span>
                    </label>
                    <TextField
                      type="tel"
                      name="mobile"
                      placeholder="Enter mobile"
                      value={mobile}
                      title="Please use a 11 digit number with no dashes or dots"
                      pattern="[0-9]{11}"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <label>
                      Enquiry <span>*</span>
                    </label>
                    <TextArea
                      name="enquiry"
                      placeholder="Enter message"
                      value={enquiry}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  // bgcolor: theme.palette.secondary[900],
                  p: "2rem",
                  // boxShadow: ".2rem 0rem 1rem rgb(0,0,0,.12)",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {/* <Box>
                  <Typography
                    variant="h5"
                    sx={{
                      color: theme.palette.red[500],
                    }}
                  >
                    Let us Know how we can help you
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      mt: ".5rem",
                      color: theme.palette.blue[600],
                    }}
                  >
                    Drop us a line let's talk
                  </Typography>
                </Box> */}
                {/* <Box display="flex"> */}
                <CommonButton
                  variant="contained"
                  size="large"
                  sx={{
                    borderRadius: "4rem",
                    p: ".5rem 5rem",
                    m: "auto",
                    fontSize: "1.2rem",
                    color: theme.palette.neutral[1000],
                    bgcolor: theme.palette.blue[500],
                    "&:hover": {
                      color: theme.palette.neutral[1000],
                      bgcolor: theme.palette.blue[500],
                    },
                  }}
                  children="Submit"
                  // onSubmit={handleSubmit}
                ></CommonButton>
                {/* </Box> */}
              </Box>
            </Box>
          </Container>
        </Box>
        <Box sx={{ position: "relative", p: "0" }}>
          <Box sx={{ overflow: "hidden" }}>
            <Box sx={{ m: "auto", display: "flex", overFlow: "hidden" }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d2653.787983379025!2d3.4624825143869793!3d6.443126795428063!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x103bf44a29b0dc45%3A0x5ef543b35e86dd4f!2suridium%20technologies!3m2!1d6.4433262!2d3.4626989!5e0!3m2!1sen!2sng!4v1677752572693!5m2!1sen!2sng"
                width="100%"
                height="890"
                // height="100%"
                // width="1515"
                style={{ border: "0", margin: "auto" }}
                allowfullscreen=""
                loading="lazy"
                title="google map"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              m: "auto",
              p: { xs: "0 1rem", lg: "0 10rem" },
              bottom: "-5rem",
              "& svg": { m: "auto" },
            }}
          >
            <Grid
              container
              spacing={{ xs: 3, sm: 3, md: 6 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    p: "3.5rem",
                    borderRadius: "1rem",
                    height: "20rem",
                    bgcolor: theme.palette.secondary[900],
                    boxShadow: ".2rem 0rem 1rem rgb(0,0,0,.12)",
                  }}
                >
                  <Box sx={{ m: "auto", textAlign: "center" }}>
                    <Email />
                    <Typography
                      variant="h4"
                      sx={{
                        color: theme.palette.blue[500],
                        // fontWeight: "bold",
                        mt: ".5rem",
                      }}
                    >
                      EMAIL:
                    </Typography>
                    <a href="mailto:info@uridiumworks.com">
                      <Typography
                        variant="h6"
                        sx={{
                          color: theme.palette.blue[500],
                          // fontWeight: "bold",
                          mt: ".5rem",
                        }}
                      >
                        info@uridiumworks.com
                      </Typography>
                    </a>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    "& svg": { m: "auto" },
                    p: "3.5rem",
                    height: "20rem",
                    borderRadius: "1rem",
                    bgcolor: theme.palette.secondary[900],
                    boxShadow: ".2rem 0rem 1rem rgb(0,0,0,.12)",
                  }}
                >
                  <Box sx={{ m: "auto", textAlign: "center" }}>
                    <Phone />
                    <Typography
                      variant="h4"
                      sx={{
                        color: theme.palette.blue[500],
                        // fontWeight: "bold",
                        mt: ".5rem",
                      }}
                    >
                      PHONE:
                    </Typography>
                    <a href="tel:2347032364108">
                      <Typography
                        variant="h6"
                        sx={{
                          color: theme.palette.blue[500],
                          // fontWeight: "bold",
                          mt: ".5rem",
                        }}
                      >
                        +2347032364108
                      </Typography>
                    </a>
                    <a href="tel:2348033745378">
                      <Typography
                        variant="h6"
                        sx={{
                          color: theme.palette.blue[500],
                          // fontWeight: "bold",
                          mt: ".5rem",
                        }}
                      >
                        +2348033745378
                      </Typography>
                    </a>
                    <a href="tel:2348060347351">
                      <Typography
                        variant="h6"
                        sx={{
                          color: theme.palette.blue[500],
                          // fontWeight: "bold",
                          mt: ".5rem",
                        }}
                      >
                        +2348060347351
                      </Typography>
                    </a>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    "& svg": { m: "auto" },
                    p: "3.5rem",
                    borderRadius: "1rem",
                    height: "20rem",
                    bgcolor: theme.palette.secondary[900],
                    boxShadow: ".2rem 0rem 1rem rgb(0,0,0,.12)",
                  }}
                >
                  <Box sx={{ m: "auto", textAlign: "center" }}>
                    <Map />
                    <Typography
                      variant="h4"
                      sx={{
                        color: theme.palette.blue[500],
                        // fontWeight: "bold",
                        mt: ".5rem",
                      }}
                    >
                      ADDRESS:
                    </Typography>
                    <a
                      href="https://goo.gl/maps/8uX3SodbVk8tvsue6"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: theme.palette.blue[500],
                          // fontWeight: "bold",
                          mt: ".5rem",
                        }}
                      >
                        1st floor #8, Right Wing, 13 Rasheed Alaba Williams
                        Street, Lekki Phase 1, Lagos
                      </Typography>
                    </a>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ContactUs;
