import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

const CommonCard = ({
  whatWeDoCard,
  ourCoreValuesCard,
  rowReverse,
  title,
  description,
  imgUrl,
  align,
  svg,
  paddingTop,
  height,
}) => {
  const theme = useTheme();

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  const boxVariant = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1, ease: "easeInOut" },
    },
    hidden: { opacity: 0, x: -50 },
  };

  // const truncate = (str) => {
  //   console.log(str);
  //   if (str) {
  //     return JSON.stringify(str).length > 500
  //       ? str.substring(0, 570) + "..."
  //       : // ? str.substring(0, 140) + "..."
  //         str;
  //   }
  // };
  return (
    <motion.div
      // transition={{ type: "inertia", velocity: 20 }}
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
    >
      {whatWeDoCard && (
        <Card
          sx={{
            // maxWidth: 300,
            "&:last-child": {
              // marginRight: "0",
            },
            margin: "2rem auto",
            boxShadow: "none",
            border: "none",
            backgroundColor: "transparent",
            // p: { sm: "1.5rem 3.2rem", lg: "1.5rem 4rem" },
            width: "100%",

            // height: titleAlign ? "32rem" : "auto",
          }}
        >
          <Grid
            container
            spacing={{ xs: 3, sm: 3, md: 3 }}
            columns={{ xs: 12, sm: 12, md: 12 }}
            direction={rowReverse}
          >
            <Grid item xs={12} sm={6} md={6}>
              <Box
                align={align}
                sx={{
                  width: "100%",
                  display: "flex",
                  height: "100%",
                  "& img": {
                    width: { xs: "80%", lg: "60%" },
                    m: "auto",
                  },
                  // paddingTop: paddingTop,
                }}
              >
                <img src={imgUrl} alt="offer-img" style={{ margin: "auto" }} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <CardContent
                sx={{
                  backgroundColor: "transparent",
                  fontColor: theme.palette.secondary.dark,
                  // textAlign: align,
                }}
              >
                <Typography
                  sx={{ color: theme.palette.blue[500], fontWeight: "bold" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  {title}
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.secondary[100],
                    lineHeight: height ? "1.2rem" : "1.4rem",
                    textAlign: "justify",
                    fontSize: height ? ".8rem" : "auto",
                    // textAlign: "left",
                  }}
                  variant="body1"
                  // noWrap
                  textOverflow="ellipsis"
                >
                  {description}
                  {/* {svg ? description : truncate(description)} */}
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      )}
      {ourCoreValuesCard && (
        <Card
          sx={{
            // maxWidth: 300,
            "&:last-child": {
              // marginRight: "0",
            },
            margin: "auto",
            boxShadow: "none",
            border: `1px solid ${theme.palette.grey[300]}`,
            backgroundColor: "transparent",
            borderRadius: "10px",
            // p: { sm: "1.5rem 3.2rem", lg: "1.5rem 4rem" },
            width: "auto",
            height: "25rem",
          }}
        >
          <Box component="div">
            <Box
              align={align}
              sx={{
                width: "100%",
                display: "flex",
                paddingTop: paddingTop,
                "& svg": {
                  height: { xs: "3rem", lg: "4rem" },
                  width: { xs: "3rem", lg: "4rem" },
                  m: "auto",
                  // m: titleAlign ? "auto" : "0 3.5rem",
                },
              }}
            >
              {svg}
            </Box>
            <CardContent
              sx={{
                backgroundColor: "transparent",
                fontColor: theme.palette.secondary.dark,
                // textAlign: align,
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.blue[500],
                  fontWeight: "bold",
                  // margin: "1rem 0 0 0",
                  pb: "1rem",
                  textAlign: "center",
                  // mb: "1rem",
                }}
                gutterBottom
                variant="h3"
                p
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.secondary[100],
                  textAlign: "justify",
                  // textAlign: "left",
                }}
                variant="body1"
                // noWrap
                textOverflow="ellipsis"
              >
                {description}
              </Typography>
            </CardContent>
          </Box>
        </Card>
      )}
    </motion.div>
  );
};

export default CommonCard;
