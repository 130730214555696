import {
  Box,
  Divider,
  Grid,
  Typography,
  useTheme,
  // CardActions,
  // Button,
  // Link,
} from "@mui/material";
import React from "react";
// import { Link as RouterLink } from "react-router-dom";

import trainingImg from "../../../assets/images/training/trainingImg3.png";

const TrainingContent4 = () => {
  const theme = useTheme();
  const style = {
    parentPosition: {
      position: "relative",
    },
    textBottom: {
      position: "absolute",
      bottom: 50,
    },
  };

  return (
    <>
      <Box className="max__height">
        <Box sx={{ m: "0 0 4rem 0" }}>
          <Grid
            container
            spacing={{ xs: 0, sm: 0, md: 0, lg: 0 }}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            // wrap={"wrap-reverse"}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  bgcolor: theme.palette.blue[500],
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.secondary[1000],
                    fontWeight: "bold",
                    m: "auto",
                    p: "2rem",
                  }}
                  variant="h2"
                >
                  Front end Development
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  display: "flex",
                  "& img": {
                    margin: "auto",
                    width: "100%",
                  },
                }}
              >
                <img src={trainingImg} alt="img1" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mb: "4rem" }}>
          <Typography
            sx={{
              color: theme.palette.blue[600],
              fontWeight: "bold",
              textAlign: "center",
              mb: "2rem",
            }}
            variant="h2"
          >
            FRONT - END DEVELOPMENT
          </Typography>
          <Typography
            sx={{
              color: theme.palette.secondary[100],
              fontWeight: "500",
              textAlign: "left",
              lineHeight: 2,
              // mb: "2rem"
            }}
          >
            Take your web development skills to the next level with our
            front-end development training. Our program will equip you with the
            latest tools and techniques in HTML, CSS, and JavaScript, empowering
            you to create engaging and responsive user interfaces. Whether
            you're new to web development or looking to upskill, our expert
            instructors will provide personalized guidance and feedback to help
            you achieve your goals. Enroll today and start building dynamic,
            user-friendly web experiences.
          </Typography>
        </Box>
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Introduction to Front-End Development
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              History and evolution of web and how it works
              <br />
              HTML5 Basics, tags, elements and attributes
              <br />
              Basic concept of CSS selctors, properties and values
              <br />
              Create a simple website with HTML5 and CSS
            </Typography>
          </Box>
          <Box sx={{ width: "15%", ...style.parentPosition }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
                ...style.textBottom,
              }}
              variant="h3"
            >
              Week 1
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Introduction to Front-End Development
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              History and evolution of web and how it works
              <br />
              HTML5 Basics, tags, elements and attributes
              <br />
              Basic concept of CSS selctors, properties and values
              <br />
              Create a simple website with HTML5 and CSS
            </Typography>
          </Box>
          <Box sx={{ width: "15%", ...style.parentPosition }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
                ...style.textBottom,
              }}
              variant="h3"
            >
              Week 2
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Introduction to Front-End Development
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              History and evolution of web and how it works
              <br />
              HTML5 Basics, tags, elements and attributes
              <br />
              Basic concept of CSS selctors, properties and values
              <br />
              Create a simple website with HTML5 and CSS
            </Typography>
          </Box>
          <Box sx={{ width: "15%", ...style.parentPosition }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
                ...style.textBottom,
              }}
              variant="h3"
            >
              Week 3
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Introduction to Front-End Development
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              History and evolution of web and how it works
              <br />
              HTML5 Basics, tags, elements and attributes
              <br />
              Basic concept of CSS selctors, properties and values
              <br />
              Create a simple website with HTML5 and CSS
            </Typography>
          </Box>
          <Box sx={{ width: "15%", ...style.parentPosition }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
                ...style.textBottom,
              }}
              variant="h3"
            >
              Week 4
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: "1.5rem 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{ color: theme.palette.secondary[100], fontWeight: "bold" }}
              variant="h3"
            >
              Introduction to Front-End Development
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "500",
                lineHeight: "2",
                pt: "2rem",
              }}
            >
              History and evolution of web and how it works
              <br />
              HTML5 Basics, tags, elements and attributes
              <br />
              Basic concept of CSS selctors, properties and values
              <br />
              Create a simple website with HTML5 and CSS
            </Typography>
          </Box>
          <Box sx={{ width: "15%", ...style.parentPosition }}>
            <Typography
              sx={{
                color: theme.palette.secondary[100],
                fontWeight: "bold",
                ...style.textBottom,
              }}
              variant="h3"
            >
              Week 5
            </Typography>
          </Box>
        </Box>
        <Divider />
      </Box>
    </>
  );
};

export default TrainingContent4;
