import React from "react";
import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { PartnersImg } from "components";
// import CardOffer from "../";
import partner1 from "../../assets/images/partner1.png";
import partner2 from "../../assets/images/partner2.png";
import partner3 from "../../assets/images/partner3.png";
import partner4 from "../../assets/images/partner4.png";
import partner5 from "../../assets/images/partner5.png";
import partner6 from "../../assets/images/partner6.png";
import partner7 from "../../assets/images/partner7.png";
import partner8 from "../../assets/images/partner8.png";
import partner9 from "../../assets/images/partner9.png";
import partner10 from "../../assets/images/partner10.png";
import partner11 from "../../assets/images/partner11.png";
import partner12 from "../../assets/images/partner12.png";
import partner13 from "../../assets/images/partner13.png";

const Partners = () => {
  const theme = useTheme();
  const partnersData = [
    {
      imageUrl: partner1,
    },
    {
      imageUrl: partner2,
    },
    {
      imageUrl: partner3,
    },
    {
      imageUrl: partner4,
    },
    {
      imageUrl: partner5,
    },
    {
      imageUrl: partner6,
    },
    {
      imageUrl: partner7,
    },
    {
      imageUrl: partner8,
    },
    {
      imageUrl: partner9,
    },
    {
      imageUrl: partner10,
    },
    {
      imageUrl: partner11,
    },
    {
      imageUrl: partner12,
    },
    {
      imageUrl: partner13,
    },
  ];
  return (
    <Box component="section">
      <Container maxWidth="lg" sx={{ m: "5rem auto 10rem auto" }}>
        <Typography
          sx={{
            fontWeight: "700",
            m: "2rem 0",
            color: theme.palette.blue[500],
          }}
          variant="h1"
          gutterBottom
        >
          Fostering incredible <br /> business development
        </Typography>
        <Typography
          sx={{
            fontWeight: "500",
            m: "2rem 0",
            color: theme.palette.primary[100],
            width: { xs: "100%", lg: "40%" },
          }}
          variant="h5"
          gutterBottom
        >
          For a new wave of innovative, forward-thinking business operation in
          Africa, <b>Uridium</b> serves as a growth engine to the following
          clients:
        </Typography>

        <Box component="div" display="flex" sx={{ flowGrow: "1", mt: "6rem" }}>
          <Grid
            container
            spacing={{ xs: 3, sm: 3, md: 3, lg: 5 }}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            {partnersData?.map((item, index) => (
              <Grid item xs={6} sm={4} md={4} lg={2.4} key={index}>
                <PartnersImg imageUrl={item?.imageUrl}></PartnersImg>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Partners;
